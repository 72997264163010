import { useEffect, useCallback } from "react"
import { captureError } from "../ErrorHandlers"

const isPushNotificationSupported = () => {
  return "serviceWorker" in navigator && "PushManager" in window
}

const pushNotificationSupported = isPushNotificationSupported()

const usePushNotifications = (
  onPushNotificationClick: (fromJid: string) => void,
) => {
  const onNewPushNotification = useCallback(
    (event: MessageEvent) => {
      const dataType = event.data.type
      if (dataType === "PUSH_NOTIFICATION_CLICK") {
        onPushNotificationClick(event.data.toJID)
      } else if (dataType === "SERVICE_WORKER_ERROR") {
        captureError(new Error("ServiceWorkerError"), {
          origin: "PushNotifications",
          extra: event.data.attributes,
        })
      }
    },
    [onPushNotificationClick],
  )

  useEffect(() => {
    if (!pushNotificationSupported) {
      return
    }

    navigator.serviceWorker
      .register("/serviceWorker.js")
      .then(() => {
        navigator.serviceWorker.addEventListener(
          "message",
          onNewPushNotification,
        )
      })
      .catch((error) => {
        captureError(error, {
          origin: "PushNotifications",
          extra: { message: "addEventListener" },
        })
      })
  }, [onNewPushNotification])
}

export default usePushNotifications
