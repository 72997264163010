import { Client, xml } from "@xmpp/client"
import { v4 as uuid } from "uuid"
import { Element } from "@xmpp/xml"

export type RosterItem = {
  jid: string
  name: string
  groups: string[]
}

const XmppApi = {
  sendMessage: (xmpp: Client, to: string, text: string): Promise<Element> => {
    return new Promise((resolve) => {
      const id = uuid()
      const message = xml(
        "message",
        { type: "chat", to: to, from: xmpp.jid, id },
        xml("body", {}, text),
        xml("markable", "urn:xmpp:chat-markers:0"),
      )

      xmpp.send(message).catch((e) => {
        throw e
      })
      resolve(message)
    })
  },

  fetchRoster: async (xmpp: Client): Promise<RosterItem[]> => {
    const { iqCaller } = xmpp

    const response = await iqCaller.request(
      xml(
        "iq",
        { type: "get", from: xmpp.jid?.toString() },
        xml("query", "jabber:iq:roster"),
      ),
    )

    return response
      .getChild("query")!
      .getChildren("item")
      .map((item) => {
        const groups = item.getChildren("group").map((g) => g.text())
        return {
          jid: item.attrs.jid,
          name: item.attrs.name,
          groups,
        }
      })
  },

  sendPresence: async (xmpp: Client) => {
    return xmpp.send(xml("presence"))
  },
}

export default XmppApi
