import { useCallback, useEffect, useState } from "react"

const useIsTabActive = () => {
  const [isTabVisible, setIsTabVisible] = useState(true)

  const handleTabVisibilityChange = useCallback(() => {
    setIsTabVisible(document.visibilityState === "visible")
  }, [])

  useEffect(() => {
    document.addEventListener("visibilitychange", handleTabVisibilityChange)

    return () => {
      document.removeEventListener(
        "visibilitychange",
        handleTabVisibilityChange,
      )
    }
  }, [handleTabVisibilityChange])

  return isTabVisible
}

export default useIsTabActive
