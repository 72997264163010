function groupBy<T>(
  array: T[],
  keyExtractor: (item: T) => string | number,
): Record<string | number, T[]> {
  const groups = new Map()

  array.forEach((item) => {
    const key = keyExtractor(item)

    if (!groups.has(key)) {
      groups.set(key, [])
    }

    groups.get(key).push(item)
  })

  return Object.fromEntries(groups.entries())
}

export default groupBy
