import { Element } from "@xmpp/xml"
import { MessageDisplayType, MessageRetraction } from "../reducers/chatsSlice"
import MessageParser from "./messageParser"
import ParserHelpers from "./parserHelpers"

class RetractionParser {
  static parse(
    stanza: Element,
    timestamp?: string,
  ): MessageRetraction | undefined {
    const retractionNode = stanza.getChild("retract")

    if (!retractionNode) return

    return {
      id: stanza.attrs.id,
      type: MessageParser.type(stanza),
      to: MessageParser.to(stanza),
      stanzaId: ParserHelpers.stanzaId(stanza),
      messageId: retractionNode.attrs.id,
      timestamp: timestamp || new Date().toISOString(),
      from: MessageParser.from(stanza),
      displayType: MessageDisplayType.Retraction,
    }
  }
}

export default RetractionParser
