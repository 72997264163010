const MAX_CONCURRENT_EXECUTIONS = 7

export default class RoomSyncQueue {
  static queue: {
    promise: () => Promise<any>
    resolve: (value: unknown) => void
    reject: (reason?: any) => void
  }[] = []
  static currentExecutionCount = 0

  static enqueue(promise: () => Promise<any>) {
    return new Promise((resolve, reject) => {
      this.queue.push({
        promise,
        resolve,
        reject,
      })
      this.dequeue()
    })
  }

  static dequeue() {
    if (this.currentExecutionCount === MAX_CONCURRENT_EXECUTIONS) {
      return false
    }
    const item = this.queue.shift()
    if (!item) {
      return false
    }
    try {
      this.currentExecutionCount++
      item
        .promise()
        .then((value) => {
          this.currentExecutionCount--
          item.resolve(value)
          this.dequeue()
        })
        .catch((err) => {
          this.currentExecutionCount--
          item.reject(err)
          this.dequeue()
        })
    } catch (err) {
      this.currentExecutionCount--
      item.reject(err)
      this.dequeue()
    }
    return true
  }
}
