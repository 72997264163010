import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { Button, Classes, Icon, Popover } from "@blueprintjs/core"
import SearchBar from "./SearchBar"
import ChatCard from "./ChatCard"
import { addBookmark } from "../api/xeps/PepNativeBookmarks"
import classNames from "classnames"
import { XmppContext } from "../stream/xmppClient"
import XmppGroupChatApi from "../api/xmppGroupChatApi"
import utilsCss from "./utilsCss.module.css"
import css from "./JoinChatPopover.module.css"
import { useAppStore, useAppSelector } from "../reducers/hooks"
import { joinChat } from "../reducers/chatsSliceThunks"
import {
  ChatSimplistic,
  selectSimpleChats,
} from "../reducers/chatsSliceSelectors"
import ChatHelpers from "../lib/chatHelpers"

type JoinChatPopoverProps = {
  onActiveChatChange: (chatJid: string) => void
}

const JoinChatPopover = ({ onActiveChatChange }: JoinChatPopoverProps) => {
  const { client } = useContext(XmppContext)
  const store = useAppStore()
  const chats = useAppSelector(selectSimpleChats)

  const [searchValue, setSearchValue] = useState<string>("")
  const {
    chats: allChats,
    refetchChats,
    hasFetched: chatsDidFetch,
  } = useGetAllChats()

  const chatOptions = useMemo(() => {
    const all = allChats.filter(
      (chat) => !chats.some(({ jid }) => jid === chat.jid),
    )
    const filtered = all.filter((chat) =>
      chat.name.toLowerCase().includes(searchValue.toLowerCase()),
    )

    return { all, filtered }
  }, [chats, allChats, searchValue])

  const onChatSelect = useCallback(
    async (chat: ChatSimplistic) => {
      setSearchValue("")
      await addBookmark(client, chat.jid, chat.name)
      store.dispatch(joinChat(client, store, chat))
      onActiveChatChange(chat.jid)
    },
    [client, store, onActiveChatChange],
  )

  const shouldDisplayJoinChat = !chatsDidFetch || chatOptions.all.length > 0
  const shouldDisplaySearchBar = chatOptions.all.length > 5

  const ChatOptions = () => {
    if (!chatsDidFetch) {
      return (
        <div className={utilsCss.noResults}>
          Could not get your chats, try again
        </div>
      )
    }

    if (chatOptions.filtered.length === 0) {
      return <div className={utilsCss.noResults}>No Results</div>
    }

    return (
      <>
        {chatOptions.filtered.map((chat) => (
          <ChatCard
            compact
            key={chat.jid}
            chatJid={chat.jid}
            chatName={chat.name}
            chatActive={false}
            chatColor={ChatHelpers.getChatColor(chat)}
            className={Classes.POPOVER_DISMISS}
            onClick={() => onChatSelect(chat)}
          />
        ))}
      </>
    )
  }

  return (
    <Popover
      interactionKind="click"
      placement="bottom"
      content={
        <div className={css.joinChatContainer}>
          {shouldDisplaySearchBar && (
            <div className={css.joinChatSearchBar}>
              <SearchBar
                value={searchValue}
                onValueChange={setSearchValue}
                inPopover
              />
            </div>
          )}
          <div className={css.joinChatList}>
            <ChatOptions />
          </div>
        </div>
      }
      renderTarget={({ className, onClick, ...targetProps }) => (
        <Button
          icon={<Icon icon="annotation" size={18} />}
          minimal
          style={{ display: shouldDisplayJoinChat ? "flex" : "none" }}
          className={classNames(css.addChatButton, className)}
          onClick={(e) => {
            refetchChats()
            onClick?.(e)
          }}
          {...targetProps}
        />
      )}
    />
  )
}

const useGetAllChats = () => {
  const [chats, setChats] = useState<ChatSimplistic[]>([])
  const [hasFetched, setHasFetched] = useState<boolean>(false)
  const { client } = useContext(XmppContext)

  const fetchChats = useCallback(() => {
    if (!client.jid) return

    XmppGroupChatApi.fetchRooms(client).then((chats) => {
      // We assume that any successful fetch causes this to render.
      // Upside is that we will show this interface once anything fetched.
      // Downside is that on further failures we won't show updated rooms.
      setHasFetched(true)
      setChats(chats)
    })
  }, [client])

  useEffect(() => fetchChats(), [fetchChats, client.jid])

  return { chats, refetchChats: fetchChats, hasFetched }
}

export default JoinChatPopover
