import classNames from "classnames"
import React, { memo, useContext } from "react"
import css from "./MessageImageGroupBubleBody.module.css"
import MessageHelpers, { MessageStatus } from "../../lib/messageHelpers"
import { Message } from "../../reducers/chatsSlice"
import { useAppSelector } from "../../reducers/hooks"
import {
  maybeMessageAuthorName,
  messageAuthorName,
} from "../../reducers/profilesSliceSelectors"
import { XmppContext } from "../../stream/xmppClient"
import { ReadStatus } from "./ReadStatus"
import LazyImage from "./LazyImage"
import { getDownloadFileName } from "./utils/getDownloadFileName"
import { mapFileUrlToSameOrigin } from "./utils/mapFileToSameOrigin"
import {
  downloadMultipleImagesAsZip,
  UrlAndFilename,
} from "./utils/downloadMultipleImagesAsZip"
import FileStub from "./FileStub"
import { Icon } from "@blueprintjs/core"

const getUrlAndFilenames = (
  messages: Message[],
  fileSenderName: string,
): UrlAndFilename[] => {
  return messages.map((m, index) => ({
    filename: getDownloadFileName(m, fileSenderName, index)!,
    url: m.url || m.body,
  }))
}

const onDownloadClicked = (downloadUrlAndFilenames: UrlAndFilename[]) => {
  downloadMultipleImagesAsZip(downloadUrlAndFilenames)
}

export type MessageImageGroupBubleBodyProps = {
  messages: Message[]
  status: MessageStatus | undefined
  highlight?: boolean
  withDriver?: boolean
  onExpand: () => void
}

const MessageImageGroupBubleBody = ({
  messages,
  status,
  highlight = false,
  withDriver = false,
  onExpand,
}: MessageImageGroupBubleBodyProps) => {
  const firstMessage = messages[0]

  const { myJid } = useContext(XmppContext)
  const authorName = useAppSelector(messageAuthorName(firstMessage, myJid))
  const forwardedAuthorName = useAppSelector(
    maybeMessageAuthorName(firstMessage.forwarded, myJid),
  )
  const messageWithAuthor = firstMessage.forwarded
    ? firstMessage.forwarded
    : firstMessage
  const fileSenderName = useAppSelector(
    messageAuthorName(messageWithAuthor, myJid),
  )

  const messagesToShow = messages.map((message) =>
    message.forwarded ? message.forwarded : message,
  )

  const downloadUrlAndFilenames = getUrlAndFilenames(
    messagesToShow,
    fileSenderName,
  )

  return (
    <div className="group">
      <span
        className={classNames(css.userName, {
          [css.highlight]: highlight,
        })}
      >
        {authorName}
      </span>
      <div
        className={classNames(
          "flex flex-col gap-y-0.5 max-w-64",
          firstMessage.forwarded && "ml-[10px]",
        )}
      >
        {firstMessage.forwarded && (
          <span className={classNames(css.userName, css.highlight)}>
            {forwardedAuthorName}
          </span>
        )}
        <div className="flex flex-row justify-between text-black mb-1 text-xs gap-x-2">
          <div className="flex flex-row gap-x-1">
            <span>{messagesToShow.length} files</span>
            <span>•</span>
            <button onClick={onExpand}>
              Expand <Icon icon="chevron-down" />
            </button>
          </div>

          <a
            onClick={(e) => {
              e.preventDefault()
              onDownloadClicked(downloadUrlAndFilenames)
            }}
            className="text-blue-500 cursor-pointer"
          >
            <span>
              Download <Icon icon="download" />
            </span>
          </a>
        </div>

        <div className="grid grid-cols-2 gap-2">
          {messagesToShow.slice(0, 3).map((m) => (
            <div
              className="flex justify-center rounded-lg h-28 w-28 border bg-white"
              key={m.id}
            >
              {/\.pdf/i.test(m.url!) ? (
                <FileStub
                  url={m.url!}
                  name={m.fileName}
                  key={m.id}
                  inGroup
                  message={m}
                />
              ) : (
                <LazyImage
                  url={mapFileUrlToSameOrigin(m.url!)}
                  inGroup
                  message={m}
                />
              )}
            </div>
          ))}
          {messagesToShow.length > 3 && (
            <div className="flex justify-center border rounded-lg h-28 w-28 bg-gray-400">
              <button
                onClick={onExpand}
                className="text-white h-full w-full text-4xl font-bold"
              >
                +{messagesToShow.length - 3}
              </button>
            </div>
          )}
        </div>

        {firstMessage.forwarded && (
          <div className={classNames(css.messageBody, "mt-2")}>
            {firstMessage.body}
          </div>
        )}
      </div>

      <div className={classNames(css.timeAndStatus, "mt-2")}>
        <div className={css.timeStamp}>
          {MessageHelpers.timestamp(firstMessage)}
        </div>
        {status && (
          <div className={css.status}>
            <ReadStatus status={status} withDriver={withDriver} />
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(MessageImageGroupBubleBody)
