import { Element } from "@xmpp/xml"

const iqMeasure = (
  iqCaller: any,
  name: string,
): {
  request: (xml: Element, timeout?: number | undefined) => Promise<Element>
} => {
  return {
    request: async (xml: Element, timeout?: number | undefined) => {
      const startMark = performance.mark("iqRequest.start", {
        detail: { name },
      })
      try {
        const response = await iqCaller.request(xml, timeout)
        const endMark = performance.mark("iqRequest.end")
        performance.measure("iqRequest.duration", {
          start: startMark.name,
          end: endMark.name,
          detail: { name },
        })
        return response
      } catch (e: any) {
        const endMark = performance.mark("iqRequest.end")
        performance.measure("iqRequest.error", {
          start: startMark.name,
          end: endMark.name,
          detail: { name, error: e.name },
        })
        throw e
      }
    },
  }
}

export default iqMeasure
