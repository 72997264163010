import { getNextRoomSyncQueueJid } from "./roomSyncStatusSelectors"
import {
  addToSyncQueue,
  removeFromFailed,
  removeFromSyncQueue,
} from "./roomSyncStatusSlice"
import { AppDispatch, RootState } from "./store"

export const popNextRoomSyncQueueJid = () => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const nextRoomJid = getNextRoomSyncQueueJid()(getState().roomSyncStatus)

    if (nextRoomJid) {
      dispatch(removeFromSyncQueue(nextRoomJid))
      return nextRoomJid
    }
  }
}

export const retrySyncSingleRoom = (jid: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(removeFromFailed({ jid }))
    dispatch(addToSyncQueue({ jid }))
  }
}
