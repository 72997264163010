import { PayloadAction } from "@reduxjs/toolkit"

type Action = {
  start: DOMHighResTimeStamp
  duration: DOMHighResTimeStamp
  type: PayloadAction["type"]
}

const BATCH_SIZE = 50

const actionQueue: Action[] = []

const round = (n: number) => Math.round(n * 100) / 100

const monitorEnhancer =
  (createStore: any) => (reducer: any, initialState: any) => {
    const monitoredReducer = (state: any, action: PayloadAction) => {
      const start = performance.now()
      const newState = reducer(state, action)
      const duration = performance.now() - start

      actionQueue.push({
        start: round(start),
        duration: round(duration),
        type: action.type,
      })

      return newState
    }

    return createStore(monitoredReducer, initialState)
  }

const reportActions = () => {
  while (actionQueue.length > 0) {
    const batch = actionQueue.splice(0, BATCH_SIZE)
    const payload = {
      performanceNow: performance.now(),
      sendAt: new Date().toISOString(),
      actions: batch,
    }
    window.analytics.track("ReduxActions", payload)
  }
}

setInterval(reportActions, 5000)

export default monitorEnhancer
