import React, { useMemo } from "react"
import { ChatConnectionStatus } from "../reducers/chatsSlice"
import ChatStatusBar from "./ChatStatusBar"
import { Icon } from "@blueprintjs/core"

type ChatConnectionStatusBarProps = {
  status?: ChatConnectionStatus
}

const ChatConnectionStatusBar = ({ status }: ChatConnectionStatusBarProps) => {
  const message = useMemo(() => {
    switch (status) {
      case "disconnected":
        return <p>You are disconnected from this chat, reconnecting...</p>
      case "failed":
        return (
          <span className="flex flex-col items-center gap-2 md:flex-row">
            <p>
              There is a problem with the connection. You cannot send nor
              receive messages from this driver.
            </p>
            <button
              onClick={() => window.location.reload()}
              className="p-0.5 flex items-center space-x-1.5 px-2 rounded-md border-warning-200 bg-warning-50 active:translate-y-0.5 transition-transform border"
            >
              <Icon icon="repeat" size={12} />{" "}
              <span>Please, refresh the page</span>
            </button>
          </span>
        )
      default:
        return null
    }
  }, [status])

  if (message) return <ChatStatusBar>{message}</ChatStatusBar>
  else return null
}

export default ChatConnectionStatusBar
