import React from "react"
import { Button, Icon, Tooltip } from "@blueprintjs/core"
import css from "./ChatInputFiles.module.css"

export type PastedFile = {
  file: File
  url: string
}

type ChatInputFilesProps = {
  pastedFiles: PastedFile[]
  handleRemoveImage: (index: number) => void
}

const ChatInputFiles: React.FC<ChatInputFilesProps> = ({
  pastedFiles,
  handleRemoveImage,
}) => {
  const isImage = (file: File): boolean => {
    const acceptedImageTypes = [
      "image/gif",
      "image/jpg",
      "image/jpeg",
      "image/png",
      "image/webp",
      "image/bmp",
      "image/tiff",
    ]
    return acceptedImageTypes.includes(file.type)
  }
  return (
    <div className={css.chatInputFilesContainer}>
      {pastedFiles.map((pastedFile, index) => {
        const file = pastedFile.file
        const fileType = file.name.substring(file.name.lastIndexOf(".") + 1)
        const isImageCheck = isImage(file)
        const previewStyle = isImageCheck
          ? { backgroundImage: `url(${pastedFile.url})` }
          : undefined
        return (
          <div key={index} className={css.fileContainer}>
            <div className={css.removeButtonContainer}>
              <Tooltip content="Remove file">
                <Button
                  data-testid="remove-pasted-image-button"
                  className={css.removeButton}
                  icon={<Icon icon="cross-circle" size={19} color="black" />}
                  minimal
                  onClick={() => handleRemoveImage(index)}
                />
              </Tooltip>
            </div>

            <div
              className={css.filePreviewContainer}
              style={previewStyle}
              data-testid="file-preview-container"
            >
              {!isImageCheck && (
                <div className={css.filePreview}>
                  {fileType.toLowerCase() === "pdf" ? (
                    <img src="/pdf.svg" alt="PDF Icon" width={28} height={28} />
                  ) : (
                    <Icon
                      icon="document"
                      size={28}
                      data-testid="document-preview-icon"
                    />
                  )}
                  <div className={css.filePreviewNameWrapper}>
                    <span className={css.fileName}>
                      {file.name.length > 20
                        ? `${file.name.slice(0, 15)}...`
                        : file.name}
                    </span>
                    <div className={css.fileType}>{fileType}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ChatInputFiles
