import { Element } from "@xmpp/xml"
import type { Marker, MarkerName, SendStatus } from "../reducers/chatsSlice"
import ParserHelpers from "./parserHelpers"

class MarkerParser {
  static parse(
    stanza: Element,
    options?: { timestamp?: string; sendStatus?: SendStatus },
  ): Marker {
    if (!stanza.attrs.from) {
      throw `Can not parse chat marker, from attr is missing: ${stanza.toString()}`
    }

    if (!stanza.attrs.to) {
      throw `Can not parse chat marker, to attr is missing: ${stanza.toString()}`
    }

    const marker =
      MarkerParser.getChatMarker("received", stanza) ||
      MarkerParser.getChatMarker("displayed", stanza)

    if (!marker) {
      throw `Can not parse chat marker, marker is not present: ${stanza.toString()}`
    }

    if (!marker.attrs.id) {
      throw `Can not parse chat marker, id attr must be present: ${stanza.toString()}`
    }

    return {
      from: stanza.attrs.from,
      to: stanza.attrs.to,
      name: marker.name as MarkerName,
      markedMessageId: marker.attrs.id,
      timestamp: options?.timestamp || new Date().toISOString(),
      id: stanza.attrs.id,
      stanzaId: ParserHelpers.stanzaId(stanza),
      type: stanza.attrs.type || "chat",
      sendStatus: options?.sendStatus,
    } as Marker
  }

  static getChatMarker(name: MarkerName, stanza: Element): Element | undefined {
    return stanza.getChild(name, "urn:xmpp:chat-markers:0")
  }
}

export default MarkerParser
