import React, {
  RefObject,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react"
import { captureError } from "../../ErrorHandlers"
import css from "./MessageBubleBody.module.css"
import { MessageFallback, type Message } from "../../reducers/chatsSlice"
import MessageHelpers, { MessageStatus } from "../../lib/messageHelpers"
import { Icon } from "@blueprintjs/core"
import { Interweave } from "interweave"
import { UrlMatcher } from "interweave-autolink"
import classNames from "classnames"
import { XmppContext } from "../../stream/xmppClient"
import { useAppDispatch, useAppSelector } from "../../reducers/hooks"
import { messageAuthorName } from "../../reducers/profilesSliceSelectors"
import { DriverContext } from "../../contexts/DriverContext"
import { isSafari, isIOS } from "react-device-detect"
import { saveAs } from "file-saver"
import AudioPlayer from "../AudioPlayer"
import { ShowMore } from "@re-dev/react-truncate"
import { selectSettings } from "../../reducers/settingsSlice"
import SettingsApi from "../../api/settingsApi"
import {
  selectActiveChat,
  selectChatMessagesWithAppliedRetractionsMemo,
  selectDriverParticipant,
  selectMessageChat,
} from "../../reducers/chatsSliceSelectors"
import { ChatInputTextAreaRef } from "../ChatInput"
import { ChatHistoryContext } from "../../contexts/ChatHistoryContext"
import { setChatReplyMessage } from "../../reducers/chatRepliesSlice"
import { ReadStatus } from "./ReadStatus"
import LazyImage from "./LazyImage"
import { getDownloadFileName } from "./utils/getDownloadFileName"
import { mapFileUrlToSameOrigin } from "./utils/mapFileToSameOrigin"
import FileStub from "./FileStub"
import AdHocCommands from "../../api/adHocCommands"
import FileShareResultParser from "../../lib/fileShareResultParser"
import MessageBubbleToolbar from "../MessageBubbleToolbar"
import XmppGroupChatApi from "../../api/xmppGroupChatApi"
import MessageReactions from "../MessageReactions"
import { useMessageBubbleToolbarContext } from "../../contexts/MessageBubbleToolbarContext"
import ClipboardWriter from "../../lib/clipboardWriter"
import { markSending } from "../../reducers/sendoutQueueSlice"

const handleDownload = (url: string, fileName: string) => {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      saveAs(blob, fileName)
    })
    .catch((error) => {
      console.error("Error while downloading file:", error)
    })
}

export type MessageBubleProps = {
  message: Message
  status?: MessageStatus | undefined
  highlight?: boolean
  grouped?: boolean
  withDriver?: boolean
  onDelete: (message: Message) => void
  chatInputRef: RefObject<ChatInputTextAreaRef>
  naked?: boolean
}

const MissedCallWidget = ({ phoneNumber }: { phoneNumber: string }) => {
  return (
    <a
      href={`tel:${phoneNumber}`}
      className="flex items-center gap-2 p-2 rounded-md bg-gray-50 hover:no-underline"
    >
      <Icon
        icon="phone"
        size={16}
        color="#215db0"
        className="p-2 bg-gray-100 rounded-full"
      />
      <p className="font-bold">Missed voice call</p>
    </a>
  )
}

type MessageBodyProps = {
  message: Message
  downloadFileName?: string | null
  // eslint-disable-next-line
  className?: string
  compact?: boolean
  // eslint-disable-next-line
  naked?: boolean
}

export const MessageBody = ({
  message,
  downloadFileName,
  className,
  compact,
  naked = false,
}: MessageBodyProps) => {
  const bodyRef = useRef<HTMLDivElement>(null)
  const { myJid } = useContext(XmppContext)

  const { setDriverNextDestination, driver } = useContext(DriverContext)
  const { client } = useContext(XmppContext)
  const activeChat = useAppSelector(selectActiveChat)
  const messageChat = useAppSelector(
    selectMessageChat(message, myJid.toString()),
  )

  const onAddressClick = useCallback(
    (e: any) => {
      e.preventDefault()
      const address = (e as React.MouseEvent).currentTarget.textContent
      if (address && driver) {
        setDriverNextDestination(address, driver)
      }
      window.analytics.track("AddressLinkClick")
    },
    [setDriverNextDestination, driver],
  )

  const handleShareLink = useCallback(
    (filename: string) =>
      ClipboardWriter.executeAndWriteResult(async () => {
        window.analytics.track("ShareFileLinkGenerated", {
          userJid: myJid.toString(),
          messageId: message.id,
          fileUrl: message.url,
          chatJid: activeChat.jid,
        })

        if (message.url) {
          const response = await AdHocCommands.shareFile({
            client,
            fileUrl: message.url,
            messageId: message.id,
            messageRoomJid: messageChat?.jid,
            triggeringRoomJid: activeChat.jid,
            userJid: myJid.toString(),
          })

          const fileShareResult = FileShareResultParser.parse(response)
          if (fileShareResult?.shareToken) {
            window.appToaster.show({
              message: "Link copied to clipboard",
              intent: "success",
            })
          } else {
            window.appToaster.show({
              message:
                "Sorry, but we could not share this file. Please try again later.",
              intent: "danger",
            })
          }

          filename = filename.replace(" ", "_")

          return `${process.env.REACT_APP_FILES_ENDPOINT}/${fileShareResult?.shareToken}/${filename}`
        }
      }),
    [message, client, myJid, activeChat, messageChat],
  )

  useEffect(() => {
    const currentBodyRef = bodyRef.current
    if (!currentBodyRef) return

    const links = currentBodyRef.getElementsByClassName(css.address)

    Array.prototype.forEach.call(links, (link: Element) => {
      if (link.nodeName === "A") {
        link.addEventListener("click", onAddressClick)
      }
    })

    return () => {
      Array.prototype.forEach.call(links, (link: Element) => {
        if (link.nodeName === "A") {
          link.removeEventListener("click", onAddressClick)
        }
      })
    }
  })

  const bodyWithAppliedReferences = (): string => {
    return MessageHelpers.getBodyWithAppliedAddressReferences(
      message,
      (address) => `<a class="${css.address}">${address}</a>`,
    )
  }

  const renderAudioPlayer = (
    url: string,
    downloadFileName: string | null | undefined,
  ) => {
    if ((isSafari || isIOS) && /\.oga/i.test(url)) {
      return <AudioPlayer src={mapFileUrlToSameOrigin(url)} />
    } else {
      return (
        <audio controls>
          <source src={mapFileUrlToSameOrigin(url)} />
          {/* If audio element not supported render FileStub */}
          <FileStub
            url={mapFileUrlToSameOrigin(url)}
            name={message.fileName}
            downloadFileName={downloadFileName}
            handleDownload={!naked ? handleDownload : undefined}
            handleShareLink={!naked ? handleShareLink : undefined}
            message={message}
          />
        </audio>
      )
    }
  }

  const renderFile = (
    url: string,
    body: string,
    downloadFileName: string | null | undefined,
  ) => {
    if (/\.png|\.jpg|\.jpeg/i.test(url)) {
      return (
        <LazyImage
          url={mapFileUrlToSameOrigin(url)}
          downloadFileName={downloadFileName}
          compact={compact}
          handleDownload={!naked ? handleDownload : undefined}
          handleShareLink={!naked ? handleShareLink : undefined}
          message={message}
        />
      )
    } else if (/\.oga|\.mp3\.wav/i.test(url)) {
      return (
        <div className={css.audio}>
          {renderAudioPlayer(url, downloadFileName)}
          {body != url && !compact && (
            <div className={css.transcript}>
              <ShowMore lines={2} more="Read more" less="Read less">
                {body}
              </ShowMore>
            </div>
          )}
        </div>
      )
    } else {
      return (
        <FileStub
          url={mapFileUrlToSameOrigin(url)}
          name={message.fileName}
          downloadFileName={downloadFileName}
          handleDownload={!naked ? handleDownload : undefined}
          handleShareLink={!naked ? handleShareLink : undefined}
          message={message}
        />
      )
    }
  }

  const displayBody = () => {
    if (message.missedCallNumber) {
      return <MissedCallWidget phoneNumber={message.missedCallNumber} />
    }

    if (message.file && message.url) {
      return renderFile(message.url, message.body, downloadFileName)
    } else {
      return (
        <Interweave
          content={bodyWithAppliedReferences()}
          newWindow
          matchers={[new UrlMatcher("url")]}
        />
      )
    }
  }

  return (
    <div className={classNames(css.messageBody, className)} ref={bodyRef}>
      {message.retracted ? <RetractedMessagePlaceholder /> : displayBody()}
    </div>
  )
}

export const RetractedMessagePlaceholder = () => (
  <span className="italic text-dark-gray">
    <Icon icon="disable" className="grayscale" /> This message was deleted
  </span>
)

type MessageTranslationProps = {
  message: Message
}
const MessageTranslation = ({ message }: MessageTranslationProps) => {
  const settings = useAppSelector(selectSettings)
  const { client } = useContext(XmppContext)

  const toggleTranslations = async () => {
    try {
      await SettingsApi.setShowTranslations(client, !settings.showTranslations)
      window.analytics?.track("ToggleTranslations", {
        to: settings.showTranslations,
      })
    } catch (error) {
      captureError(error, {
        origin: "UserAction",
        extra: { message: "setShowTranslations" },
      })
    }
  }

  const renderTranslation = () => {
    if (message.translation) {
      return (
        <>
          {settings.showTranslations && (
            <div className={css.translation}>{message.translation}</div>
          )}
          <div className={css.translationLink}>
            <a onClick={toggleTranslations}>
              {settings.showTranslations
                ? "Hide translations"
                : "Show translations"}
            </a>
          </div>
        </>
      )
    }
    return <></>
  }

  return renderTranslation()
}

const MessageForwarded = ({
  message,
  downloadFileName,
  compact,
}: MessageBodyProps) => {
  const { myJid } = useContext(XmppContext)
  const authorName = useAppSelector(messageAuthorName(message, myJid))

  return (
    <div className={css.message}>
      <span className={classNames(css.userName, css.highlight)}>
        {authorName}
      </span>
      <MessageBody
        message={message}
        downloadFileName={downloadFileName}
        compact={compact}
      />
    </div>
  )
}

type MessageReplyProps = {
  message?: Message
  fallback: MessageFallback
  className?: string
}

const MessageReply = ({ message, fallback, className }: MessageReplyProps) => {
  const { jumpAndHighlightMessage } = useContext(ChatHistoryContext)

  return (
    <div
      className={classNames(
        className,
        "relative flex flex-col items-baseline select-none transition-colors rounded-sm gap-1 px-3 py-2 my-1 bg-quoted-message-50 before:bg-active-chat before:w-[3px] before:rounded-l-md before:absolute before:top-0 before:left-0 before:h-full",
        message && "cursor-pointer hover:bg-quoted-message-100",
      )}
      onClick={() => {
        if (message) jumpAndHighlightMessage(message.id)
      }}
    >
      {message ? (
        <MessageReplyContent message={message} />
      ) : (
        <>
          <p className="text-xs font-bold">{fallback.author}</p>
          <p className="max-w-md text-xs">{fallback.body}</p>
        </>
      )}
    </div>
  )
}

type MessageAuthorProps = {
  message: Message
}

export const MessageAuthor = ({ message }: MessageAuthorProps) => {
  const { myJid } = useContext(XmppContext)
  const authorName = useAppSelector(messageAuthorName(message, myJid))
  const activeChat = useAppSelector(selectActiveChat)
  const driverParticipant = useAppSelector(
    selectDriverParticipant(activeChat.jid),
  )

  const isMessageFromDriver = MessageHelpers.hasComeFromJid(
    message,
    driverParticipant?.jid,
  )

  return (
    <span
      className={classNames(
        "text-xs font-bold",
        isMessageFromDriver && "text-active-chat",
      )}
    >
      {authorName}
    </span>
  )
}

const MessageReplyContent = ({ message }: { message: Message }) => {
  return (
    <>
      <MessageAuthor message={message} />
      <MessageBody
        message={message}
        className="max-w-full pointer-events-none"
        downloadFileName={null}
        compact
      />
    </>
  )
}

const MessageBubleBody = ({
  message,
  status,
  onDelete,
  highlight = false,
  grouped = false,
  withDriver = false,
  chatInputRef,
  naked = false,
}: MessageBubleProps) => {
  const { client, myJid } = useContext(XmppContext)
  const { reactionPicker } = useMessageBubbleToolbarContext()

  const dispatch = useAppDispatch()
  const activeChat = useAppSelector(selectActiveChat)
  const authorName = useAppSelector(messageAuthorName(message, myJid))
  const messageWithAuthor = message.forwarded ? message.forwarded : message
  const fileSenderName = useAppSelector(
    messageAuthorName(messageWithAuthor, myJid),
  )
  const downloadFileName = getDownloadFileName(message, fileSenderName)
  const chatMessages = useAppSelector((state) =>
    selectChatMessagesWithAppliedRetractionsMemo(state, activeChat),
  )

  // Prevent replying to missed calls messages
  // as they do not exist on the WhatsApp side
  const canBeRepliedTo = !message.missedCallNumber

  const selectAsMessageToReply = () => {
    if (!canBeRepliedTo) return

    dispatch(setChatReplyMessage({ chatJid: activeChat.jid, message }))
    chatInputRef.current?.focus()
    window.analytics.track("SelectMessageToReply", {
      chatJid: activeChat.jid,
      messageId: message.id,
    })
  }

  const replyMessage = useMemo(() => {
    return chatMessages.find((msg) => msg.id === message.replyMessage?.id)
  }, [chatMessages, message.replyMessage?.id])

  if (message.retracted) {
    return (
      <div>
        <MessageBody message={message} />
        <div className={classNames(css.timeAndStatus, "select-none")}>
          <div className={css.timeStamp}>
            {MessageHelpers.timestamp(message)}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      {!naked && (
        <MessageBubbleToolbar
          ref={reactionPicker.toolbarRef}
          onReplyClick={() => selectAsMessageToReply()}
          currentReactions={message.reactions}
          onReactionSelect={(reaction) =>
            XmppGroupChatApi.sendMessageReaction(
              client,
              activeChat.jid,
              message.id,
              reaction,
            )
          }
          onDelete={
            MessageHelpers.hasComeFromJid(message, myJid.toString())
              ? () => onDelete(message)
              : undefined
          }
        />
      )}
      <span
        className={classNames(css.userName, {
          [css.highlight]: highlight,
          [css.remove]: grouped,
        })}
      >
        {authorName}
      </span>
      {message.forwarded && (
        <MessageForwarded
          message={message.forwarded}
          downloadFileName={downloadFileName}
        />
      )}
      {message.replyMessage && (
        <MessageReply
          message={replyMessage}
          fallback={message.replyMessage}
          className={classNames("-mx-1.5", grouped && "-mt-1.5")}
        />
      )}
      <MessageBody
        message={message}
        downloadFileName={downloadFileName}
        naked={naked}
      />
      {!naked && <MessageTranslation message={message} />}
      <MessageReactions message={message} className="mt-1" />
      <div className={classNames(css.timeAndStatus, "select-none")}>
        <div className={css.timeStamp}>{MessageHelpers.timestamp(message)}</div>
        {status && (
          <div className={css.status}>
            <ReadStatus
              status={status}
              withDriver={withDriver}
              onRetry={() => {
                window.analytics.track("Sendout.RetryManual", {
                  messageId: message.id,
                })
                dispatch(markSending({ messageId: message.id }))
                window.sendoutQueueWorker.send(message.id)
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(MessageBubleBody)
