import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import parse from "@xmpp/xml/lib/parse"
import { PURGE, REHYDRATE } from "redux-persist"

export type SendoutQueue = SendoutQueueItem[]

type MessageType = "chat" | "groupchat"

export type SendoutQueueItem = {
  stanza: string
  messageId: string
  messageType: MessageType
  targetJid: string
  failed: boolean
}

export type AddAction = {
  stanza: string
  messageId: string
  messageType: MessageType
}

export type MarkSendingAction = {
  messageId: string
}

export type MarkSentAction = {
  messageId: string
}

export type MarkFailedAction = {
  messageId: string
}

const initialState: SendoutQueue = []

const sendoutQueueSlice = createSlice({
  name: "sendoutQueue",
  initialState,
  reducers: {
    add: (state: SendoutQueue, action: PayloadAction<AddAction>) => {
      const element = parse(action.payload.stanza)
      const targetJid = element.attrs.to
      state.push({ failed: false, ...action.payload, targetJid })
    },
    markSending: (
      state: SendoutQueue,
      action: PayloadAction<MarkSendingAction>,
    ) => {
      const item = state.find(
        (item) => item.messageId === action.payload.messageId,
      )
      if (item) {
        item.failed = false
      }
    },
    markSent: (state, action: PayloadAction<MarkSentAction>) => {
      const newState = []
      for (const item of state) {
        if (item.messageId === action.payload.messageId) {
          console.log(`[SendoutQueueWorker] Acking ${item.messageId}`)
          window.analytics.track("Sendout.MarkSent", item)
        } else {
          newState.push(item)
        }
      }
      state.splice(0, state.length, ...newState)
    },
    markFailed: (state, action: PayloadAction<MarkFailedAction>) => {
      const item = state.find((e) => e.messageId === action.payload.messageId)

      if (item) {
        console.warn(
          `[SendoutQueueWorker] Tried too many times, aborting ${item.messageId}`,
        )
        window.analytics.track("Sendout.Failed", { messageId: item.messageId })
        item.failed = true
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(REHYDRATE, (state, action) => {
      // @ts-ignore
      const sendoutQueue: SendoutQueue = action?.payload?.sendoutQueue
      if (sendoutQueue) {
        for (const e of sendoutQueue) {
          state.push(e)
          if (!e.failed && e.messageType === "groupchat") {
            console.info(`[XEP-0198] Retrying from store ${e.messageId}`)
            window.analytics.track("Sendout.RetryFromStore", {
              messageId: e.messageId,
            })
            setTimeout(() => {
              window.sendoutQueueWorker.send(e.messageId)
            }, 10000)
          }
        }
      }
    })
    builder.addCase(PURGE, () => initialState)
  },
})

export const { add, markSent, markFailed, markSending } =
  sendoutQueueSlice.actions

export default sendoutQueueSlice.reducer
