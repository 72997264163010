import React, { memo, useContext, useMemo } from "react"
import ChatHelpers from "../lib/chatHelpers"
import {
  ChatWithMessages,
  selectFilteredChatsAndMessages,
} from "../reducers/chatsSliceSelectors"
import { useAppSelector } from "../reducers/hooks"
import { XmppContext } from "../stream/xmppClient"
import utilsCss from "./utilsCss.module.css"

const ChatListUnreadCount = ({ chatJids }: { chatJids: string[] }) => {
  const { myJid } = useContext(XmppContext)
  const chats: ChatWithMessages[] = useAppSelector(
    selectFilteredChatsAndMessages(chatJids),
  )

  const groupUnreadCount = useMemo(() => {
    return chats.reduce(
      (acc, curr) =>
        acc + ChatHelpers.unreadMessagesCount(curr, myJid.toString()),
      0,
    )
  }, [chats, myJid])

  return (
    <>
      {groupUnreadCount > 0 && (
        <div className={utilsCss.unreadTag}>{groupUnreadCount}</div>
      )}
    </>
  )
}

export default memo(ChatListUnreadCount)
