import React, { useCallback, useContext, useState } from "react"
import { XmppContext } from "../stream/xmppClient"
import classNames from "classnames"
import css from "./ChatListHeader.module.css"
import cssUtils from "./utilsCss.module.css"
import JoinChatPopover from "./JoinChatPopover"
import { Button, Icon, OverlaysProvider, Tooltip } from "@blueprintjs/core"
import SearchPopover from "./search/SearchPopover"
import RoomLoading from "./RoomLoading"

type ChatListHeaderProps = {
  onOpenChatInfo: () => void
  onActiveChatChange: (chatJid: string) => void
}

const ChatListHeader = ({
  onOpenChatInfo,
  onActiveChatChange,
}: ChatListHeaderProps) => {
  const { client, myJid } = useContext(XmppContext)

  const [isSearchOpen, setIsSearchOpen] = useState(false)

  const showSearch = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      setIsSearchOpen(true)
    },
    [setIsSearchOpen],
  )

  return (
    <div className={css.chatListHeader}>
      <div className="flex flex-row space-x-2">
        <button className={css.user} onClick={onOpenChatInfo}>
          <span
            className={classNames(css.status, {
              [css.offline]: client.status !== "online",
            })}
          ></span>
          &nbsp;
          <b className={cssUtils.bold}>{myJid.getLocal()}</b>
        </button>
        <RoomLoading />
      </div>
      <div className="flex flex-row justify-items-end">
        <OverlaysProvider>
          <Tooltip content="Search in messages" position="left">
            <Button
              minimal
              icon={<Icon icon="search" size={18} />}
              onClick={showSearch}
            />
          </Tooltip>

          <SearchPopover
            isOpen={isSearchOpen}
            onClose={() => setIsSearchOpen(false)}
          />
        </OverlaysProvider>
        <JoinChatPopover onActiveChatChange={onActiveChatChange} />
      </div>
    </div>
  )
}

export default ChatListHeader
