import React, { useContext, useEffect, useRef, useState } from "react"
import AdHocCommands from "../../api/adHocCommands"
import SearchResultParser, { SearchHit } from "../../lib/searchResultParser"
import { XmppContext } from "../../stream/xmppClient"
import SearchMessageInDetailsRow from "./SearchMessageInDetailsRow"

export default function SearchMessageDetails({
  clickedMessageHit,
}: {
  clickedMessageHit: SearchHit
}) {
  const { client } = useContext(XmppContext)
  const [hits, setHits] = useState<SearchHit[]>([])
  const clickedMessageRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const searchMessageContext = async () => {
      const response = await AdHocCommands.getMessageContextFromSearch({
        client,
        chatJid: clickedMessageHit.document.chat_jid,
        timestamp: clickedMessageHit.document.timestamp.toString(),
      })

      const hits = SearchResultParser.parse(response)?.hits || []
      setHits(hits)
      setTimeout(() => {
        clickedMessageRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        })
      }, 200)
    }

    searchMessageContext()
  }, [clickedMessageHit, client])

  const inGroup = (hit: SearchHit, previousHit: SearchHit | undefined) => {
    if (!previousHit) {
      return false
    }

    const previousTimestamp = new Date(previousHit.document.timestamp / 1000)
    const currentTimestamp = new Date(hit.document.timestamp / 1000)

    return (
      previousHit.document.chat === hit.document.chat &&
      previousHit.document.sender === hit.document.sender &&
      currentTimestamp.getTime() - previousTimestamp.getTime() < 1000 * 60 * 5
    )
  }

  return (
    <div className="flex flex-col gap-y-4 w-full items-start">
      {hits.map((hit, index) => (
        <div
          ref={
            hit.document.id === clickedMessageHit.document.id
              ? clickedMessageRef
              : undefined
          }
          key={hit.document.id}
        >
          <SearchMessageInDetailsRow
            messageHit={
              hit.document.id === clickedMessageHit.document.id
                ? clickedMessageHit
                : hit
            }
            inGroup={inGroup(hit, hits[index - 1])}
          />
        </div>
      ))}
    </div>
  )
}
