import { Client } from "@xmpp/client"
import { Element } from "@xmpp/xml"
import { parseArchivedTimestamp, parseStanza } from "../../lib/stanzaParser"
import MessageParser from "../../lib/messageParser"
import MarkerParser from "../../lib/markerParser"
import {
  Marker,
  Message,
  MessageFileShared,
  MessageRetraction,
  Reaction,
} from "../../reducers/chatsSlice"
import { Order } from "./MAM"
import ReactionsParser from "../../lib/reactionsParser"
import MessageFileSharedParser from "../../lib/messageFileSharedParser"
import RetractionParser from "../../lib/retractionParser"

export type ProcessResults = {
  messagesCount: number
  markersCount: number
  reactionsCount: number
  messageFileSharedCount: number
  messageRetractionsCounts: number
}

export type GroupedMessages = {
  corrections: Message[]
  messages: Message[]
}

class MAMStanzaProcessor {
  static groupByMessagesAndCorrections(messages: Message[]): GroupedMessages {
    return messages.reduce(
      (acc, message) => {
        if (message.replaceId) {
          acc.corrections.push(message)
        } else {
          acc.messages.push(message)
        }

        return acc
      },
      { corrections: [], messages: [] } as GroupedMessages,
    )
  }

  static normalizePagesAscending<
    T extends
      | Message
      | Marker
      | Reaction
      | MessageFileShared
      | MessageRetraction
      | string,
  >(pages: T[][], archiveOrder: Order, flippedPages: boolean): T[] {
    if (archiveOrder === "asc" && !flippedPages) {
      return pages.flat()
    }

    if (archiveOrder === "asc" && flippedPages) {
      return pages.flatMap((page) => page.reverse())
    }

    if (archiveOrder === "desc" && !flippedPages) {
      return pages.reverse().flat()
    }

    if (archiveOrder === "desc" && flippedPages) {
      return pages.flat().reverse()
    }

    throw new Error("Invalid archive order")
  }

  static accumulatePages(
    messagesAccumulator: Message[][],
    markersAccumulator: Marker[][],
    reactionsAccumulator: Reaction[][],
    messageFileSharesAccumulator: MessageFileShared[][],
    messageRetractionsAccumulator: MessageRetraction[][],
    client: Client,
  ) {
    return (stanzas: Element[]): ProcessResults => {
      const messages: Message[] = []
      const markers: Marker[] = []
      const reactions: Reaction[] = []
      const messageFileShares: MessageFileShared[] = []
      const messageRetractions: MessageRetraction[] = []

      stanzas.forEach((stanza) => {
        const [messageStanza, type] = parseStanza(
          stanza,
          client.jid?.bare().toString(),
        )

        if (!messageStanza || !type) {
          return
        }

        if (type === "archived_message") {
          const message = MessageParser.parse(messageStanza, {
            timestamp: parseArchivedTimestamp(stanza),
          })

          messages.push(message)
          return
        }

        if (type === "archived_chat_marker") {
          const marker = MarkerParser.parse(messageStanza, {
            timestamp: parseArchivedTimestamp(stanza),
          })

          markers.push(marker)
          return
        }

        if (type === "archived_reaction") {
          const reaction = ReactionsParser.parse(
            messageStanza,
            parseArchivedTimestamp(stanza),
          )
          if (!reaction) return

          reactions.push(reaction)
          return
        }

        if (type === "archived_message_file_shared") {
          const messageFileShared = MessageFileSharedParser.parse(
            messageStanza,
            parseArchivedTimestamp(stanza),
          )

          if (!messageFileShared) return

          messageFileShares.push(messageFileShared)
        }

        if (type === "archived_retraction") {
          const messageRetraction = RetractionParser.parse(
            messageStanza,
            parseArchivedTimestamp(stanza),
          )

          if (!messageRetraction) return

          messageRetractions.push(messageRetraction)
        }
      })

      messagesAccumulator.push(messages)
      markersAccumulator.push(markers)
      reactionsAccumulator.push(reactions)
      messageFileSharesAccumulator.push(messageFileShares)
      messageRetractionsAccumulator.push(messageRetractions)

      return {
        messagesCount: messages.length,
        markersCount: markers.length,
        reactionsCount: reactions.length,
        messageFileSharedCount: messageFileShares.length,
        messageRetractionsCounts: messageRetractions.length,
      }
    }
  }
}

export default MAMStanzaProcessor
