import MessageHelpers from "../../../lib/messageHelpers"
import { Message } from "../../../reducers/chatsSlice"

export const getDownloadFileName = (
  message: Message,
  authorName: string,
  extraIndex?: number,
): string | null => {
  const address = message.forwarded ? extractAddress(message.body) : null
  const timestamp = MessageHelpers.timestamp(message)
  const parts = timestamp.split(" ")
  const datePart = parts[1]
  let transformedDate = datePart.replace(/\//g, "-")
  const url = message.forwarded ? message.forwarded.url : message.url
  if (!url) return null
  let extension = "txt"
  const nameStart = url.lastIndexOf(".") + 1
  if (nameStart !== 0) {
    extension = url.slice(nameStart, url.length)
    if (!/^[a-zA-Z0-9]{1,6}$/.test(extension)) {
      extension = "txt"
    }
  }

  if (extraIndex) {
    transformedDate = `${transformedDate}-${extraIndex}`
  }

  if (address) {
    return `${transformedDate} ${authorName} ${address}.${extension}`
  }
  return `${transformedDate} ${authorName}.${extension}`
}

const extractAddress = (body: string): string | null => {
  const addressRegex = /Address: (.*)\n/
  const match = body.match(addressRegex)

  if (match && match.length > 1) {
    return match[1]
  } else {
    return null
  }
}
