import classNames from "classnames"
import React, { memo } from "react"
import { isMobile } from "react-device-detect"

import css from "./LazyImage.module.css"
import { FileToolbar } from "./FileToolbar"
import { Message } from "../../reducers/chatsSlice"

const LazyImage = ({
  url,
  downloadFileName,
  compact,
  handleDownload,
  handleShareLink,
  inGroup,
  message,
  nonClickable,
}: {
  url: string
  downloadFileName?: string | null
  compact?: boolean
  inGroup?: boolean
  handleDownload?: (url: string, fileName: string) => void
  handleShareLink?: (filename: string) => void
  message?: Message
  nonClickable?: boolean
}) => {
  const ImageComponent = () => (
    <img
      src={url}
      loading="lazy"
      className={classNames(
        css.image,
        !inGroup && "object-scale-down",
        inGroup && "w-28 h-28 object-cover rounded-lg",
        compact && "h-20",
      )}
    />
  )

  return (
    <div className="w-full h-full group">
      <div className={classNames(css.imageContainer, "w-full h-full relative")}>
        {handleDownload && handleShareLink && downloadFileName && message && (
          <div>
            <FileToolbar
              url={url}
              downloadFileName={downloadFileName!}
              handleDownload={handleDownload}
              handleShareLink={handleShareLink}
              message={message}
              isMobile={isMobile}
            />
          </div>
        )}
        {nonClickable ? (
          <ImageComponent />
        ) : (
          <a
            href={url}
            target="_blank"
            rel="noreferrer"
            className="w-full h-full"
          >
            <ImageComponent />
          </a>
        )}
      </div>
    </div>
  )
}

export default memo(LazyImage)
