import { Client } from "@xmpp/client"
import { IncomingContext } from "@xmpp/middleware"
import xml, { Element } from "@xmpp/xml"

const NS = "urn:xmpp:sm:3"

type OutgoingQueueAttributes = {
  entity: Client
  middleware: any
  onAck: (stanza: Element) => void
}

export const outgoingQueue = ({
  entity,
  middleware,
  onAck,
}: OutgoingQueueAttributes): void => {
  const unacknowledgedStanzas: Element[] = []
  let ackedCount: number = 0
  let sentSinceLastAckRequest = 0
  let isEnabled = false

  const ack = () => {
    if (unacknowledgedStanzas.length === 0) {
      console.error("[XEP-0198] Should ack, but no message in the queue")
      return
    }

    const stanza = unacknowledgedStanzas.shift()
    const id = stanza?.attrs.id
    if (id) {
      if (stanza.is("message")) {
        // Not too verbose - skip `iq` and `presence`
        console.info(`[XEP-0198] Acking ${stanza.name} id=${id}`)
      }
      onAck(stanza)
    }
  }

  middleware.use((context: IncomingContext<any>, next: any) => {
    const { stanza } = context

    if (stanza.is("resumed") || stanza.is("enabled")) {
      console.info(`[XEP-0198] ${stanza}`)
    }

    if (stanza.is("a")) {
      const newH = stanza.attrs.h
      const newlyAcked = newH - ackedCount
      console.info(
        `[XEP-0198] Incoming a, h=${newH} oldH=${ackedCount} unackedLength=${unacknowledgedStanzas.length}`,
      )
      for (let i = 0; i < newlyAcked; i++) {
        ack()
      }
      ackedCount = newH
    }

    return next()
  })

  const sendR = () => {
    entity.send(xml("r", { xmlns: NS }))
    sentSinceLastAckRequest = 0
  }

  entity.on("send", (stanza) => {
    const stanzaId = stanza.attrs.id

    if (stanza.is("enable") || stanza.is("resume")) {
      isEnabled = true
      console.info(`[XEP-0198] (Re)enabling queue ${stanza}`)
      unacknowledgedStanzas.splice(0)
      return
    }

    if (stanza.is("r")) {
      console.info(`[XEP-0198] Sending <r>`)
      return
    }

    if (!(stanza.is("message") || stanza.is("iq") || stanza.is("presence"))) {
      return
    }

    if (!isEnabled) {
      console.info(
        `[XEP-0198] SM not enabled, ${stanza.name} id=${stanzaId} ${stanza}`,
      )
      return
    }

    unacknowledgedStanzas.push(stanza)
    // console.log(
    // `[XEP-0198] Adding ${stanza.name} id=${stanzaId} to unacknowledged queue, size=${unacknowledgedStanzas.length}`,
    // )
    sentSinceLastAckRequest += 1

    if (stanza.is("message") || sentSinceLastAckRequest % 10 === 0) {
      sendR()
    }
  })

  // @ts-ignore
  entity.reliabilitySM = {
    unacknowledgedStanzas,
    sendR,
  }
}
