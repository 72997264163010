import classNames from "classnames"
import React from "react"
import { SearchHit } from "../../lib/searchResultParser"
import AvatarImage from "../AvatarImage"
import SearchMessageBubbleBody from "./SearchMessageBubbleBody"

export default function SearchMessageInDetailsRow({
  messageHit,
  inGroup,
}: {
  messageHit: SearchHit
  inGroup: boolean
}) {
  return (
    <div className="flex flex-row space-x-1 md:space-x-2 items-start">
      <div className="min-w-8 min-h-8">
        {!inGroup ? <AvatarImage name={messageHit.document.sender} /> : ""}
      </div>
      <div
        className={classNames(
          `relative flex flex-col space-y-1 text-black text-sm bg-message-bubble p-2 md:p-4
          rounded-lg`,
          !inGroup &&
            `rounded-tl-none
            before:content-[''] before:border-[5px] before:m-0 before:absolute
            before:left-[-7px] before:top-[0px]
            before:border-solid
            before:border-l-transparent
            before:border-r-message-bubble
            before:border-t-message-bubble
            before:border-b-transparent`,
        )}
      >
        {!inGroup && (
          <div className="flex flex-row space-x-2">
            <span className="font-semibold">{messageHit.document.sender}</span>
          </div>
        )}
        <div className="break-all whitespace-break-spaces flex flex-col space-y-2">
          <SearchMessageBubbleBody messageHit={messageHit} filesClickable />
        </div>
        <span className="text-gray-500">
          {new Date(messageHit.document.timestamp / 1000).toLocaleString()}
        </span>
      </div>
    </div>
  )
}
