import { useMemo, useRef } from "react"
import { useOnClickOutside } from "usehooks-ts"
import { MessageBubbleToolbarActions } from "../contexts/MessageBubbleToolbarContext"

const useMessageReactionPicker = () => {
  const toolbarRef = useRef<MessageBubbleToolbarActions>(null)

  const containerRef = useRef<HTMLDivElement>(null)

  useOnClickOutside(containerRef, () => {
    toolbarRef.current?.closeEmojiPicker()
  })

  // TODO: bind can be changing, most probably this will break long press on mobile
  const picker = useMemo(() => {
    return { toolbarRef, containerRef }
  }, [])

  return picker
}

export default useMessageReactionPicker
