import { useCallback, useEffect } from "react"
import { isMobile } from "react-device-detect"

export function useSelectChatNavigation(onReturnToChatLists: () => void) {
  const navigateToChat = useCallback(() => {
    if (isMobile) {
      if (window.location.hash !== "#chat") {
        window.history.pushState({}, "", "#chat")
      }
    }
  }, [])

  useEffect(() => {
    const listener = () => onReturnToChatLists()
    window.addEventListener("popstate", listener)
    return () => window.removeEventListener("popstate", listener)
  }, [onReturnToChatLists])

  return { navigateToChat }
}
