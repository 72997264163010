import { useAppSelector } from "../reducers/hooks"
import { selectDriverParticipant } from "../reducers/chatsSliceSelectors"
import { Chat, Participant } from "../reducers/chatsSlice"
import { findProfile } from "../reducers/profilesSliceSelectors"
import { participantPhotoToSrc } from "../components/AvatarImage"

type ChatInfoReturn = [
  Participant | undefined,
  string,
  string | undefined,
  string,
]

const useChatInfo = (chat: Chat): ChatInfoReturn => {
  const chatDriverParticipant = useAppSelector(
    selectDriverParticipant(chat.jid),
  )
  const profile = useAppSelector(findProfile(chat.jid))

  const additionalInfo = (): string => {
    if (!chatDriverParticipant) return ""
    const attrs = [
      chatDriverParticipant.phone,
      chatDriverParticipant.plates,
    ].filter((attr) => attr)

    if (attrs.length === 0) return ""

    if (attrs.length === 1) return attrs[0] || ""

    return attrs.join(" | ")
  }

  const chatTitle = () => {
    if (chatDriverParticipant) {
      return chatDriverParticipant.name
    } else if (chat.room) {
      return chat.name
    } else if (profile) {
      return profile.name
    } else {
      return chat.name
    }
  }

  const photoSrc = () => {
    return participantPhotoToSrc(
      chatDriverParticipant ? chatDriverParticipant.photo : profile?.photo,
    )
  }

  return [chatDriverParticipant, chatTitle(), photoSrc(), additionalInfo()]
}

export default useChatInfo
