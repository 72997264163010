export {}

enum AnalyticsEvent {
  Startup = "Startup",
  Track = "AnalyticsTrack",
  Identify = "AnalyticsIdentify",
}

const setupSegmentIfReactNative = () => {
  if (!window.ReactNativeWebView) return
  // An event sent to mobile that the UI was rendered.
  // Used to calculate app startup time.
  window.ReactNativeWebView.postMessage(
    JSON.stringify({ type: AnalyticsEvent.Startup }),
  )

  // Monkey patch analytics identify and track to send events to mobile and handle them there
  window.analytics.track = (event: string, properties?: Object) => {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: AnalyticsEvent.Track,
        data: { event, properties },
      }),
    )
  }
  // @ts-ignore - identify function is overloaded in type definitions
  // and ts insist that the function first argument needs to be a callback
  // when in reality there is a version that takes userId and traits and
  // it is the one we are using in the SignIn setupAnalytics function
  window.analytics.identify = (userId: string, traits?: Object) => {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: AnalyticsEvent.Identify,
        data: { userId, traits },
      }),
    )
  }
}

if (process.env.NODE_ENV === "production") {
  window.analytics.load(
    process.env.REACT_APP_SEGMENT_WEB_APP_SOURCE_KEY || "",
    {
      integrations: {
        "Segment.io": {
          apiHost: process.env.REACT_APP_SEGMENT_API_HOST,
          deliveryStrategy: {
            strategy: "batching",
            config: {
              size: 10,
              timeout: 5000,
            },
          },
        },
      },
    },
  )
  setupSegmentIfReactNative()
}

window.analytics.track("AppLoaded")
