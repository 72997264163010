import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { PURGE } from "redux-persist"
import { RootState } from "./store"

export type Subject = {
  text: string
  from: string
  timestamp: string
  chatJid: string
}

export type SubjectsState = Subject[]

const initialState: SubjectsState = []

type UpsertChatSubjectAction = {
  subject: Subject
}

export const subjectSlices = createSlice({
  name: "subjects",
  initialState,
  reducers: {
    upsertChatSubject: (
      state,
      action: PayloadAction<UpsertChatSubjectAction>,
    ) => {
      const subjectIndex = state.findIndex(
        (s) => s.chatJid === action.payload.subject.chatJid,
      )

      if (subjectIndex === -1) {
        state.push(action.payload.subject)
      } else {
        state[subjectIndex] = action.payload.subject
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState)
  },
})

export const selectChatSubject = (chatJid: string) => {
  return (state: RootState) => {
    return state.subjects.find((s) => s.chatJid === chatJid)
  }
}

export const { upsertChatSubject } = subjectSlices.actions

export default subjectSlices.reducer
