import React from "react"
import { differenceInCalendarDays, differenceInCalendarWeeks } from "date-fns"

type RelativeTimeLabelProps = {
  timestamp: string
}

const RelativeTimeLabel = ({ timestamp }: RelativeTimeLabelProps) => {
  const DAY_NAMES = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ]

  const relativeString = () => {
    const now = new Date()
    const date = new Date(timestamp)

    if (differenceInCalendarDays(now, date) === 0) {
      return date.toTimeString().slice(0, 5)
    }

    if (differenceInCalendarDays(now, date) === 1) {
      return "Yesterday"
    }

    if (differenceInCalendarWeeks(now, date, { weekStartsOn: 1 }) === 0) {
      return DAY_NAMES[date.getDay()]
    }

    return date.toLocaleDateString()
  }

  return <>{relativeString()}</>
}

export default RelativeTimeLabel
