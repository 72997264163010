import { Element } from "@xmpp/xml"
import { jid } from "@xmpp/client"

type Presence = {
  type: "connected" | "disconnected"
  from: ReturnType<typeof jid.parse>
  chat: string
  user: string
  statuses: number[]
  selfPresence?: boolean
}

export default class PresenceParser {
  static parse(stanza: Element): Presence | undefined {
    if (!stanza.getAttr("from")) return

    const from = jid.parse(stanza.getAttr("from"))
    const type = stanza.getAttr("type")

    const statuses =
      stanza
        .getChild("x", "http://jabber.org/protocol/muc#user")
        ?.getChildren("status")
        .map((status) => Number(status.getAttr("code"))) ?? []

    const user = from.resource
    const chat = from.bare().toString()

    return {
      type: type === "unavailable" ? "disconnected" : "connected",
      from,
      chat,
      user,
      statuses,
      selfPresence: statuses.includes(110),
    }
  }
}
