import { useMemo } from "react"
import ChatHelpers from "../lib/chatHelpers"
import { useAppSelector } from "../reducers/hooks"
import { selectActiveChat } from "../reducers/chatsSliceSelectors"
import { jid } from "@xmpp/client"

export function useActiveChatDriverName() {
  const activeChat = useAppSelector(selectActiveChat)

  return useMemo(() => {
    const driver = ChatHelpers.findDriverParticipant(activeChat)
    if (!driver) return

    return jid.parse(driver.jid).local
  }, [activeChat])
}
