// Copied from
// https://tusharf5.com/posts/type-safe-retry-function-in-typescript/#the-implementation

export async function retry<T extends (...arg0: any[]) => any>(
  fn: T,
  args: Parameters<T>,
  options: {
    backoff?: boolean
    currentAttempt?: number
    maxAttempts?: number
  } = {},
): Promise<Awaited<ReturnType<T>>> {
  const currentAttempt = options.currentAttempt || 1
  const maxAttempts = options.maxAttempts || 3
  try {
    const result = await fn(...args)
    return result
  } catch (e) {
    const backoffTime = options.backoff
      ? retryBackoffSleepTime(currentAttempt)
      : 0
    console.log(
      `Attempt ${currentAttempt} failed. fn=${fn.name} wait=${backoffTime}`,
    )

    if (currentAttempt >= maxAttempts) {
      console.log(`All ${maxAttempts} retry attempts exhausted`)
      throw e
    }

    await new Promise((resolve) => setTimeout(resolve, backoffTime * 1000))

    return retry(fn, args, { ...options, currentAttempt: currentAttempt + 1 })
  }
}

const retryBackoffSleepTime = (currentAttempt: number): number => {
  if (currentAttempt <= 1) return 0.5
  if (currentAttempt == 2) return 1
  if (currentAttempt == 3) return 2

  return 4
}

export type NetworkInformation = {
  type: string | undefined
  effectiveType: string | undefined
  downlink: number | undefined
  downlinkMax: number | undefined
  rtt: number | undefined
  saveData: boolean | undefined
}

export const getNetworkInformation = (): NetworkInformation | undefined => {
  // @ts-ignore
  const connection = window.navigator.connection
  if (!connection) return undefined

  return {
    type: connection.type,
    effectiveType: connection.effectiveType,
    downlink: connection.downlink,
    downlinkMax: connection.downlinkMax,
    rtt: connection.rtt,
    saveData: connection.saveData,
  }
}
