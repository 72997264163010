import { Client, xml } from "@xmpp/client"

export const pingServer = async (
  client: Client,
  timeout: number | undefined = undefined,
): Promise<number | null> => {
  const { iqCaller } = client

  try {
    const start = window.performance.now()
    const response = await iqCaller.request(
      xml("iq", { type: "get" }, xml("ping", "urn:xmpp:ping")),
      timeout,
    )
    if (response.getChild("error")) {
      return null
    } else {
      return window.performance.now() - start
    }
  } catch {
    return null
  }
}
