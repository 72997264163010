import { Client, xml } from "@xmpp/client"
import { Element } from "@xmpp/xml"
import SettingsParser from "../lib/settingsParser"
import { Settings } from "../reducers/settingsSlice"
import iqMeasure from "../instrumentation/iqMeasure"

const SettingsApi = {
  getSettings: async (
    client: Client,
  ): Promise<Partial<Settings> | undefined> => {
    const { iqCaller } = client
    const measuredIqCaller = iqMeasure(iqCaller, "pubsub_get_settings")
    const stanza = xml(
      "iq",
      { type: "get" },
      xml(
        "pubsub",
        { xmlns: "http://jabber.org/protocol/pubsub" },
        xml("items", { node: "storage:settings" }),
      ),
    )

    const result = await measuredIqCaller.request(stanza)
    return SettingsParser.parseSettings(result)
  },

  setSettingValue: (
    client: Client,
    key: string,
    value: string,
  ): Promise<Element> => {
    const { iqCaller } = client
    const measuredIqCaller = iqMeasure(iqCaller, "pubsub_publish_settings")

    const stanza = xml(
      "iq",
      {
        type: "set",
        from: client.jid?.toString(),
      },
      xml(
        "pubsub",
        { xmlns: "http://jabber.org/protocol/pubsub" },
        xml(
          "publish",
          { node: "storage:settings" },
          xml("item", { id: key }, xml("value", {}, value)),
        ),
      ),
    )

    return measuredIqCaller.request(stanza)
  },

  setMobileNotificationsEnabled: (client: Client, enabled: boolean) => {
    return SettingsApi.setSettingValue(
      client,
      "mobile_notifications_enabled",
      enabled ? "true" : "false",
    )
  },

  setShowNicknameInWhatsapp: (client: Client, enabled: boolean) => {
    return SettingsApi.setSettingValue(
      client,
      "show_nickname_in_whatsapp",
      enabled ? "true" : "false",
    )
  },

  setShowTranslations: (client: Client, enabled: boolean) => {
    return SettingsApi.setSettingValue(
      client,
      "show_translations",
      enabled ? "true" : "false",
    )
  },
}

export default SettingsApi
