import Cookies from "universal-cookie"

const TWO_WEEKS = 12096e5
const cookieOptions = {
  path: "/",
  expires: new Date(Date.now() + TWO_WEEKS),
  domain: window.location.hostname,
  secure: window.location.hostname !== "localhost",
}

export const getStoredMyJid = (): string | null => {
  const cookies = new Cookies(null, cookieOptions)
  return cookies.get("myJid")
}

export const storeMyJid = (jid: string) => {
  const cookies = new Cookies(null, cookieOptions)
  cookies.set("myJid", jid)
}
