const RECONNECTING_TOAST_DEBOUNCE = 1000

let toastTimeoutId: NodeJS.Timeout | null = null
let reconnectingToastShown = false

export default class ReconnectStatusReactor {
  static handleReconnectStatusChange(status: string) {
    if (toastTimeoutId) {
      clearTimeout(toastTimeoutId)
    }

    if (status === "reconnecting") {
      ReconnectStatusReactor.handleReconnecting()
    }

    if (status === "reconnected") {
      ReconnectStatusReactor.handleReconnected()
    }
  }

  static handleReconnecting() {
    toastTimeoutId = setTimeout(() => {
      reconnectingToastShown = true
      window.appToaster.show(
        {
          message: "Trying to reconnect ...",
          intent: "danger",
          timeout: 0,
        },
        "connection",
      )
    }, RECONNECTING_TOAST_DEBOUNCE)
  }

  static handleReconnected() {
    if (reconnectingToastShown) {
      window.appToaster.show(
        { message: "You are back online!", intent: "success", timeout: 3000 },
        "connection",
      )

      reconnectingToastShown = false
    }
  }
}
