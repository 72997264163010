import React, { memo, useMemo, useState } from "react"
import css from "./ChatList.module.css"
import SearchBar from "./SearchBar"
import ChatListGroup from "./ChatListGroup"
import { useAppSelector } from "../reducers/hooks"
import { driversBasedOnRoster } from "../reducers/rosterSelectors"
import { driverJid } from "../jidUtils"
import { selectSimpleChats } from "../reducers/chatsSliceSelectors"
import { useEffect } from "react"

type ChatListProps = {
  onActiveChatChange: (chatJid: string) => void
}

const ChatList = ({ onActiveChatChange }: ChatListProps) => {
  const [filterValue, setFilterValue] = useState<string>("")
  const driverRosterItems = useAppSelector(driversBasedOnRoster)
  const chats = useAppSelector(selectSimpleChats)

  const onValueChange = (value: string) => {
    setFilterValue(value)
  }

  const filteredChats = useMemo(() => {
    if (filterValue.length === 0) return chats

    return chats.filter((chat) =>
      chat.name.toLowerCase().includes(filterValue.toLowerCase()),
    )
  }, [filterValue, chats])

  const driversChatJids = useMemo(() => {
    return chats
      .filter((chat) => {
        return driverRosterItems.some(
          (driver) => driver.jid === driverJid(chat.jid),
        )
      })
      .map((chat) => chat.jid)
  }, [chats, driverRosterItems])

  const groupChatJids = useMemo(() => {
    return chats
      .filter((chat) => {
        return !driverRosterItems.some(
          (driver) => driver.jid === driverJid(chat.jid),
        )
      })
      .map((chat) => chat.jid)
  }, [chats, driverRosterItems])

  useEffect(() => {
    window.analytics.track("InitialRenderChatList")
  }, [])

  return (
    <>
      <div className={css.chatList}>
        {chats.length > 5 && (
          <div className={css.chatListToolbar}>
            <SearchBar value={filterValue} onValueChange={onValueChange} />
          </div>
        )}
        <ChatListGroup
          name="Groups"
          chatJids={groupChatJids}
          onActiveChatChange={onActiveChatChange}
          filterBy={filterValue}
        />
        <ChatListGroup
          name="Drivers"
          chatJids={driversChatJids}
          onActiveChatChange={onActiveChatChange}
          filterBy={filterValue}
        />
        {filteredChats.length === 0 && (
          <div className={css.noResults}>No Results</div>
        )}
      </div>
    </>
  )
}

ChatList.displayName = "ChatList"

export default memo(ChatList)
