import { Element } from "@xmpp/xml"
import { Subject } from "../reducers/subjectsSlice"
import { jid } from "@xmpp/client"

class SubjectParser {
  static parse(stanza: Element, timestamp?: string): Subject | undefined {
    const subject = stanza.getChild("subject")

    if (!subject) return undefined

    return {
      text: subject.getText(),
      from: stanza.attrs.from,
      chatJid: jid.parse(stanza.attrs.from).bare().toString(),
      timestamp: timestamp || new Date().toISOString(),
    }
  }
}

export default SubjectParser
