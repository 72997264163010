import { Client, xml } from "@xmpp/client"
import xid from "@xmpp/id"
import { Element } from "@xmpp/xml"
import { archiveQueryResult } from "../../lib/stanzaParser"
import { ProcessResults } from "./MAMStanzaProcessor"
import iqMeasure from "../../instrumentation/iqMeasure"

export type MAMQueryOptions = {
  afterStamp?: string
  afterId?: string
  beforeId?: string
  toJid?: string
  queryId?: string
  before?: boolean
  flipPage?: boolean
  withJid?: string
  max?: string
}

const archiveStanzaHandler = (accumulator: Element[], queryId: string) => {
  return (stanza: Element) => {
    if (!stanza.is("message")) return

    if (stanza.getChildText("delay") === "Offline Storage") {
      console.warn("Ignoring stanza from Offline Storage")
      return []
    }

    const archivedMessage = archiveQueryResult(stanza)

    if (
      archivedMessage &&
      stanza.getChild("result")!.attrs.queryid === queryId
    ) {
      accumulator.push(stanza)
    }
  }
}

type PageResult = {
  accumulator: Element[] // all the stanzas we received for this page
  result: Element // the resulting IQ stanza that contains the fin element
}

export const fetchArchivePage = (
  client: Client,
  queryOptions: MAMQueryOptions,
  timeout: number | undefined = undefined,
): Promise<PageResult> => {
  const request = buildArchiveQueryStanza(queryOptions)
  const { iqCaller } = client
  const measuredIqCaller = iqMeasure(iqCaller, "mam_request")

  const accumulator: Element[] = []
  return new Promise((resolve, reject) => {
    const handler = archiveStanzaHandler(
      accumulator,
      request.getChild("query")!.attrs.queryid,
    )
    client.on("stanza", handler)
    measuredIqCaller
      .request(request, timeout)
      .then((result) => resolve({ accumulator, result }))
      .catch((error) => reject(error))
      .finally(() => client.off("stanza", handler))
  })
}

export type Order = "asc" | "desc"
export type ArchiveFetchResult = {
  count: number
  hasMore: boolean
}

export const iterateThroughArchivePages = async (
  client: Client,
  queryOptions: Omit<MAMQueryOptions, "queryId">,
  processPageResults: (page: Element[]) => ProcessResults,
  order: Order,
  softMax?: number,
): Promise<ArchiveFetchResult> => {
  let totalFetched = 0
  let messagesCount = 0
  let finElement: Element | undefined = undefined
  let hasMore = true

  let shouldFetchMore = true
  while (shouldFetchMore) {
    const pageQueryOptions = buildPageQueryOptions(
      order,
      queryOptions,
      finElement,
    )

    const pageResults = await fetchArchivePage(client, pageQueryOptions)
    const processResult = processPageResults(pageResults.accumulator)

    messagesCount += processResult.messagesCount
    totalFetched += pageResults.accumulator.length

    finElement = pageResults.result.getChild("fin")

    if (!finElement) throw "Fin element not found in the archive result"

    if (finElement.attrs.complete === "true") {
      hasMore = false
    }

    if (!hasMore || (softMax && messagesCount >= softMax)) {
      shouldFetchMore = false
    }
  }

  return { count: totalFetched, hasMore }
}

const buildPageQueryOptions = (
  order: Order,
  initialQueryOptions: MAMQueryOptions,
  pageFinElement: Element | undefined,
): MAMQueryOptions => {
  const pageQueryOptions: MAMQueryOptions = {
    ...initialQueryOptions,
    queryId: xid(),
  }

  if (!pageFinElement) {
    return pageQueryOptions
  }

  const nextId =
    order === "asc" || initialQueryOptions.flipPage
      ? pageFinElement.getChild("set")?.getChildText("last")
      : pageFinElement.getChild("set")?.getChildText("first")

  if (!nextId) {
    throw "Cannot fetch an ID attribute to page through archive"
  }

  if (order === "asc") {
    return {
      ...pageQueryOptions,
      afterId: nextId,
    }
  } else {
    return {
      ...pageQueryOptions,
      beforeId: nextId,
      before: false,
    }
  }
}

export const buildArchiveQueryStanza = ({
  afterStamp,
  afterId,
  beforeId,
  toJid,
  queryId,
  before,
  flipPage,
  withJid,
  max,
}: MAMQueryOptions) => {
  const stanza = xml(
    "iq",
    { type: "set" },
    xml("query", { xmlns: "urn:xmpp:mam:2", queryid: queryId || xid() }),
  )

  const finalMax = max || "100"
  const setElement = xml(
    "set",
    "http://jabber.org/protocol/rsm",
    xml("max", {}, finalMax),
  )
  if (afterId) {
    setElement.append(xml("after", {}, afterId))
  }

  if (beforeId) {
    setElement.append(xml("before", {}, beforeId))
  }

  if (before) {
    setElement.append(xml("before"))
  }

  if (setElement.children.length > 0) {
    stanza.getChild("query")!.append(setElement)
  }

  const formFields: Element[] = []

  if (afterStamp) {
    formFields.push(
      xml("field", { var: "start" }, xml("value", {}, afterStamp)),
    )
  }

  if (withJid) {
    formFields.push(xml("field", { var: "with" }, xml("value", {}, withJid)))
  }

  if (formFields.length > 0) {
    formFields.unshift(
      xml(
        "field",
        { var: "FORM_TYPE", type: "hidden" },
        xml("value", {}, "urn:xmpp:mam:2"),
      ),
    )
    stanza
      .getChild("query")!
      .append(
        xml("x", { xmlns: "jabber:x:data", type: "submit" }, ...formFields),
      )
  }

  if (toJid) {
    stanza.attrs.to = toJid
  }

  if (flipPage) {
    stanza.getChild("query")!.append(xml("flip-page"))
  }

  return stanza
}
