import React, { RefObject } from "react"

export type MessageBubbleToolbarActions = {
  openEmojiPicker: () => void
  closeEmojiPicker: () => void
}

export type MessageBubbleToolbarContext = {
  reactionPicker: {
    toolbarRef: RefObject<MessageBubbleToolbarActions>
    containerRef: RefObject<HTMLDivElement>
  }
}

export const MessageBubbleToolbarContext = React.createContext(
  {} as MessageBubbleToolbarContext,
)

export function useMessageBubbleToolbarContext() {
  return React.useContext(MessageBubbleToolbarContext)
}
