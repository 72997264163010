import { Marker } from "../reducers/chatsSlice"

class MarkerHelpers {
  static isDuplicate(left: Marker, right: Marker) {
    return (
      left.markedMessageId === right.markedMessageId &&
      left.from === right.from &&
      left.to === right.to &&
      left.name === right.name &&
      left.type === right.type
    )
  }

  static isEqual(left: Marker, right: Marker) {
    if (left.type !== right.type) return false

    if (left.id && right.id) {
      return left.id === right.id
    }

    if (left.stanzaId && right.stanzaId) {
      return left.stanzaId === right.stanzaId
    }

    return false
  }
}

export default MarkerHelpers
