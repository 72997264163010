import { RootState } from "./store"
import { Location } from "./locationsSlice"

export const selectLocation = (jid: string, itemId: string) => {
  return (state: RootState): Location | undefined => {
    const driverLocation = state.locations.items.find(
      (c) => c.jid === jid && c.itemId === itemId,
    )
    return driverLocation ? driverLocation.value : undefined
  }
}
