import React, { useCallback, useContext, useEffect, useState } from "react"
import { captureError } from "../ErrorHandlers"
import { Button, H1, Overlay } from "@blueprintjs/core"

import cssUtils from "./utilsCss.module.css"
import css from "./NotificationsPermissionsRequest.module.css"
import classNames from "classnames"
import AdHocCommands from "../api/adHocCommands"
import { XmppContext } from "../stream/xmppClient"
import SettingsApi from "../api/settingsApi"
import { updateSettings } from "../reducers/settingsSlice"
import { useAppDispatch } from "../reducers/hooks"

const MobilePushNotificationsPermissionsRequest = () => {
  const [overlayOpen, setOverlayOpen] = useState(false)
  const { client } = useContext(XmppContext)
  const dispatch = useAppDispatch()

  const createAndSendSubscription = useCallback(async () => {
    const serviceWorker = await navigator.serviceWorker.ready
    let subscription = await serviceWorker.pushManager.getSubscription()
    if (!subscription) {
      try {
        subscription = await serviceWorker.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: process.env.REACT_APP_PUSH_SERVER_PUBLIC_KEY,
        })
      } catch (error) {
        captureError(error, {
          origin: "PushNotifications",
          extra: { message: "subscribe" },
        })
      }
    }

    if (subscription) {
      AdHocCommands.sendPushNotificationsSubscription(client, subscription)
        .then(() => {
          SettingsApi.setMobileNotificationsEnabled(client, true)
          dispatch(
            updateSettings({ settings: { pushSubscriptionPresent: true } }),
          )
        })
        .catch((error) => {
          captureError(error, {
            origin: "PushNotifications",
            extra: { message: "setMobileNotificationsEnabled" },
          })
        })
    }
  }, [client, dispatch])

  useEffect(() => {
    if (!window.Notification) return

    if (window.Notification.permission === "denied") return

    if (window.Notification.permission === "default") {
      setOverlayOpen(true)
      return
    }
  }, [])

  const closeOverlay = useCallback(() => {
    return setOverlayOpen(false)
  }, [])

  const onAllowClick = useCallback(() => {
    closeOverlay()
    window.Notification.requestPermission().then((permissions) => {
      if (permissions !== "granted") return

      createAndSendSubscription()
    })
  }, [closeOverlay, createAndSendSubscription])

  return (
    <Overlay
      isOpen={overlayOpen}
      enforceFocus={true}
      onClose={closeOverlay}
      canOutsideClickClose={false}
    >
      <div className={classNames(css.container, cssUtils.center)}>
        <div className={css.info}>
          <H1>Allow push notifications</H1>
          <p>
            To get notifications for new messages, click Allow Notifications
            below
          </p>
          <Button onClick={onAllowClick} large intent="primary">
            Allow push notifications
          </Button>
        </div>
      </div>
    </Overlay>
  )
}

export default MobilePushNotificationsPermissionsRequest
