import { Client, xml } from "@xmpp/client"
import { Element } from "@xmpp/xml"
import LocationParser from "../lib/locationParser"
import { Location } from "../reducers/locationsSlice"
import { Route } from "../reducers/routesSlice"
import { Activity } from "../reducers/activitySlice"
import RouteParser from "../lib/routeParser"
import ActivityParser from "../lib/activityParser"
import iqMeasure from "../instrumentation/iqMeasure"

type Direction = {
  location?: Location
  nextDestination?: Location
}

const pubsubGetIq = (
  client: Client,
  pubJid: string,
  node: string,
  ids: string[],
) => {
  const items = xml("items", {
    node: node,
  })
  ids.forEach((id) => {
    items.append(xml("item", { id }))
  })

  return xml(
    "iq",
    { from: client.jid, type: "get", to: pubJid },
    xml(
      "pubsub",
      {
        xmlns: "http://jabber.org/protocol/pubsub",
      },
      items,
    ),
  )
}

const XmppPubSubApi = {
  discover: (client: Client, pubJid: string) => {
    const { iqCaller } = client
    const measuredIqCaller = iqMeasure(iqCaller, "pubsub_disco_items")

    const discoStanza = xml(
      "iq",
      { from: client.jid, type: "get", to: pubJid },
      xml("query", "http://jabber.org/protocol/disco#items"),
    )
    measuredIqCaller.request(discoStanza)
  },

  discoverItems: (client: Client, pubJid: string) => {
    const { iqCaller } = client
    const measuredIqCaller = iqMeasure(iqCaller, "pubsub_disco_items_geoloc")

    const discoStanza = xml(
      "iq",
      { from: client.jid, type: "get", to: pubJid },
      xml("query", {
        xmlns: "http://jabber.org/protocol/disco#items",
        node: "http://jabber.org/protocol/geoloc",
      }),
    )
    measuredIqCaller.request(discoStanza)
  },

  fetchDirection: (
    client: Client,
    pubJid: string,
  ): Promise<Direction | undefined> => {
    const { iqCaller } = client
    const measuredIqCaller = iqMeasure(iqCaller, "pubsub_get_items")

    return new Promise((resolve, reject) => {
      const stanza = pubsubGetIq(
        client,
        pubJid,
        "http://jabber.org/protocol/geoloc",
        ["current", "next_destination"],
      )

      measuredIqCaller
        .request(stanza)
        .then((result) => {
          resolve({
            location: LocationParser.parse(result, "current"),
            nextDestination: LocationParser.parse(result, "next_destination"),
          })
        })
        .catch((error) => reject(error))
    })
  },

  fetchRoute: (client: Client, pubJid: string): Promise<Route | undefined> => {
    const { iqCaller } = client
    const measuredIqCaller = iqMeasure(iqCaller, "pubsub_get_items")
    return new Promise((resolve, reject) => {
      const stanza = pubsubGetIq(client, pubJid, "route", ["current"])

      measuredIqCaller
        .request(stanza)
        .then((result) => {
          resolve(RouteParser.parse(result))
        })
        .catch((error) => reject(error))
    })
  },

  fetchActivity: (
    client: Client,
    pubJid: string,
  ): Promise<Activity | undefined> => {
    const { iqCaller } = client
    const measuredIqCaller = iqMeasure(iqCaller, "pubsub_get_items")
    return new Promise((resolve, reject) => {
      const stanza = pubsubGetIq(
        client,
        pubJid,
        "http://jabber.org/protocol/activity",
        ["current"],
      )

      measuredIqCaller
        .request(stanza)
        .then((result) => {
          resolve(ActivityParser.parse(result))
        })
        .catch((error) => reject(error))
    })
  },

  subscribe: (
    client: Client,
    pubJid: string,
    node: string,
  ): Promise<Element> => {
    const { iqCaller } = client
    const measuredIqCaller = iqMeasure(iqCaller, "pubsub_subscribe")

    const subscribeStanza = xml(
      "iq",
      { from: client.jid, type: "set", to: pubJid },
      xml(
        "pubsub",
        "http://jabber.org/protocol/pubsub",
        xml("subscribe", {
          node,
          jid: client.jid?.bare(),
        }),
      ),
    )
    return measuredIqCaller.request(subscribeStanza)
  },
}

export default XmppPubSubApi
