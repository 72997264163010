import { Element } from "@xmpp/xml"
import { Location } from "../reducers/locationsSlice"

class LocationParser {
  static parse(stanza: Element, nodeId: string): Location | undefined {
    const geoLoc = LocationParser.geolocChild(stanza, nodeId)

    if (!geoLoc) return undefined

    const lat = geoLoc.getChildText("lat")
    const lon = geoLoc.getChildText("lon")

    if (!lat || !lon) return undefined

    return {
      lat,
      lon,
      bearing: geoLoc.getChildText("bearing") || "0",
      country: geoLoc.getChildText("country") || undefined,
      countryCode: geoLoc.getChildText("countrycode") || undefined,
      locality: geoLoc.getChildText("locality") || undefined,
    }
  }

  static geolocChild(stanza: Element, nodeId: string): Element | undefined {
    return this.nodeIdChild(stanza, nodeId)?.getChild(
      "geoloc",
      "http://jabber.org/protocol/geoloc",
    )
  }

  static retractChild(stanza: Element, nodeId: string): Element | undefined {
    const node = this.nodeIdChild(stanza, nodeId)

    return node?.name === "retract" ? node : undefined
  }

  static nodeIdChild(stanza: Element, nodeId: string): Element | undefined {
    const pubSubOrEventNode =
      stanza.getChild("pubsub", "http://jabber.org/protocol/pubsub") ||
      stanza.getChild("event", "http://jabber.org/protocol/pubsub#event")

    if (!pubSubOrEventNode) return undefined

    return pubSubOrEventNode
      .getChildByAttr("node", "http://jabber.org/protocol/geoloc")
      ?.getChildByAttr("id", nodeId)
  }
}

export default LocationParser
