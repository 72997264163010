import classNames from "classnames"
import React, { memo } from "react"
import { isMobile } from "react-device-detect"
import { FileToolbar } from "./FileToolbar"
import { Message } from "../../reducers/chatsSlice"

export type FileStubProp = {
  url: string
  name?: string
  downloadFileName?: string | null
  handleDownload?: (url: string, name: string) => void
  handleShareLink?: (filename: string) => void
  inGroup?: boolean
  message?: Message
  nonClickable?: boolean
}

const getFileName = (url: string, name?: string) => {
  if (name) return name

  const nameStart = url.lastIndexOf("/") + 1
  return url.slice(nameStart, url.length)
}

const FileStub = ({
  url,
  name,
  downloadFileName,
  handleDownload,
  handleShareLink,
  inGroup,
  message,
  nonClickable,
}: FileStubProp) => {
  const FileComponent = () => (
    <>
      {downloadFileName?.endsWith("pdf") && (
        <img src="pdf.svg" className="w-10 h-10" />
      )}
      <span
        className={classNames(
          "break-words text-clip line-clamp-2",
          inGroup && "max-w-20",
        )}
      >
        {getFileName(url, name)}
      </span>
    </>
  )

  return (
    <div className="group min-h-40 min-w-40 flex items-center relative">
      <div className="flex flex-col justify-center items-center gap-y-2 text-center text-xs h-full w-full">
        {handleDownload && handleShareLink && downloadFileName && message && (
          <div>
            <FileToolbar
              url={url}
              downloadFileName={downloadFileName!}
              handleDownload={handleDownload}
              handleShareLink={handleShareLink}
              message={message}
              isMobile={isMobile}
            />
          </div>
        )}
        <div className="flex flex-col justify-center items-center gap-y-2 text-center text-xs h-full w-full">
          {nonClickable ? (
            <FileComponent />
          ) : (
            <a href={url} target="_blank" rel="noreferrer">
              <FileComponent />
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

export default memo(FileStub)
