import { Icon } from "@blueprintjs/core"
import React, { memo } from "react"

const ErrorWithRetry = ({
  errorMessage,
  onRetry,
}: {
  errorMessage: string
  onRetry: () => void
}) => {
  return (
    <div className="flex flex-row gap-x-2 h-[40px]">
      <Icon icon="error" size={20} color="red" />
      <p>{errorMessage}</p>
      <a onClick={onRetry} className="text-primary">
        Retry
      </a>
    </div>
  )
}

export default memo(ErrorWithRetry)
