import React, { useEffect } from "react"
import { useRouteError } from "react-router-dom"
import { isMobile } from "react-device-detect"
import * as Sentry from "@sentry/react"

export const RouterErrorHandler = () => {
  const routeError = useRouteError()

  useEffect(() => {
    window.analytics.track("AppCrash", { error: routeError })
    captureError(routeError, { origin: "ErrorBoundary" })
  }, [routeError])

  const onClickReload = () => {
    window.location.href = "/"
  }

  const submessage = `We’ve been notified about the issue and are working to resolve it. In the meantime, please try ${isMobile ? "restarting the application" : "refreshing the page"}.`

  return (
    <>
      <main className="grid place-items-center p-4 bg-slate-900">
        <img src="slickshift2.png" className="w-52" />
      </main>
      <main className="grid min-h-full place-items-center px-6 py-24 sm:py-4">
        <div className="text-center">
          <h1 className="mt-4 text-xl font-bold tracking-tight text-gray-900 sm:text-xl">
            Something went wrong
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">{submessage}</p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            {!isMobile && (
              <a
                onClick={onClickReload}
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Refresh
              </a>
            )}
          </div>
        </div>
      </main>
    </>
  )
}

type CaptureOptions = {
  origin:
    | "XMPPErrorCallback"
    | "XMPPErrorStanza"
    | "XMPPOnOnline"
    | "ErrorBoundary"
    | "UserAction"
    | "InitialLoad"
    | "PushNotifications"
    | "Other"
  extra?: Record<string, string | number>
}

export const captureError = (error: any, options: CaptureOptions) => {
  console.error(error, options)
  Sentry.captureException(error, {
    tags: { origin: options.origin },
    extra: options.extra,
  })
}
