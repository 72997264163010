import { jid } from "@xmpp/client"
import { JID } from "@xmpp/jid"

const cache: { [key: string]: JID } = {}

const parse = (rawJid: string) => {
  if (cache[rawJid]) return cache[rawJid]

  cache[rawJid] = jid.parse(rawJid)
  return cache[rawJid]
}

export { parse }
