import { Element } from "@xmpp/xml"
import { Settings } from "../reducers/settingsSlice"
import { type PushToken } from "../api/adHocCommands"

class SettingsParser {
  static parseSettings(stanza: Element): Partial<Settings> | undefined {
    const eventOrPubsubNode =
      stanza.getChild("event", "http://jabber.org/protocol/pubsub#event") ||
      stanza.getChild("pubsub", "http://jabber.org/protocol/pubsub")

    if (!eventOrPubsubNode) return undefined

    const storageSettingsNode = eventOrPubsubNode.getChildByAttr(
      "node",
      "storage:settings",
    )

    if (!storageSettingsNode) return undefined

    const settings = {
      mobileNotificationsEnabled: storageSettingsNode
        .getChildByAttr("id", "mobile_notifications_enabled")
        ?.getChildText("value"),
      showNicknameInWhatsapp: storageSettingsNode
        .getChildByAttr("id", "show_nickname_in_whatsapp")
        ?.getChildText("value"),
      showTranslations: storageSettingsNode
        .getChildByAttr("id", "show_translations")
        ?.getChildText("value"),
    }

    return Object.fromEntries(
      Object.entries(settings)
        .filter(([, value]) => value !== undefined)
        .map(([key, value]) => [key, value === "true"]),
    )
  }

  static parsePushSubscription(commandResult: Element): PushToken | undefined {
    const subscription = commandResult
      .getChildByAttr("node", "get_subscription")
      ?.getChildText("json")

    if (!subscription) return undefined
    try {
      return JSON.parse(subscription)
    } catch {
      return undefined
    }
  }
}

export default SettingsParser
