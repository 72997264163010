import React, { useContext, useEffect, useMemo, useRef, useState } from "react"

import css from "./LocationMap.module.css"
import { Location } from "../reducers/locationsSlice"
import { Loader } from "@googlemaps/js-api-loader"
import { InputGroup, Tag } from "@blueprintjs/core"
import { DriverContext } from "../contexts/DriverContext"
import { Resizable, ResizeCallbackData } from "react-resizable"
import cssUtils from "./utilsCss.module.css"
import classNames from "classnames"

type LocationMapProps = {
  location: Location
  destination?: Location
}

const loader = new Loader({
  apiKey: process.env.REACT_APP_GOGLE_MAP_KEY || "",
  libraries: ["places"],
})

const defaultMapSize = () => {
  // 30vh, max 600px
  let width = (document.documentElement.clientWidth * 30) / 100
  width = width > 600 ? 600 : width
  // 40vh, max 500px
  let height = (document.documentElement.clientHeight * 40) / 100
  height = height > 500 ? 500 : height

  return { width, height }
}

const savedMapSize = () => {
  const width = parseInt(localStorage.getItem("mapWidth") || "0")
  const height = parseInt(localStorage.getItem("mapHeight") || "0")

  if (width === 0 || height === 0) return null

  return { width, height }
}

const LocationMap = ({ location, destination }: LocationMapProps) => {
  const [size, setSize] = useState<{ width: number; height: number }>(
    savedMapSize() || defaultMapSize(),
  )
  const [resizing, setResizing] = useState<boolean>(false)
  const { setDriverNextDestination, cancelDestination, driver } =
    useContext(DriverContext)
  const destinationToString = (dest: Location) => {
    const coordinates = `${dest.lat}, ${dest.lon}`
    if (dest.locality && dest.country)
      return `${dest.locality}, ${dest.country} (${coordinates})`

    return coordinates
  }

  const [places, setPlaces] = useState<google.maps.PlacesLibrary>()
  const [address, setAddress] = useState<string>(
    destination ? destinationToString(destination) : "",
  )
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setAddress(destination ? destinationToString(destination) : "")
  }, [destination])

  useEffect(() => {
    loader.importLibrary("places").then((places) => {
      setPlaces(places)
    })
  }, [])

  useEffect(() => {
    if (!places || !inputRef.current) return

    const autocomplete = new places.Autocomplete(inputRef.current)

    const listener = autocomplete.addListener("place_changed", () => {
      if (!driver) return
      const place = autocomplete.getPlace()

      if (place.formatted_address) {
        setDriverNextDestination(place.formatted_address, driver)
        return
      }

      // user types / copy-pasted value and pressed enter
      if (place.name && place.name.length > 0) {
        setDriverNextDestination(place.name, driver)
        return
      }

      // user removed all text and pressed enter
      if (place.name && place.name.length === 0) {
        cancelDestination(driver)
        return
      }
    })

    return () => listener.remove()
  }, [places, driver, setDriverNextDestination, cancelDestination])

  const handleAddressChange = (value: string) => {
    setAddress(value)
  }

  const mapUrl = useMemo(() => {
    if (!destination) {
      return `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOGLE_MAP_KEY}&q=${location.lat}%2C${location.lon}&zoom=10`
    } else {
      return `https://www.google.com/maps/embed/v1/directions?key=${process.env.REACT_APP_GOGLE_MAP_KEY}&origin=${location.lat}%2C${location.lon}&destination=${destination.lat}%2C${destination.lon}&mode=driving&units=metric`
    }
  }, [location, destination])

  const onCleanValue = () => {
    setAddress("")
    if (driver) cancelDestination(driver)
  }

  const onResize = (e: React.SyntheticEvent, data: ResizeCallbackData) => {
    setSize(data.size)
  }

  const onResizeStop = () => {
    setResizing(false)
    localStorage.setItem("mapWidth", size.width.toString())
    localStorage.setItem("mapHeight", size.height.toString())
  }

  return (
    <Resizable
      minConstraints={[200, 200]}
      maxConstraints={[800, 800]}
      height={size.height}
      width={size.width}
      onResize={onResize}
      resizeHandles={["sw"]}
      onResizeStart={() => setResizing(true)}
      onResizeStop={onResizeStop}
      handleSize={[15, 15]}
    >
      <div
        className={css.map}
        style={{ width: size.width + "px", height: size.height + "px" }}
      >
        <InputGroup
          value={address}
          onValueChange={handleAddressChange}
          large
          inputRef={inputRef}
          round
          className={css.locationInput}
          leftIcon="route"
          rightElement={
            address.length > 0 ? (
              <Tag interactive minimal onClick={onCleanValue} icon="cross" />
            ) : undefined
          }
        />
        <iframe
          width="100%"
          height="100%"
          referrerPolicy="no-referrer-when-downgrade"
          src={mapUrl}
          allowFullScreen
        ></iframe>
        <div
          className={classNames(css.overlay, { [cssUtils.visible]: resizing })}
        ></div>
      </div>
    </Resizable>
  )
}

export default LocationMap
