import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { PURGE } from "redux-persist"
import { RootState } from "./store"

export type Activity = {
  jid: string
  general: string
  specific: string | undefined
}

export type ActivityState = Activity[]

const initialState: ActivityState = []

type UpsertActivityAction = {
  activity: Activity
}

export const activitySlice = createSlice({
  name: "activities",
  initialState,
  reducers: {
    upsertActivity: (state, action: PayloadAction<UpsertActivityAction>) => {
      const index = state.findIndex(
        (p) => p.jid === action.payload.activity.jid,
      )

      if (index === -1) {
        state.push(action.payload.activity)
      } else {
        state[index] = action.payload.activity
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState)
  },
})

export const { upsertActivity } = activitySlice.actions

export const findActivity = (jid: string | undefined) => {
  return (state: RootState): Activity | undefined => {
    if (!jid) return undefined

    return state.activities.find((activity) => activity.jid === jid)
  }
}

export default activitySlice.reducer
