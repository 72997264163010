import React from "react"
import { ColorRing } from "react-loader-spinner"

type LoaderProps = {
  className?: string
  small?: boolean
}
const Loader = ({ className, small }: LoaderProps) => (
  <div className={className}>
    <ColorRing
      visible={true}
      height={small ? "40" : "80"}
      width={small ? "40" : "80"}
      ariaLabel="blocks-loading"
      wrapperStyle={{}}
      wrapperClass="blocks-wrapper"
      colors={["#5551FF", "#00AF68", "#97D700", "#FFCD00", "#FF5C0B"]}
    />
  </div>
)

export default Loader
