import { Client } from "@xmpp/client"
import { Element } from "@xmpp/xml"
import JID from "@xmpp/jid"
import XmppGroupChatApi from "../api/xmppGroupChatApi"

type Occupant = {
  jid: string | undefined
  role: string | undefined
  affiliation: string | undefined
}

type RoomData = {
  occupants: Record<string, Occupant>
}

const getX = (stanza: Element): Element | undefined => {
  return stanza.getChild("x", "http://jabber.org/protocol/muc#user")
}

const isMUCPresence = (stanza: Element): boolean => {
  if (stanza.name !== "presence") return false

  return !!getX(stanza)
}

const isJoinedRoomStanza = (stanza: Element, jid: string): boolean => {
  if (!isMUCPresence(stanza)) return false

  const x = getX(stanza)
  if (!x) return false

  const selfPresence = x
    .getChildren("status")
    .some((status) => status.attrs.code === "110")
  if (!selfPresence) return false

  if (stanza.attrs.type === "unavailable") return false

  const from = JID(stanza.attrs.from)

  return from.bare().toString() === jid
}

const syncPresence = (rooms: Record<string, RoomData>, stanza: Element) => {
  if (!isMUCPresence(stanza)) return

  const jid = JID(stanza.attrs.from)
  if (!rooms[jid.bare().toString()]) {
    return
  }

  const room = rooms[jid.bare().toString()]
  if (stanza.attrs.type === "unavailable") {
    delete room.occupants[jid.resource]
  } else {
    const x = getX(stanza)
    const item = x?.getChild("item")
    room.occupants[jid.resource] = {
      jid: item?.attrs.jid,
      role: item?.attrs.role,
      affiliation: item?.attrs.affiliation,
    }
  }
}

const syncConfigChange = (
  stanza: Element,
  handleRoomConfigurationChange: (roomJid: string) => void,
) => {
  const ROOM_CONFIGURATION_CHANGED_CODE = "104"
  if (stanza.name !== "message") return

  const xElement = getX(stanza)
  const statusCode = xElement?.getChild("status")?.attrs.code

  if (statusCode !== ROOM_CONFIGURATION_CHANGED_CODE) return

  handleRoomConfigurationChange(stanza.attrs.from)
}

const MUC = (
  xmpp: Client,
  handleRoomConfigurationChange: (roomJid: string) => void,
) => {
  const rooms: Record<string, RoomData> = {}

  xmpp.on("stanza", (stanza: Element) => {
    syncPresence(rooms, stanza)
    syncConfigChange(stanza, handleRoomConfigurationChange)
  })

  return {
    joinRoom: async (
      jid: string,
      nick: string,
      historySince: Date,
      timeout: number,
    ) => {
      const didJoin = new Promise((resolve, reject) => {
        const joinListener = (stanza: Element) => {
          if (isJoinedRoomStanza(stanza, jid)) {
            resolve(true)
          }
        }

        xmpp.on("stanza", joinListener)
        setTimeout(() => {
          xmpp.off("stanza", joinListener)
          reject(`Did not receive presence for ${jid}/${nick}`)
        }, timeout)
      })
      rooms[jid] = {
        occupants: {},
      }
      await XmppGroupChatApi.sendPresence(xmpp, jid, nick, historySince)
      await didJoin
    },
    rooms,
  }
}

export type MUC = ReturnType<typeof MUC>

export default MUC
