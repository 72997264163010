import { captureError } from "../ErrorHandlers"
import {
  GenericMessage,
  Message,
  MessageDisplayType,
  MessageGroup,
} from "../reducers/chatsSlice"
import { JID } from "@xmpp/jid"
import { parse as jidParse } from "./cachedJid"

export type MessageStatus =
  | "scheduled"
  | "not_sent"
  | "sent"
  | "received"
  | "displayed"

const DATE_FORMATTER = new Intl.DateTimeFormat("en-GB")

class MessageHelpers {
  static applyCorrection(original: Message, correction: Message): Message {
    return {
      ...original,
      body: correction.body,
      file: correction.file,
      url: correction.url,
      fileName: correction.fileName,
      references: correction.references,
      translation: correction.translation,
    }
  }

  static isSendingOrError(message: GenericMessage): boolean {
    return (
      "sendStatus" in message &&
      (message.sendStatus === "sending" || message.sendStatus === "error")
    )
  }

  static hasComeFromJid(
    messageOrMessageGroup: GenericMessage | MessageGroup,
    compareJid?: string,
  ): boolean {
    if (messageOrMessageGroup.displayType === MessageDisplayType.MessageGroup) {
      return false
    }

    const message = messageOrMessageGroup as GenericMessage

    if (!compareJid) return false
    const theJid = jidParse(compareJid)
    return (
      MessageHelpers.fromBareJid(message, theJid.domain) ===
      theJid.bare().toString()
    )
  }

  static fromBareJid(message: GenericMessage, domain: string): string {
    const fromJid = jidParse(message.from)
    if (this.isSendingOrError(message)) {
      return fromJid.bare().toString()
    }

    if (message.type === "groupchat") {
      return `${fromJid.getResource()}@${domain}`
    }

    return fromJid.bare().toString()
  }

  static userName(message: GenericMessage): string {
    return message.type === "groupchat"
      ? jidParse(message.from)?.getResource()
      : jidParse(message.from)?.getLocal()
  }

  static isIncoming(message: GenericMessage, myJid: JID): boolean {
    const toJid = jidParse(message.to).bare()
    const fromJid = jidParse(message.from)
    const isToMyself = toJid?.toString() === myJid.bare().toString()

    if (this.isSendingOrError(message)) {
      return isToMyself
    }

    if (message.type === "groupchat") {
      return isToMyself && fromJid.getResource() !== myJid.getLocal()
    }

    return isToMyself
  }

  static isDisplayed(message: Message, forJid: string): boolean {
    return message.markers
      .filter((marker) => MessageHelpers.hasComeFromJid(marker, forJid))
      .some((marker) => marker.name === "displayed")
  }

  static isReceived(message: Message, forJid: string): boolean {
    return message.markers
      .filter((marker) => MessageHelpers.hasComeFromJid(marker, forJid))
      .some((marker) => marker.name === "received")
  }

  static isToMyself(message: GenericMessage): boolean {
    return (
      message.type === "chat" &&
      jidParse(message.from).bare().toString() ===
        jidParse(message.to).bare().toString()
    )
  }

  static timestamp(message: Message, withDate = true): string {
    const date = new Date(message.timestamp)

    const time = date.toTimeString().slice(0, 5)

    if (withDate) {
      return `${time} ${DATE_FORMATTER.format(date)}`
    }

    return time
  }

  static shouldGroup(
    messageOrMessageGroup: Message | MessageGroup,
    prevMessageOrMessageGroup?: Message | MessageGroup,
  ): boolean {
    if (!prevMessageOrMessageGroup) return false

    if (
      messageOrMessageGroup.displayType === MessageDisplayType.MessageGroup ||
      prevMessageOrMessageGroup.displayType === MessageDisplayType.MessageGroup
    ) {
      return false
    }

    const message = messageOrMessageGroup as Message
    const prevMessage = prevMessageOrMessageGroup as Message

    const FIVE_MINUTES = 5 * 60

    if (message.from !== prevMessage.from) return false

    const secondsDiff =
      (Date.parse(message.timestamp) - Date.parse(prevMessage.timestamp)) / 1000
    if (secondsDiff >= FIVE_MINUTES) return false

    return true
  }

  static applyCorrections<T extends Message | MessageGroup>(
    messageOrMessageGroup: T,
    messageCorrections: Message[],
  ): T {
    const applyMessageCorrection = (
      message: Message,
      messageCorrections: Message[],
    ) => {
      const correction = messageCorrections
        ? messageCorrections.find((corr) => corr.replaceId === message.id)
        : undefined
      if (correction) {
        return MessageHelpers.applyCorrection(message, correction)
      }

      return message
    }

    if (messageOrMessageGroup.displayType === MessageDisplayType.MessageGroup) {
      const messageGroup = messageOrMessageGroup as MessageGroup
      messageGroup.messages = messageGroup.messages.map((message) =>
        applyMessageCorrection(message, messageCorrections),
      )
      return messageGroup as T
    }

    return applyMessageCorrection(
      messageOrMessageGroup as Message,
      messageCorrections,
    ) as T
  }

  static markRetracted = (message: Message) => {
    return {
      ...message,
      retracted: true,
    }
  }

  static getBodyWithAppliedAddressReferences(
    message: Message,
    wrapAddressString: (address: string) => string,
  ): string {
    if (!message.references || message.references?.length === 0)
      return message.body

    try {
      const chunks = []
      let copyFromIndex = 0
      const references = message.references
        .slice()
        .sort((a, b) => a.begin - b.begin)
      references.forEach((reference) => {
        const begin = reference.begin
        const end = reference.end

        chunks.push(message.body.slice(copyFromIndex, begin))
        const dist = message.body.slice(begin, end)
        chunks.push(wrapAddressString(dist))

        copyFromIndex = end
      })

      // add the rest of the message
      if (copyFromIndex < message.body.length - 1) {
        chunks.push(message.body.slice(copyFromIndex, message.body.length))
      }

      return chunks.join("")
    } catch (error) {
      captureError(error, {
        origin: "Other",
        extra: { message: "getBodyWithAppliedAddressReferences" },
      })
      return message.body
    }
  }

  static getReactionsAsEmojisWithSender(message: Message) {
    return message.reactions.flatMap((r) =>
      r.emojis.map((emoji) => ({ from: r.from, emoji })),
    )
  }
}

export default MessageHelpers
