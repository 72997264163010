import React, { useState, useLayoutEffect } from "react"

import Loader from "../components/Loader"
import { setupAnalytics } from "./SignIn"
import { resetCredentialsAndConnect } from "../stream/xmppClient"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useAppStore } from "../reducers/hooks"
import { Callout } from "@blueprintjs/core"
import AdHocCommands from "../api/adHocCommands"
import { retry } from "../utils"
import { type PushToken } from "../api/adHocCommands"

import utilsCss from "../components/utilsCss.module.css"
import classNames from "classnames"

const notifyNativePart = (isConnected: boolean) => {
  if (!window.ReactNativeWebView) {
    return
  }

  window.ReactNativeWebView.postMessage(
    JSON.stringify({ type: "NativeSignIn", data: { isConnected } }),
  )
}

const extractPushToken = (param: string | null): PushToken | null => {
  console.log("Extracting push token form param", param)
  if (!param) return null

  try {
    return JSON.parse(decodeURIComponent(param))
  } catch (error) {
    console.error("Error extracting push token", error)
    return null
  }
}

const savePushToken = async (value: PushToken) => {
  console.log("Attempt to save push token", value)
  await AdHocCommands.sendPushNotificationsSubscription(window.XMPP!, value)
  console.log("Successfully sent push token", value)
}

const backgroundPushTokenSave = async (value: PushToken | null) => {
  if (!value) {
    window.analytics.track("NativePushToken", { success: false, missing: true })
    return
  }
  try {
    await retry(savePushToken, [value], { maxAttempts: 5, backoff: true })
    window.analytics.track("NativePushToken", { success: true })
  } catch (error) {
    window.analytics.track("NativePushToken", { success: false, error: error })
  }
}

const SignInNative = () => {
  const [params] = useSearchParams()
  const [isFailed, setIsFailed] = useState(false)
  const navigate = useNavigate()
  const store = useAppStore()

  useLayoutEffect(() => {
    void (async () => {
      const email = params.get("email")
      const password = params.get("password")
      const pushToken = extractPushToken(params.get("pushtoken"))
      console.log("Got pushToken", pushToken)
      if (email && password) {
        const userAuth = { email, password }
        const result = await resetCredentialsAndConnect(userAuth, store)

        if (result.connected) {
          window.myJID = result.jid
          setupAnalytics(userAuth)
          navigate("/", { replace: true })
          setTimeout(() => backgroundPushTokenSave(pushToken), 0)
        } else {
          setIsFailed(true)
        }
        notifyNativePart(result.connected)
      } else {
        notifyNativePart(false)
        setIsFailed(true)
      }
    })()
  }, [navigate, params, store])

  return (
    <div className={classNames(utilsCss.center)}>
      {isFailed ? (
        <Callout intent="danger">Invalid username or password</Callout>
      ) : (
        <Loader />
      )}
    </div>
  )
}

export default SignInNative
