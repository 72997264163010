import { Element } from "@xmpp/xml"
import { Route } from "../reducers/routesSlice"

class RouteParser {
  static parse(stanza: Element): Route | undefined {
    const routeDetails = this.routeCurrentChild(stanza)?.getChild(
      "x",
      "jabber:x:data",
    )

    if (!routeDetails) return

    const duration = routeDetails
      .getChildByAttr("var", "duration")
      ?.getChildText("value")
    const distance = routeDetails
      .getChildByAttr("var", "distance")
      ?.getChildText("value")
    const distanceState = routeDetails
      .getChildByAttr("var", "distance_state")
      ?.getChildText("value")

    if (!duration || !distance || !distanceState) return

    return {
      distanceState,
      distance: parseInt(distance),
      duration: parseInt(duration),
      jid: stanza.attrs.from,
    }
  }

  static shouldRemoveRoute(stanza: Element): boolean {
    const node = this.routeCurrentChild(stanza)?.getChild("x", "jabber:x:data")

    if (!node) return false

    const exist = node.getChildByAttr("var", "exist")?.getChildText("value")

    if (exist === "0") return true

    return false
  }

  static routeCurrentChild(stanza: Element) {
    const pubSubEventNode =
      stanza.getChild("event", "http://jabber.org/protocol/pubsub#event") ||
      stanza.getChild("pubsub", "http://jabber.org/protocol/pubsub")

    return pubSubEventNode
      ?.getChildByAttr("node", "route")
      ?.getChildByAttr("id", "current")
  }
}

export default RouteParser
