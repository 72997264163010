import { Client, xml } from "@xmpp/client"
import { Element } from "@xmpp/xml"
import iqMeasure from "../../instrumentation/iqMeasure"

const PUBSUB_NS = "http://jabber.org/protocol/pubsub"

type FieldOptions = {
  var: string
  type?: "hidden"
  value: string
}

type PublishArgs = {
  xmpp: Client
  jid: string
  node: string
  id: string
  payload: Element
  options?: FieldOptions[]
  timeout?: number
}

type GetAllItemsArgs = {
  xmpp: Client
  jid: string
  node: string
  timeout?: number
}

type RetractArgs = {
  xmpp: Client
  jid: string
  node: string
  id: string
}

const PUBLISH_OPTIONS_VALUE =
  "http://jabber.org/protocol/pubsub#publish-options"

export const getAllItems = async ({
  xmpp,
  jid,
  node,
  timeout,
}: GetAllItemsArgs): Promise<Element> => {
  const { iqCaller } = xmpp
  const measuredIqCaller = iqMeasure(iqCaller, "pubsub_get_items")

  const xmlItems = xml("items", { node: node })
  const xmlPubsub = xml("pubsub", { xmlns: PUBSUB_NS }, xmlItems)
  const xmlIq = xml("iq", { type: "get", to: jid }, xmlPubsub)

  return await measuredIqCaller.request(xmlIq, timeout)
}

export const publish = async (args: PublishArgs): Promise<void> => {
  const { xmpp, jid, node, id, payload, options, timeout } = args

  const xmlItem = xml("item", { id: id }, payload)
  const xmlPublish = xml("publish", { node: node }, xmlItem)
  const xmlPubsub = xml("pubsub", { xmlns: PUBSUB_NS }, xmlPublish)
  if (options) {
    const publishOptions = buildPublishOptions(options)
    xmlPubsub.append(publishOptions)
  }
  const xmlIq = xml("iq", { type: "set", to: jid }, xmlPubsub)

  const { iqCaller } = xmpp
  const measuredIqCaller = iqMeasure(iqCaller, "pubsub_publish")

  await measuredIqCaller.request(xmlIq, timeout)
}

export const retract = async (args: RetractArgs): Promise<void> => {
  const { xmpp, jid, node, id } = args

  const xmlItem = xml("item", { id: id })
  const xmlRetract = xml("retract", { node: node, notify: "true" }, xmlItem)
  const xmlPubsub = xml("pubsub", { xmlns: PUBSUB_NS }, xmlRetract)
  const xmlIq = xml("iq", { type: "set", to: jid }, xmlPubsub)

  const { iqCaller } = xmpp
  const measuredIqCaller = iqMeasure(iqCaller, "pubsub_retract")

  await measuredIqCaller.request(xmlIq)
}

const buildField = (fieldOptions: FieldOptions): Element => {
  return xml(
    "field",
    { var: fieldOptions.var, type: fieldOptions.type },
    xml("value", {}, fieldOptions.value),
  )
}

const buildPublishOptions = (options: FieldOptions[] = []): Element => {
  const formTypeFieldOptions: FieldOptions = {
    var: "FORM_TYPE",
    type: "hidden",
    value: PUBLISH_OPTIONS_VALUE,
  }
  const allFieldOptions = [formTypeFieldOptions, ...options]
  const fields = allFieldOptions.map(buildField)

  return xml(
    "publish-options",
    {},
    xml("x", { xmlns: "jabber:x:data", type: "submit" }, ...fields),
  )
}
