let startedNow = window.performance.now()
let startedDate = new Date()
type AllowedValues = number | string | boolean
type DurationMetric = {
  name: string
  duration: number
  date: string
}

type ValueMetric = Record<string, AllowedValues>

const durationMetrics: DurationMetric[] = []
const valueMetrics: ValueMetric = {}

export const markLoadingMetric = (name: string, value: AllowedValues) => {
  valueMetrics[name] = value
}

export const resetStart = () => {
  startedNow = window.performance.now()
  startedDate = new Date()
}

export const markMetricDuration = (name: string, start: number) => {
  durationMetrics.push({
    name,
    duration: window.performance.now() - start,
    date: new Date().toISOString(),
  })
}

export const sendLoadingMetrics = () => {
  const finalPayload = {
    date: startedDate.toISOString(),
    duration: window.performance.now() - startedNow,
    events: durationMetrics,
    meta: valueMetrics,
  }
  window.analytics.track("LoadingMetrics", finalPayload)
  console.log("LoadingMetrics", finalPayload)
}
