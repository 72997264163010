import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { PURGE } from "redux-persist"
import { RootState } from "./store"

export type Route = {
  duration: number
  distance: number
  jid: string
  distanceState: string
}

export type RoutesState = Route[]

const initialState: RoutesState = []

type UpsertRouteAction = {
  route: Route
}

type RemoveRouteAction = {
  jid: string
}

export const routesSlice = createSlice({
  name: "routes",
  initialState,
  reducers: {
    upsertRoute: (state, action: PayloadAction<UpsertRouteAction>) => {
      const routeIndex = state.findIndex(
        (p) => p.jid === action.payload.route.jid,
      )

      if (routeIndex === -1) {
        state.push(action.payload.route)
      } else {
        state[routeIndex] = action.payload.route
      }
    },
    removeRoute: (state, action: PayloadAction<RemoveRouteAction>) => {
      return state.filter((r) => r.jid !== action.payload.jid)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState)
  },
})

export const { upsertRoute, removeRoute } = routesSlice.actions

export const findRoute = (jid: string | undefined) => {
  return (state: RootState): Route | undefined => {
    if (!jid) return undefined

    return state.routes.find((route) => route.jid === jid)
  }
}

export default routesSlice.reducer
