import React from "react"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { RouterErrorHandler } from "./ErrorHandlers"
import SignIn from "./routes/SignIn"
import SignInNative from "./routes/SignInNative"
import Root from "./routes/Root"
import { Provider } from "react-redux"
import { AppStore, setupStore } from "./reducers/store"
import { Persistor, persistStore } from "redux-persist"
import { PersistGate } from "redux-persist/integration/react"
import { loadChatsArchive } from "./routes/archiveLoader"
import { OverlayToaster } from "@blueprintjs/core"
import { Client } from "@xmpp/client"
import { JID } from "@xmpp/jid"
import * as Sentry from "@sentry/react"
import SendoutQueueWorker from "./SendoutQueueWorker"
import { type MUC } from "./xmpp_plugins/MUC"

import "./variables.css"

declare global {
  interface Window {
    pageLoadSpan?: Sentry.Span
    ReactNativeWebView?: { postMessage: (message: string) => void }
    injectedStore: string
    APP_VERSION: string
    APP_ENVIRONMENT: string
    appToaster: OverlayToaster
    analytics: SegmentAnalytics.AnalyticsJS
    persistor: Persistor
    XMPP: Client | undefined
    myJID: JID
    store: AppStore
    providedEmail: string | undefined
    sendoutQueueWorker: SendoutQueueWorker
    mucPlugin: MUC
  }
}

window.analytics.page("Slickshift App")

if ("performance" in window) {
  const shouldTrackEntry = (entry: PerformanceEntry): boolean => {
    if (
      ["longtask", "long-animation-frame", "event"].includes(entry.entryType)
    ) {
      return false
    }

    if (entry.entryType !== "resource") {
      return true
    }

    if (RegExp("^https?://.*\\.segment\\.io/", "i").test(entry.name)) {
      // segment.io calls trigger performance API entering endless loop
      return false
    }

    if (entry instanceof PerformanceResourceTiming) {
      if (!["img", "xmlhttprequest", "fetch"].includes(entry.initiatorType)) {
        return true
      }
    }

    return false
  }

  const performanceCallback = (list: PerformanceObserverEntryList) => {
    list.getEntries().forEach((entry) => {
      if (shouldTrackEntry(entry)) {
        const payload = entry.toJSON()
        if (
          entry instanceof PerformanceMark ||
          entry instanceof PerformanceMeasure
        ) {
          payload["detail"] = entry.detail
        }
        window.analytics.track("PerformanceApi", payload)
      }
    })
  }

  const observer = new PerformanceObserver(performanceCallback)
  const observedTypes = PerformanceObserver.supportedEntryTypes.filter(
    (e) => e !== "mark",
  )
  observer.observe({ entryTypes: observedTypes })
}

window.performance.mark("StoreSetupStart")
const store = setupStore()
window.persistor = persistStore(store)
window.store = store
window.performance.mark("StoreSetupEnd")

export const routes = [
  {
    path: "/",
    element: <Root />,
    loader: loadChatsArchive(store),
    errorElement: <RouterErrorHandler />,
  },
  {
    path: "/signin",
    element: <SignIn />,
    errorElement: <RouterErrorHandler />,
  },
  {
    path: "/signin_native",
    element: <SignInNative />,
    errorElement: <RouterErrorHandler />,
  },
]

const router = createBrowserRouter(routes)

function App() {
  return (
    <div className="body overflow-x-clip">
      <OverlayToaster
        ref={(ref: OverlayToaster) => (window.appToaster = ref)}
      />
      <Provider store={store}>
        <PersistGate loading={null} persistor={window.persistor}>
          <RouterProvider router={router} />
        </PersistGate>
      </Provider>
    </div>
  )
}

export default App
