import { Client, xml } from "@xmpp/client"
import { xmppServiceAddress } from "../stream/xmppClient"
import iqMeasure from "../instrumentation/iqMeasure"

const FileUploadApi = {
  requestSlot: (
    xmpp: Client,
    fileName: string,
    size: string,
    contentType: string,
  ): Promise<[string, string]> => {
    const { iqCaller } = xmpp
    const measuredIqCaller = iqMeasure(iqCaller, "upload_slot")

    return new Promise((resolve) => {
      const slotStanza = xml(
        "iq",
        { from: xmpp.jid, type: "get", to: xmppServiceAddress("upload", xmpp) },
        xml("request", {
          xmlns: "urn:xmpp:http:upload:0",
          filename: fileName,
          size: size,
          "content-type": contentType,
        }),
      )
      measuredIqCaller.request(slotStanza).then((stanza) => {
        const put = stanza.getChild("slot")?.getChild("put")
        const get = stanza.getChild("slot")?.getChild("get")

        return resolve([get?.attrs.url, put?.attrs.url])
      })
    })
  },
}

export default FileUploadApi
