import { useRef } from "react"
import { useSwipeable } from "react-swipeable"

export function useMessageSwipe(onSwiped: () => void) {
  const myRef = useRef<HTMLDivElement>()

  const handlers = useSwipeable({
    onSwipeStart: () => {
      if (myRef.current) {
        myRef.current.style.transition = "transform 0.2s"
      }
    },
    onSwiping: ({ deltaX, deltaY }) => {
      if (myRef.current && Math.abs(Math.atan2(deltaY, deltaX)) < Math.PI / 8) {
        myRef.current.style.transform = `translateX(${Math.max(0, Math.min(deltaX, 32))}px)`
      }
    },
    onSwiped: ({ deltaX, deltaY }) => {
      if (myRef.current) {
        myRef.current.style.transform = "translateX(0px)"
      }
      if (deltaX >= 32 && Math.abs(Math.atan2(deltaY, deltaX)) < Math.PI / 8) {
        onSwiped()
      }
    },
  })

  const refPassthrough = (el: any) => {
    handlers.ref(el)
    myRef.current = el
  }

  return { ref: refPassthrough, handlers }
}
