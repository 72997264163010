import { Client, xml } from "@xmpp/client"
import type { Participant } from "../reducers/chatsSlice"
import VCardParser from "../lib/VCardParser"
import iqMeasure from "../instrumentation/iqMeasure"

const XmppVCardApi = {
  fetchVCard: (
    client: Client,
    jid: string,
  ): Promise<Participant | undefined> => {
    const { iqCaller } = client
    const measuredIqCaller = iqMeasure(iqCaller, "vcard_get")

    return new Promise((resolve, reject) => {
      measuredIqCaller
        .request(
          xml(
            "iq",
            {
              from: client.jid,
              type: "get",
              to: jid,
            },
            xml("vCard", "vcard-temp"),
          ),
        )
        .then((result) => {
          resolve(VCardParser.parse(result))
        })
        .catch((error) => reject(error))
    })
  },
}

export default XmppVCardApi
