import { Element } from "@xmpp/xml"
import { MessageDisplayType, MessageFileShared } from "../reducers/chatsSlice"
import MessageParser from "./messageParser"

export default class MessageFileSharedParser {
  static parse(
    stanza: Element,
    timestamp?: string,
  ): MessageFileShared | undefined {
    const messageFileSharedNode = stanza.getChild("message-file-shared")

    if (!messageFileSharedNode) return

    return {
      id: MessageParser.id(stanza, false),
      to: stanza.attrs.to,
      from: stanza.attrs.from,
      messageId: messageFileSharedNode.attrs["message_id"],
      fileUrl: messageFileSharedNode.attrs["file_url"],
      userJid: messageFileSharedNode.attrs["user_jid"],
      timestamp: timestamp || new Date().toISOString(),
      displayType: MessageDisplayType.MessageFileShared,
      type: stanza.attrs.type,
    } as MessageFileShared
  }
}
