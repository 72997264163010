import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { PURGE } from "redux-persist"
import { RootState } from "./store"

export type Settings = {
  mobileNotificationsEnabled: boolean
  pushSubscriptionPresent: boolean
  showNicknameInWhatsapp: boolean
  showTranslations: boolean
}

const initialState: Settings = {
  mobileNotificationsEnabled: false,
  pushSubscriptionPresent: false,
  showNicknameInWhatsapp: false,
  showTranslations: true,
}

type UpdateSettingsAction = {
  settings: Partial<Settings>
}

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    updateSettings: (state, action: PayloadAction<UpdateSettingsAction>) => {
      return {
        ...state,
        ...action.payload.settings,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState)
  },
})

export const selectSettings = (state: RootState) => state.settings

export const { updateSettings } = settingsSlice.actions
export default settingsSlice.reducer
