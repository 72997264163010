import { Client } from "@xmpp/client"
import { useCallback, useState } from "react"
import {
  Chat,
  Marker,
  Message,
  Reaction,
  addBatchMessagesToChat,
  addMessageCorrection,
  addBatchMessageReactions,
  MessageFileShared,
  MessageRetraction,
  addBatchMessageRetractions,
  addBatchMessageFileShares,
} from "../reducers/chatsSlice"
import { AppDispatch } from "../reducers/store"
import { MAMQueryOptions, iterateThroughArchivePages } from "../api/xeps/MAM"
import MAMStanzaProcessor from "../api/xeps/MAMStanzaProcessor"
import { captureError } from "../ErrorHandlers"

const useInfiniteScroll = (): [
  boolean,
  (
    chat: Chat,
    client: Client,
    myJid: string,
    dispatch: AppDispatch,
  ) => Promise<boolean | undefined>,
] => {
  const [isFetchingPage, setIsFetching] = useState(false)

  const fetchMoreMessages = useCallback(
    async (
      chat: Chat,
      client: Client,
      myJid: string,
      dispatch: AppDispatch,
    ) => {
      if (chat.messages.length === 0) return

      setIsFetching(true)

      let options: MAMQueryOptions = {
        beforeId: chat.messages[0].id,
      }

      if (chat.room) {
        options = {
          ...options,
          toJid: chat.jid,
          flipPage: true,
        }
      } else {
        options = {
          ...options,
          withJid: chat.jid,
          flipPage: true,
        }
      }

      const markersAccumulator: Marker[][] = []
      const messagesAccumulator: Message[][] = []
      const reactionsAccumulator: Reaction[][] = []
      const messageFileSharesAccumulator: MessageFileShared[][] = []
      const messageRetractionsAccumulator: MessageRetraction[][] = []

      try {
        const fetchResult = await iterateThroughArchivePages(
          client,
          options,
          MAMStanzaProcessor.accumulatePages(
            messagesAccumulator,
            markersAccumulator,
            reactionsAccumulator,
            messageFileSharesAccumulator,
            messageRetractionsAccumulator,
            client,
          ),
          "desc",
          25,
        )

        const retractions = MAMStanzaProcessor.normalizePagesAscending(
          messageRetractionsAccumulator,
          "desc",
          !!options.flipPage,
        )

        dispatch(
          addBatchMessageRetractions({ messageRetractions: retractions }),
        )

        const messages = MAMStanzaProcessor.normalizePagesAscending(
          messagesAccumulator,
          "desc",
          !!options.flipPage,
        ) as Message[]

        const groupedMessages =
          MAMStanzaProcessor.groupByMessagesAndCorrections(messages)

        groupedMessages["corrections"].forEach((correction) => {
          dispatch(addMessageCorrection({ message: correction, myJid }))
        })

        dispatch(
          addBatchMessagesToChat({
            messages: groupedMessages["messages"],
            myJid: client.jid!.toString(),
            mode: "prepend",
          }),
        )

        const reactionsToDispatch = MAMStanzaProcessor.normalizePagesAscending(
          reactionsAccumulator,
          "desc",
          !!options.flipPage,
        )
        dispatch(
          addBatchMessageReactions({ reactions: reactionsToDispatch, myJid }),
        )

        const fileShares = MAMStanzaProcessor.normalizePagesAscending(
          messageFileSharesAccumulator,
          "desc",
          !!options.flipPage,
        )
        dispatch(addBatchMessageFileShares({ messageFileShares: fileShares }))

        return fetchResult.hasMore
      } catch (error) {
        captureError(error, {
          origin: "UserAction",
          extra: { message: "infiniteScroll" },
        })
      } finally {
        setIsFetching(false)
      }
    },
    [],
  )

  return [isFetchingPage, fetchMoreMessages]
}

export default useInfiniteScroll
