import { SendoutQueue, SendoutQueueItem } from "./sendoutQueueSlice"
import { RootState } from "./store"

export const isAcked =
  (id: string) =>
  (state: SendoutQueue): boolean => {
    return !state.some((item) => item.messageId === id)
  }

export const getSendoutQueue =
  () =>
  (state: RootState): SendoutQueue => {
    return state.sendoutQueue
  }

export const getItem =
  (id: string) =>
  (state: SendoutQueue): SendoutQueueItem | undefined => {
    return state.find((item) => item.messageId === id)
  }

export const getItemsForRoom =
  (jid: string) =>
  (state: SendoutQueue): SendoutQueueItem[] => {
    return state.filter(
      (item) => item.targetJid === jid && item.messageType === "groupchat",
    )
  }
