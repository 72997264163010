import React, { ReactNode } from "react"

type ChatStatusBarProps = {
  children: ReactNode
}

export default function ChatStatusBar({ children }: ChatStatusBarProps) {
  return (
    <div className="px-3 py-1.5 text-center flex justify-center absolute w-full -top-1 -translate-y-full text-black bg-warning-100 border-b-0 border-transparent rounded-md">
      {children}
    </div>
  )
}
