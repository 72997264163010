import React from "react"
import Avatar from "react-avatar"
import classNames from "classnames"
import css from "./avatarImage.module.css"
import { Participant } from "../reducers/chatsSlice"
import { Icon } from "@blueprintjs/core"

type AvatarProps = {
  name: string
  size?: "small" | "large" | "xlarge"
  className?: string
  src?: string
  clickable?: boolean
  active?: boolean
  color?: string
}

export const participantPhotoToSrc = (photo: Participant["photo"]) => {
  if (!photo) return

  return `data:${photo.type};base64, ${photo.data}`
}

const AvatarImage = ({
  name,
  size = "small",
  className,
  src,
  clickable,
  active,
  color,
}: AvatarProps) => {
  const sizePx = () => {
    if (size === "large") return "50px"
    if (size === "xlarge") return "100px"

    return "30px"
  }

  const textSizeRatio = () => {
    if (size === "large") return 3
    if (size === "xlarge") return 5

    return 2.5
  }

  const avatarAlt = () => {
    if (!active) return name

    return `${name}, driving`
  }

  return (
    <div
      className={classNames(css.avatarContainer, {
        [css.activeContainer]: active,
      })}
    >
      {active && (
        <div className={css.active}>
          <Icon icon="double-chevron-right" />
        </div>
      )}
      <Avatar
        name={name}
        className={classNames(css.avatar, className, {
          [css.clickable]: clickable,
          [css.large]: size === "large",
          [css.small]: size === "small",
          [css.xlarge]: size === "xlarge",
        })}
        maxInitials={2}
        round={true}
        size={sizePx()}
        textSizeRatio={textSizeRatio()}
        color={color || css.defaultAvatarColor}
        src={src}
        alt={avatarAlt()}
        title={avatarAlt()}
      />
    </div>
  )
}

export default AvatarImage
