import React from "react"
import css from "./SearchBar.module.css"
import { Icon, InputGroup } from "@blueprintjs/core"
import { isMobile } from "react-device-detect"

type SearchBarProps = {
  value: string
  onValueChange: (value: string) => void
  inPopover?: boolean
}

const SearchBar = ({ value, onValueChange, inPopover }: SearchBarProps) => {
  return (
    <InputGroup
      leftElement={<Icon icon="search" size={14} />}
      autoFocus={!!inPopover}
      type="search"
      placeholder="Search for chat..."
      value={value}
      onValueChange={onValueChange}
      large={isMobile}
      className={css.searchInput}
    />
  )
}

export default SearchBar
