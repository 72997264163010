import { Element } from "@xmpp/xml"

type FileShareResult = {
  shareToken: string | undefined
}

export class FileShareResultParser {
  static parse(stanza: Element): FileShareResult | undefined {
    const token = FileShareResultParser.tokenChild(stanza)

    if (!token) return undefined

    const shareToken = token.children[0] as string

    return shareToken ? { shareToken } : undefined
  }

  static tokenChild(result: Element): Element | undefined {
    const commandElement = result.getChild("command")
    const fieldElement = commandElement?.getChild("x")?.getChild("field")

    if (!fieldElement || fieldElement.getAttr("var") !== "share_token")
      return undefined

    return fieldElement.getChild("value")
  }
}

export default FileShareResultParser
