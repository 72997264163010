import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "./store"
import { PURGE } from "redux-persist"

export type AppVersionState = {
  updateAvailable: boolean
}

const initialState = { updateAvailable: false } as AppVersionState

type SetUpdateAvailableAction = {
  updateAvailable: boolean
}

export const appVersionSlice = createSlice({
  name: "appVersion",
  initialState,
  reducers: {
    setUpdateAvailable: (
      state,
      action: PayloadAction<SetUpdateAvailableAction>,
    ) => {
      state.updateAvailable = action.payload.updateAvailable
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState)
  },
})

export const { setUpdateAvailable } = appVersionSlice.actions
export default appVersionSlice.reducer

export const selectUpdateAvailable = (state: RootState) => {
  return state.appVersion.updateAvailable
}
