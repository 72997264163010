export default class ClipboardWriter {
  static executeAndWriteResult(execution: () => Promise<string | undefined>) {
    if (typeof ClipboardItem && navigator.clipboard.write) {
      const text = new ClipboardItem({
        "text/plain": execution()
          .then((result) => result || "")
          .then((text) => new Blob([text], { type: "text/plain" })),
      })
      navigator.clipboard.write([text])
    } else {
      execution()
        .then((result) => result || "")
        .then((text) => navigator.clipboard.writeText(text))
    }
  }
}
