import { Client, xml } from "@xmpp/client"
import { Element } from "@xmpp/xml"
import iqMeasure from "../instrumentation/iqMeasure"
import SettingsParser from "../lib/settingsParser"

const PUSHNOT_ADDRESS = "pushnot.global"
const INFO_ADDRESS = "info.global"

export type PushToken =
  | {
      data: string
      type: string
    }
  | PushSubscription

type GetRoomsItem = {
  jid: string
  name: string
  groups: string[]
}

const AdHocCommands = {
  sendPushNotificationsSubscription: (
    client: Client,
    subscription: PushToken,
  ): Promise<Element> => {
    const { iqCaller } = client

    const measuredIqCaller = iqMeasure(iqCaller, "set_subscription")
    return measuredIqCaller.request(
      xml(
        "iq",
        {
          from: client.jid,
          type: "set",
          to: PUSHNOT_ADDRESS,
        },
        xml(
          "command",
          {
            node: "set_subscription",
            action: "execute",
            xmlns: "http://jabber.org/protocol/commands",
          },
          xml("json", "urn:xmpp:json:0", JSON.stringify(subscription)),
        ),
      ),
    )
  },

  getPushNotificationsSubscription: async (
    client: Client,
  ): Promise<PushToken | undefined> => {
    const { iqCaller } = client
    const measuredIqCaller = iqMeasure(iqCaller, "get_subscription")

    const result = await measuredIqCaller.request(
      xml(
        "iq",
        {
          from: client.jid,
          type: "set",
          to: PUSHNOT_ADDRESS,
        },
        xml("command", {
          node: "get_subscription",
          action: "execute",
          xmlns: "http://jabber.org/protocol/commands",
        }),
      ),
    )
    return SettingsParser.parsePushSubscription(result)
  },

  setDriverDestination: (
    client: Client,
    to: string,
    address: string,
  ): Promise<Element> => {
    const { iqCaller } = client
    const measuredIqCaller = iqMeasure(iqCaller, "set_next_destination")

    return measuredIqCaller.request(
      xml(
        "iq",
        {
          from: client.jid,
          type: "set",
          to: to,
        },
        xml(
          "command",
          {
            node: "set_next_destination",
            action: "execute",
            xmlns: "http://jabber.org/protocol/commands",
          },
          xml(
            "x",
            { xmlns: "jabber:x:data", type: "form" },
            xml(
              "field",
              { var: "address", type: "text-single" },
              xml("value", {}, address),
            ),
          ),
        ),
      ),
    )
  },

  removeDriverDestination: (client: Client, to: string): Promise<Element> => {
    const { iqCaller } = client
    const measuredIqCaller = iqMeasure(iqCaller, "clear_next_destination")

    return measuredIqCaller.request(
      xml(
        "iq",
        {
          from: client.jid,
          type: "set",
          to: to,
        },
        xml("command", {
          node: "clear_next_destination",
          action: "execute",
          xmlns: "http://jabber.org/protocol/commands",
        }),
      ),
    )
  },
  getCommandsList: (client: Client, to: string): Promise<Element> => {
    const { iqCaller } = client
    const measuredIqCaller = iqMeasure(iqCaller, "disco_info_commands")

    return measuredIqCaller.request(
      xml(
        "iq",
        {
          from: client.jid,
          type: "get",
          to: to,
        },
        xml("query", {
          node: "http://jabber.org/protocol/commands",
          xmlns: "http://jabber.org/protocol/disco#items",
        }),
      ),
    )
  },
  getGPSDeviceList: (client: Client, to: string): Promise<Element> => {
    const { iqCaller } = client
    const measuredIqCaller = iqMeasure(iqCaller, "get_gps_device")

    return measuredIqCaller.request(
      xml(
        "iq",
        {
          type: "set",
          to: `${to}/driverbot`,
        },
        xml("command", {
          node: "set_gps_device",
          action: "execute",
          xmlns: "http://jabber.org/protocol/commands",
        }),
      ),
    )
  },
  setDriverGPSDevice: (
    client: Client,
    to: string,
    device: string | null,
    sessionId: string,
  ): Promise<Element> => {
    const { iqCaller } = client
    const measuredIqCaller = iqMeasure(iqCaller, "set_gps_device")

    return measuredIqCaller.request(
      xml(
        "iq",
        {
          from: client.jid,
          type: "set",
          to: `${to}/driverbot`,
        },
        xml(
          "command",
          {
            node: "set_gps_device",
            sessionid: sessionId,
            xmlns: "http://jabber.org/protocol/commands",
          },
          xml(
            "x",
            { xmlns: "jabber:x:data", type: "submit" },
            device !== null
              ? xml("field", { var: "gps_device_id" }, xml("value", {}, device))
              : xml("field", { var: "gps_device_id" }, xml("value", {})),
          ),
        ),
      ),
    )
  },

  getRooms: async (client: Client): Promise<GetRoomsItem[]> => {
    const { iqCaller } = client
    const measuredIqCaller = iqMeasure(iqCaller, "get_rooms")

    const response = await measuredIqCaller.request(
      xml(
        "iq",
        {
          type: "set",
          to: INFO_ADDRESS,
        },
        xml("command", {
          node: "get_rooms",
          action: "execute",
          xmlns: "http://jabber.org/protocol/commands",
        }),
      ),
      4 * 1000,
    )
    const items = response
      ?.getChild("command", "http://jabber.org/protocol/commands")
      ?.getChild("query", "http://jabber.org/protocol/disco#items")
      ?.getChildren("item") as Element[]

    return items.map((item) => {
      const groups = item.getChildren("group").map((g) => g.text())
      return { ...item.attrs, groups } as GetRoomsItem
    })
  },

  shareFile: async ({
    client,
    fileUrl,
    userJid,
    messageId,
    messageRoomJid,
    triggeringRoomJid,
  }: {
    client: Client
    fileUrl: string
    userJid: string
    messageId: string
    messageRoomJid: string | undefined
    triggeringRoomJid: string
  }): Promise<Element> => {
    const { iqCaller } = client
    const measuredIqCaller = iqMeasure(iqCaller, "share_file")

    return measuredIqCaller.request(
      xml(
        "iq",
        {
          from: client.jid,
          type: "set",
          to: INFO_ADDRESS,
        },
        xml(
          "command",
          {
            node: "share_file",
            xmlns: "http://jabber.org/protocol/commands",
          },
          xml(
            "x",
            { xmlns: "jabber:x:data", type: "form" },
            xml(
              "field",
              { var: "file_url", type: "text-single" },
              xml("value", {}, fileUrl),
            ),
            xml(
              "field",
              { var: "message_id", type: "text-single" },
              xml("value", {}, messageId),
            ),
            xml(
              "field",
              { var: "user_jid", type: "text-single" },
              xml("value", {}, userJid),
            ),
            xml(
              "field",
              { var: "message_room_jid", type: "text-single" },
              xml("value", {}, messageRoomJid || ""),
            ),
            xml(
              "field",
              { var: "triggering_room_jid", type: "text-single" },
              xml("value", {}, triggeringRoomJid),
            ),
          ),
        ),
      ),
    )
  },
  search: async ({
    client,
    query,
    chatJid,
  }: {
    client: Client
    query: string
    chatJid: string
  }): Promise<Element> => {
    const { iqCaller } = client
    const measuredIqCaller = iqMeasure(iqCaller, "search")

    return measuredIqCaller.request(
      xml(
        "iq",
        {
          from: client.jid,
          type: "set",
          to: INFO_ADDRESS,
        },
        xml(
          "command",
          {
            node: "search",
            xmlns: "http://jabber.org/protocol/commands",
          },
          xml(
            "x",
            { xmlns: "jabber:x:data", type: "form" },
            xml(
              "field",
              { var: "query", type: "text-single" },
              xml("value", {}, query),
            ),
            xml(
              "field",
              { var: "chat_jid", type: "text-single" },
              xml("value", {}, chatJid),
            ),
          ),
        ),
      ),
    )
  },
  getMessageContextFromSearch: async ({
    client,
    timestamp,
    chatJid,
  }: {
    client: Client
    timestamp: string
    chatJid: string
  }): Promise<Element> => {
    const { iqCaller } = client
    const measuredIqCaller = iqMeasure(
      iqCaller,
      "get_message_context_from_search",
    )

    return measuredIqCaller.request(
      xml(
        "iq",
        {
          from: client.jid,
          type: "set",
          to: INFO_ADDRESS,
        },
        xml(
          "command",
          {
            node: "get_message_context_from_search",
            xmlns: "http://jabber.org/protocol/commands",
          },
          xml(
            "x",
            { xmlns: "jabber:x:data", type: "form" },
            xml(
              "field",
              { var: "timestamp", type: "text-single" },
              xml("value", {}, timestamp),
            ),
            xml(
              "field",
              { var: "chat_jid", type: "text-single" },
              xml("value", {}, chatJid),
            ),
          ),
        ),
      ),
    )
  },
}

export default AdHocCommands
