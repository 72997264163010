import { createSelector } from "@reduxjs/toolkit"
import { RootState } from "./store"
import { MessageStatus } from "../lib/messageHelpers"
import { Chat } from "../reducers/chatsSlice"
import ChatHelpers from "../lib/chatHelpers"
import { getItem } from "./sendoutQueueSelectors"
import { JID } from "@xmpp/jid"

type MessageStatuses = Record<string, MessageStatus | undefined>

export const messageStatuses = (chat: Chat | undefined, myJid: JID) =>
  createSelector(
    (state: RootState) => state.sendoutQueue,
    (sendoutQueue) => {
      const statuses: MessageStatuses = {}
      if (!chat) return statuses

      for (const message of chat.messages) {
        const queueItem = getItem(message.id)(sendoutQueue)
        const ackStatus = queueItem
          ? queueItem.failed
            ? "not_sent"
            : "scheduled"
          : "sent"
        const id = message.id
        statuses[id] = ChatHelpers.messageStatus(
          chat,
          message,
          myJid.toString(),
          ackStatus,
        )
      }
      return statuses
    },
  )
