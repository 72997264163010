import React from "react"
import FileStub from "../message/FileStub"
import LazyImage from "../message/LazyImage"

export default function SearchMessageRowFile({
  file,
  nonClickable,
}: {
  file: { url: string; type: "image" | "file" }
  nonClickable?: boolean
}) {
  return (
    <div className="max-w-full overflow-x-clip">
      {file.type === "image" ? (
        <LazyImage url={file.url} nonClickable={nonClickable} />
      ) : (
        <FileStub url={file.url} nonClickable={nonClickable} />
      )}
    </div>
  )
}
