import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { PURGE } from "redux-persist"
import { type NetworkInformation, getNetworkInformation } from "../utils"

export type RoomSyncStatusJid = {
  jid: string
}

export type Count = {
  count: number
}

type RoomSyncMetadata = {
  jid: string
  messages: number
  markers: number
  reactions: number
  retractions: number
}

export type RoomSyncStatus = {
  started: number | undefined
  messagesCount: number
  markersCount: number
  reactionsCount: number
  retractionsCount: number
  syncQueue: RoomSyncStatusJid[]
  initSyncRoomCount: number
  syncedJids: string[]
  failedJids: string[]
  preRoomSyncInProgress: boolean
  networkInformationStart: NetworkInformation | undefined
}

const initialState: RoomSyncStatus = {
  started: undefined,
  preRoomSyncInProgress: false,
  messagesCount: 0,
  markersCount: 0,
  reactionsCount: 0,
  retractionsCount: 0,
  syncQueue: [],
  syncedJids: [],
  failedJids: [],
  initSyncRoomCount: 0,
  networkInformationStart: getNetworkInformation(),
}

const roomSyncStatus = createSlice({
  name: "roomSyncStatus",
  initialState,
  reducers: {
    addToSyncQueue: (
      state: RoomSyncStatus,
      action: PayloadAction<RoomSyncStatusJid>,
    ) => {
      state.syncQueue = state.syncQueue.filter(
        (r) => r.jid !== action.payload.jid,
      )
      state.syncQueue.unshift({ jid: action.payload.jid })
    },

    removeFromSyncQueue: (
      state: RoomSyncStatus,
      action: PayloadAction<RoomSyncStatusJid>,
    ) => {
      state.syncQueue = state.syncQueue.filter(
        (r) => r.jid !== action.payload.jid,
      )
    },

    addToSynced: (
      state: RoomSyncStatus,
      action: PayloadAction<RoomSyncStatusJid>,
    ) => {
      state.syncedJids.push(action.payload.jid)
    },

    markAsFailed: (
      state: RoomSyncStatus,
      action: PayloadAction<RoomSyncStatusJid>,
    ) => {
      state.failedJids.push(action.payload.jid)
    },

    removeFromFailed: (
      state: RoomSyncStatus,
      action: PayloadAction<RoomSyncStatusJid>,
    ) => {
      state.failedJids = state.failedJids.filter(
        (jid) => jid !== action.payload.jid,
      )
    },

    setInitSyncRoomCount: (
      state: RoomSyncStatus,
      action: PayloadAction<Count>,
    ) => {
      state.initSyncRoomCount = action.payload.count
    },

    clearSyncedAndFailedRooms: (state: RoomSyncStatus) => {
      state.started = window.performance.now()
      state.syncedJids = []
      state.failedJids = []
    },

    updatePreRoomSyncInProgress: (
      state: RoomSyncStatus,
      action: PayloadAction<boolean>,
    ) => {
      state.preRoomSyncInProgress = action.payload
    },
    updateSyncMetadata: (
      state: RoomSyncStatus,
      action: PayloadAction<RoomSyncMetadata>,
    ) => {
      const payload = action.payload
      state.messagesCount += payload.messages
      state.markersCount += payload.markers
      state.reactionsCount += payload.reactions
      state.retractionsCount += payload.retractions
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState)
  },
})

export const {
  addToSyncQueue,
  removeFromSyncQueue,
  addToSynced,
  markAsFailed,
  removeFromFailed,
  setInitSyncRoomCount,
  clearSyncedAndFailedRooms,
  updateSyncMetadata,
  updatePreRoomSyncInProgress,
} = roomSyncStatus.actions

export default roomSyncStatus.reducer
