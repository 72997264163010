import React, { useContext, useMemo } from "react"
import classNames from "classnames"
import { Message } from "../reducers/chatsSlice"
import { XmppContext } from "../stream/xmppClient"
import cssUtils from "./utilsCss.module.css"
import MessageHelpers from "../lib/messageHelpers"
import { useActiveChatDriverName } from "../hooks/useActiveChatDriverName"
import { jid } from "@xmpp/client"

type MessageReactionsProps = {
  message: Message
  className?: string
}

const MessageReactions = ({ message, className }: MessageReactionsProps) => {
  const { myJid } = useContext(XmppContext)
  const driverName = useActiveChatDriverName()

  const {
    reactionsAsEmojiWithSender,
    currentUserReaction,
    driverReaction,
    emojis,
  } = useMemo(() => {
    const reactionsAsEmojiWithSender =
      MessageHelpers.getReactionsAsEmojisWithSender(message)

    const currentUserReaction = reactionsAsEmojiWithSender?.find(
      (r) => jid.parse(r.from).resource === myJid.local,
    )

    const driverReaction = reactionsAsEmojiWithSender?.find(
      (r) => jid.parse(r.from).resource === driverName,
    )

    const emojis = Array.from(
      new Set(reactionsAsEmojiWithSender.map((r) => r.emoji)),
    )

    return {
      reactionsAsEmojiWithSender,
      currentUserReaction,
      driverReaction,
      emojis,
    }
  }, [message, driverName, myJid.local])

  if (!emojis.length) return null

  return (
    <div className={classNames(className, "flex gap-1 select-none")}>
      <MessageReactionBubble
        emojis={emojis}
        count={reactionsAsEmojiWithSender.length}
        currentUserEmoji={currentUserReaction?.emoji}
        driverEmoji={driverReaction?.emoji}
      />
    </div>
  )
}

type MessageReactionBubbleProps = {
  emojis: string[]
  count: number
  currentUserEmoji: string | undefined
  driverEmoji: string | undefined
}

function MessageReactionBubble({
  emojis,
  count,
  currentUserEmoji,
  driverEmoji,
}: MessageReactionBubbleProps) {
  const tooltipMessage = driverEmoji
    ? `Driver reacted with ${driverEmoji}`
    : `${plularize(count, "user", "users")} reacted`

  return (
    <span className={cssUtils.tooltip}>
      <p
        className={classNames(
          "px-1 py-0 border rounded-full flex items-center gap-1",
          currentUserEmoji
            ? "bg-active-chat/10 border-active-chat/20"
            : "bg-gray-100 border-gray-200",
        )}
      >
        {emojis.join("")}
        {count > 1 && <span className="text-xs">{count}</span>}
      </p>
      <span className={cssUtils.tooltiptext}>{tooltipMessage}</span>
    </span>
  )
}

function plularize(count: number, singular: string, plural: string) {
  return `${count} ${count === 1 ? singular : plural}`
}

export default MessageReactions
