import React from "react"
import { useAppSelector } from "../reducers/hooks"
import { isInitSyncDone } from "../reducers/roomSyncStatusSelectors"
import Loader from "./Loader"

export default function RoomLoading() {
  const hasInitSyncCompleted = useAppSelector(isInitSyncDone)

  if (hasInitSyncCompleted) {
    return null
  }

  return <Loader small={true} />
}
