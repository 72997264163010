import React from "react"
import { SearchHit } from "../../lib/searchResultParser"
import AvatarImage from "../AvatarImage"
import SearchMessageBubbleBody from "./SearchMessageBubbleBody"

export default function SearchMessageDetails({
  onGoToDetails,
  messageHit,
}: {
  onGoToDetails?: () => void
  messageHit: SearchHit
}) {
  return (
    <a
      onClick={onGoToDetails}
      className="w-full hover:no-underline group flex justify-center"
    >
      <div className="flex flex-col w-full max-w-5xl items-start bg-white p-2 md:p-4 rounded-lg border-gray-200 border space-y-2 group-hover:bg-gray-50">
        <div className="text-sm text-gray-600">
          # {messageHit.document.chat}
        </div>
        <div className="flex space-x-1 md:space-x-2 w-full">
          <div className="w-8 h-8">
            <AvatarImage name={messageHit.document.sender} />
          </div>
          <div className="grow flex flex-col space-y-1 text-black text-sm">
            <div className="flex flex-row space-x-2">
              <span className="font-semibold">
                {messageHit.document.sender}
              </span>
              <span className="text-gray-500">
                {new Date(
                  messageHit.document.timestamp / 1000,
                ).toLocaleString()}
              </span>
            </div>
            <div className="flex flex-col space-y-2 break-all whitespace-break-spaces">
              <SearchMessageBubbleBody
                messageHit={messageHit}
                filesClickable={false}
              />
            </div>
          </div>
        </div>
      </div>
    </a>
  )
}
