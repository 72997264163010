import { Client, xml } from "@xmpp/client"
import { Message } from "../reducers/chatsSlice"
import { v4 as uuid } from "uuid"

const XmppMessageRetractionApi = {
  retract: async (client: Client, message: Message, to: string) => {
    const originId = uuid()
    window.analytics.track("MessageRetractionClick", { originId })
    const retractMessage = xml(
      "message",
      { type: message.type, to },
      xml("retract", { id: message.id, xmlns: "urn:xmpp:message-retract:1" }),
      xml("fallback", {
        for: "urn:xmpp:message-retract:1",
        xmlns: "urn:xmpp:fallback:0",
      }),
      xml(
        "body",
        {},
        "This person attempted to retract a previous message, but it's unsupported by your client.",
      ),
      xml("store", "urn:xmpp:hints"),
      xml("origin-id", { xmlns: "urn:xmpp:sid:0", id: originId }),
    )

    return client.send(retractMessage)
  },
}

export default XmppMessageRetractionApi
