import { Element } from "@xmpp/xml"
import { jid } from "@xmpp/client"
import { Bookmark } from "../api/xeps/PepNativeBookmarks"

class BookmarkEventParser {
  static parse(
    stanza: Element,
  ):
    | { type: "create"; bookmark: Bookmark }
    | { type: "retract"; jid: string }
    | undefined {
    const node = stanza
      .getChild("event")
      ?.getChildByAttr("node", "urn:xmpp:bookmarks:1")

    if (!node) return

    if (node.getChild("item")) {
      const item = node.getChild("item")!
      const conference = item.getChild("conference")
      if (!conference) return

      return {
        type: "create",
        bookmark: {
          jid: jid.parse(item.getAttr("id")).bare().toString(),
          autojoin: conference.getAttr("autojoin") === "true",
          name: conference.getAttr("name"),
        },
      }
    } else if (node.getChild("retract")) {
      return { type: "retract", jid: node.getChild("retract")?.getAttr("id") }
    }
  }
}

export default BookmarkEventParser
