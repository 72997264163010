import JSZip from "jszip"
import { saveAs } from "file-saver"

export type UrlAndFilename = {
  url: string
  filename: string
}

export function downloadMultipleImagesAsZip(urlAndFilenames: UrlAndFilename[]) {
  const zip = new JSZip()
  const firstFilename = urlAndFilenames[0].filename
  const zipName = firstFilename.slice(0, firstFilename.length - 4)

  const download = async (urlAndFilename: UrlAndFilename) => {
    return fetch(urlAndFilename.url)
      .then((response) => response.blob())
      .then((blob) => {
        zip.file(urlAndFilename.filename, blob)
      })
      .catch((error) => {
        console.error("Error while downloading file:", error)
      })
  }

  const arrOfFiles = urlAndFilenames.map((item) => download(item))

  Promise.all(arrOfFiles)
    .then(() => {
      zip.generateAsync({ type: "blob" }).then(function (blob) {
        saveAs(blob, `${zipName}.zip`)
      })
    })
    .catch((err) => {
      console.log(err)
    })
}
