import { Element } from "@xmpp/xml"

class ParserHelpers {
  static stanzaId(stanza: Element): string | undefined {
    const stanzaId = stanza.getChild("stanza-id")
    if (!stanzaId) return undefined

    return stanzaId.attrs.id
  }
}

export default ParserHelpers
