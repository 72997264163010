import React, {
  KeyboardEvent,
  useState,
  useRef,
  ChangeEvent,
  forwardRef,
  useImperativeHandle,
  ForwardedRef,
  useEffect,
  useMemo,
  useCallback,
} from "react"
import {
  Button,
  Icon,
  Menu,
  MenuItem,
  Popover,
  TextArea,
} from "@blueprintjs/core"
import EmojiPicker, { EmojiClickData } from "emoji-picker-react"
import { Chat } from "../reducers/chatsSlice"
import { useAppDispatch, useAppSelector } from "../reducers/hooks"
import { driversProfiles } from "../reducers/profilesSliceSelectors"
import { Profile } from "../reducers/profilesSlice"
import ChatInputFiles, { PastedFile } from "./ChatInputFiles"
import classNames from "classnames"
import { shallowEqual } from "react-redux"
import css from "./ChatInput.module.css"
import cssUtils from "./utilsCss.module.css"
import { isMobile } from "react-device-detect"
import ChatConnectionStatusBar from "./ChatConnectionStatusBar"
import { ChatReplyBar } from "./ChatReplyBar"
import { clearReplyMessage } from "../reducers/chatRepliesSlice"
import { selectChatReplyMessage } from "../reducers/chatRepliesSliceSelectors"
import { isRoomFailed } from "../reducers/roomSyncStatusSelectors"

type ChatInputProps = {
  chat: Chat
  onSend: (text: string, files: FileList) => void
}

export type ChatInputTextAreaRef = {
  focus: () => void
  value(): string
}

const ChatInput = forwardRef<ChatInputTextAreaRef, ChatInputProps>(
  (
    { onSend, chat }: ChatInputProps,
    ref: ForwardedRef<ChatInputTextAreaRef>,
  ) => {
    const inputFileRef = useRef<HTMLInputElement>(null)
    const inputTextRef = useRef<HTMLTextAreaElement>(null)
    const [isEmojiPickerOpen, setEmojiPickerOpen] = useState<boolean>(false)
    const [value, setValue] = useState<string>("")
    const [isAutocompleteOpen, setAutocompleteOpen] = useState<boolean>(false)
    const drivers = useAppSelector(driversProfiles, shallowEqual)
    const dispatch = useAppDispatch()
    const [autocompleteStartIndex, setAutocompleteStartIndex] =
      useState<number>(0)
    const [autocompleteActiveItemIndex, setAutocompleteActiveItemIndex] =
      useState<number>(0)
    const activeItemRef = useRef<HTMLLIElement>(null)

    const [pastedFiles, setPastedFiles] = useState<PastedFile[]>([])
    const [isSending, setIsSending] = useState<boolean>(false)

    const replyMessage = useAppSelector(selectChatReplyMessage(chat))
    const roomSyncFailed = useAppSelector(isRoomFailed(chat.jid))

    useEffect(() => {
      setValue(chat.draftMessage || "")
      setPastedFiles([])
    }, [chat.jid, chat.draftMessage])

    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          if (!entries[0].isIntersecting) {
            activeItemRef.current?.scrollIntoView()
          }
        },
        { threshold: 1.0 },
      )

      const observerTargetCurrent = activeItemRef.current

      if (observerTargetCurrent) {
        observer.observe(activeItemRef.current)
      }

      return () => {
        if (observerTargetCurrent) {
          observer.unobserve(observerTargetCurrent)
        }
      }
    }, [autocompleteActiveItemIndex])

    useImperativeHandle(ref, () => {
      return {
        focus: () => {
          inputTextRef.current?.focus()
        },
        value: () => {
          return inputTextRef.current?.value || ""
        },
      }
    }, [])

    const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      // if # was removed, close autocomplete
      if (isAutocompleteOpen) {
        if (event.target.value[autocompleteStartIndex] !== "#") {
          setAutocompleteOpen(false)
        }
      }
      setValue(event.target.value)
    }

    const handleOnSend = async () => {
      setIsSending(true)
      const fileList = new DataTransfer()
      pastedFiles.forEach((file) => {
        fileList.items.add(file.file)
      })
      try {
        await onSend(value, fileList.files)
        setPastedFiles([])
        setValue("")
        dispatch(clearReplyMessage({ chatJid: chat.jid }))
      } finally {
        setIsSending(false)
      }
    }

    const handleOnEnter = (e: KeyboardEvent<HTMLTextAreaElement>) => {
      if (isMobile) return

      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault()
        handleOnSend()
      }
    }

    const openFileBrowser = () => {
      inputFileRef.current?.click()
    }

    const updateFilesStates = (files: File[]) => {
      const maxFiles = 8
      if (pastedFiles.length >= maxFiles) {
        window.appToaster.show({
          message: `Sorry but you can send only ${maxFiles} files at the time`,
          intent: "danger",
          timeout: 3000,
        })
        window.analytics.track("AttachedFilesLimitReached", {
          pasteAttempt: files.length + maxFiles,
          limit: maxFiles,
        })
        return
      }

      const remainingSpace = maxFiles - pastedFiles.length
      const filesToAdd = files.slice(0, remainingSpace)

      const newFiles = filesToAdd.map((file) => ({
        file,
        url: URL.createObjectURL(file),
      }))

      setPastedFiles((prevFiles) => {
        const newFilesArray = [...prevFiles, ...newFiles]
        return newFilesArray.slice(0, maxFiles)
      })
    }

    const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files || e.target.files.length === 0) return

      const files = Array.from(e.target.files)
      updateFilesStates(files)
    }

    const handleOnPaste = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
      if (e.clipboardData.files && e.clipboardData.files.length !== 0) {
        e.preventDefault()
        const files = Array.from(e.clipboardData.files)
        updateFilesStates(files)
      }
    }

    const handleRemoveImage = (index: number) => {
      const updatedFiles = pastedFiles.filter((file, i) => i !== index)
      setPastedFiles(updatedFiles)
    }

    const handleEmojiSelect = (emojiData: EmojiClickData) => {
      setEmojiPickerOpen(false)

      if (inputTextRef.current) {
        const [start, end] = [
          inputTextRef.current.selectionStart,
          inputTextRef.current.selectionEnd,
        ]
        const newString =
          value.substring(0, start ? start : 0) +
          emojiData.emoji +
          value.substring(end ? end : 0, value.length)
        setValue(newString)
      } else {
        setValue(value + emojiData.emoji)
      }
    }

    const EmojiPopover = ({ disabled }: { disabled?: boolean }) => {
      return (
        <Popover
          interactionKind="click"
          placement="top"
          isOpen={isEmojiPickerOpen}
          content={<EmojiPicker onEmojiClick={handleEmojiSelect} />}
          onInteraction={(state) => setEmojiPickerOpen(!!state)}
          renderTarget={({ isOpen, ...props }) => (
            <Button
              disabled={disabled}
              {...props}
              icon={<Icon icon="emoji" size={18} />}
              minimal
            />
          )}
        />
      )
    }

    const driverInfo = useCallback((driver: Profile) => {
      return `${driver.name} ${driverPhoneAndPlates(driver)}`
    }, [])

    const filteredDrivers = useMemo(() => {
      if (!inputTextRef.current) return []
      const filterValue = value.slice(
        autocompleteStartIndex + 1,
        inputTextRef.current?.selectionStart,
      )

      // prevent flickering of menu due to delay with state updates
      if (filterValue === "#" || filterValue === "") return drivers

      const driversInAutocomplete = drivers.filter((driver) =>
        driverInfo(driver).toLowerCase().includes(filterValue.toLowerCase()),
      )

      setAutocompleteActiveItemIndex(0)
      return driversInAutocomplete
    }, [autocompleteStartIndex, driverInfo, drivers, value])

    const renderAutocompleteContent = () => {
      if (!isAutocompleteOpen) return <></>

      if (filteredDrivers.length === 0) {
        setAutocompleteOpen(false)
        return <></>
      }

      return (
        <Menu className={css.autocompleteMenu}>
          {filteredDrivers.map((driver, index) => (
            <MenuItem
              ref={
                index === autocompleteActiveItemIndex
                  ? activeItemRef
                  : undefined
              }
              className={classNames(css.item)}
              active={index === autocompleteActiveItemIndex}
              key={index}
              text={
                <>
                  <b className={cssUtils.bold}>{driver.name}</b>{" "}
                  {driverPhoneAndPlates(driver)}
                </>
              }
              onClick={() => {
                insertDriverInfo(driver)
                setAutocompleteOpen(false)
              }}
            />
          ))}
        </Menu>
      )
    }

    const driverPhoneAndPlates = (driver: Profile) => {
      return [driver.phone, driver.plates]
        .filter((v) => v && v.length > 0)
        .join(" ")
    }

    const insertDriverInfo = (driver: Profile) => {
      const leftPart = value.slice(0, autocompleteStartIndex)
      const spaceBeforeIfNeeded =
        autocompleteStartIndex !== 0 &&
        value[autocompleteStartIndex - 1] !== " "
          ? " "
          : ""
      const driverInfoPart = driverInfo(driver)
      const spaceAfterIfNeeded =
        inputTextRef.current &&
        value[inputTextRef.current.selectionStart] !== " "
          ? " "
          : ""
      const rightPart = value.slice(
        inputTextRef.current?.selectionStart,
        value.length,
      )

      setValue(
        leftPart +
          spaceBeforeIfNeeded +
          driverInfoPart +
          spaceAfterIfNeeded +
          rightPart,
      )

      inputTextRef.current?.focus()

      window.analytics.track("DriverInfoAutocompleted")
    }

    const handleOnKeyDownCapture = (e: KeyboardEvent<HTMLTextAreaElement>) => {
      if ((e.key === "Tab" || e.key === "Enter") && isAutocompleteOpen) {
        e.preventDefault()
        e.stopPropagation()

        insertDriverInfo(filteredDrivers[autocompleteActiveItemIndex])

        setAutocompleteOpen(false)
      }

      if (e.key === "ArrowDown" && isAutocompleteOpen) {
        e.preventDefault()
        e.stopPropagation()

        if (autocompleteActiveItemIndex === filteredDrivers.length - 1) {
          setAutocompleteActiveItemIndex(0)
        } else {
          setAutocompleteActiveItemIndex(autocompleteActiveItemIndex + 1)
        }
      }

      if (e.key === "ArrowUp" && isAutocompleteOpen) {
        e.preventDefault()
        e.stopPropagation()

        if (autocompleteActiveItemIndex === 0) {
          setAutocompleteActiveItemIndex(filteredDrivers.length - 1)
        } else {
          setAutocompleteActiveItemIndex(autocompleteActiveItemIndex - 1)
        }
      }
    }

    const handleOnKeyUpCapture = (e: KeyboardEvent<HTMLTextAreaElement>) => {
      if (value[e.currentTarget.selectionStart - 1] === "#") {
        setAutocompleteStartIndex(e.currentTarget.selectionStart - 1)
        if (!isAutocompleteOpen) setAutocompleteOpen(true)
      }
    }

    const chatNotConnected =
      roomSyncFailed ||
      (chat.connectionStatus &&
        ["disconnected", "failed"].includes(chat.connectionStatus))

    return (
      <div className={classNames(css.inputContainer, "relative")}>
        <ChatConnectionStatusBar status={chat.connectionStatus} />

        {replyMessage && (
          <ChatReplyBar
            replyMessage={replyMessage}
            onClearReply={() =>
              dispatch(clearReplyMessage({ chatJid: chat.jid }))
            }
          />
        )}

        <Popover
          popoverClassName={css.autocomplete}
          content={renderAutocompleteContent()}
          isOpen={isAutocompleteOpen}
          autoFocus={false}
          enforceFocus={false}
          fill={true}
          matchTargetWidth={true}
          minimal={true}
          usePortal={false}
          placement="top"
        >
          <div className={css.autocompleteTarget}></div>
        </Popover>

        <div
          className={classNames({
            [css.inputBoxPastedFiles]: pastedFiles.length !== 0,
          })}
        >
          <TextArea
            onPasteCapture={handleOnPaste}
            tabIndex={0}
            autoFocus={isMobile ? false : true}
            autoResize={true}
            onChange={handleOnChange}
            value={value}
            fill
            aria-label="chat-input"
            onKeyDown={chatNotConnected ? () => {} : handleOnEnter}
            placeholder="Type a message (use # for quick access to the driver's information) ..."
            inputRef={inputTextRef}
            onKeyDownCapture={handleOnKeyDownCapture}
            onKeyUpCapture={handleOnKeyUpCapture}
            className={classNames(css.textArea, {
              [css.textAreaPastedFiles]: pastedFiles.length !== 0,
            })}
          />

          {pastedFiles.length !== 0 && (
            <ChatInputFiles
              pastedFiles={pastedFiles}
              handleRemoveImage={handleRemoveImage}
            />
          )}
        </div>

        <div className={css.inputButtons}>
          <Button
            icon={<Icon icon="paperclip" size={isMobile ? 20 : 18} />}
            minimal
            disabled={chatNotConnected || isSending}
            onClick={openFileBrowser}
            className={classNames({ [css.alignLeft]: isMobile })}
          />
          <input
            type="file"
            multiple={true}
            onChange={onFileChange}
            style={{ display: "none" }}
            ref={inputFileRef}
            onClick={(e) => {
              e.currentTarget.value = ""
            }}
          />
          {!isMobile && (
            <EmojiPopover disabled={chatNotConnected || isSending} />
          )}
          <Button
            icon={<Icon icon="send-message" size={isMobile ? 20 : 18} />}
            minimal
            disabled={chatNotConnected || isSending}
            onClick={handleOnSend}
            intent="primary"
            data-testid="send"
          />
        </div>
      </div>
    )
  },
)

export default ChatInput
