import React, { useMemo, useState } from "react"
import { useAppSelector } from "../reducers/hooks"
import ChatCard from "./ChatCard"
import { Collapse, Icon } from "@blueprintjs/core"
import classNames from "classnames"
import { selectSyncFailedRoomJids } from "../reducers/roomSyncStatusSelectors"
import { selectFilteredSimpleChats } from "../reducers/chatsSliceSelectors"
import ChatListUnreadCount from "./ChatListUnreadCount"
import ChatHelpers from "../lib/chatHelpers"

type ChatListGroupProps = {
  chatJids: string[]
  name: string
  filterBy?: string
  onActiveChatChange: (chatJid: string) => void
}

const ChatListGroup = ({
  chatJids,
  onActiveChatChange,
  name,
  filterBy,
}: ChatListGroupProps) => {
  const [groupOpen, setGroupOpen] = useState<boolean>(true)
  const filtering = filterBy && filterBy.length > 0
  const syncFailedRoomJids = useAppSelector(selectSyncFailedRoomJids)

  const chats = useAppSelector(selectFilteredSimpleChats(chatJids))

  const filteredChats = useMemo(() => {
    if (filterBy === undefined || filterBy.length === 0) return chats

    return chats.filter((chat) =>
      chat.name.toLowerCase().includes(filterBy.toLowerCase()),
    )
  }, [filterBy, chats])

  if (filteredChats.length === 0) return <></>

  return (
    <>
      <div
        className={classNames(
          "font-semibold select-none px-2 py-3 flex flex-row gap-1 align-center",
          { "cursor-pointer": !filtering },
        )}
        onClick={filtering ? undefined : () => setGroupOpen(!groupOpen)}
      >
        <div>
          <Icon
            icon={groupOpen || filterBy ? "chevron-down" : "chevron-right"}
          />
        </div>
        <div className="flex-grow">{name}</div>
        {!groupOpen && <ChatListUnreadCount chatJids={chatJids} />}
      </div>
      <Collapse isOpen={filtering ? true : groupOpen}>
        {filteredChats.map((chat, index) => (
          <ChatCard
            failedSync={syncFailedRoomJids.some((jid) => jid === chat.jid)}
            key={index}
            chatJid={chat.jid}
            chatName={chat.name}
            chatColor={ChatHelpers.getChatColor(chat)}
            chatActive={chat.active}
            onClick={() => onActiveChatChange(chat.jid)}
          />
        ))}
      </Collapse>
    </>
  )
}

export default ChatListGroup
