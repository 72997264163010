import { xml, Client } from "@xmpp/client"
import { Element } from "@xmpp/xml"
import { stringify, clone } from "ltx"
import XMPPError from "@xmpp/error"

type SensetiveNode = [string, string]

const NS_SASL = "urn:ietf:params:xml:ns:xmpp-sasl"
const NS_COMPONENT = "jabber:component:accept"

const SENSITIVES: SensetiveNode[] = [
  ["handshake", NS_COMPONENT],
  ["auth", NS_SASL],
  ["challenge", NS_SASL],
  ["response", NS_SASL],
  ["success", NS_SASL],
]

const isSensitive = (element: Element) => {
  if (element.children.length === 0) return false
  return SENSITIVES.some((sensitive) => {
    return element.is(...sensitive)
  })
}

const hideSensitive = (element: Element) => {
  if (isSensitive(element)) {
    element.children = []
    element.append(xml("hidden", { xmlns: "xmpp.js" }))
  }

  return element
}

const getClientJid = (client: Client): string | undefined => {
  return client.jid?.toString()
}

export const formatToString = (element: Element) => {
  return stringify(hideSensitive(clone(element)))
}

const XmppAnalytics = {
  trackOnSend: (client: Client) => {
    return (element: Element) => {
      if (["iq", "a", "presence"].includes(element.getName())) return

      window.analytics.track("Stanza Out", {
        client_jid: getClientJid(client),
        stanza: formatToString(element),
      })
    }
  },

  trackOnIncomingElement: (client: Client) => {
    return (element: Element) => {
      window.analytics.track("Stanza In", {
        client_jid: getClientJid(client),
        stanza: formatToString(element),
      })
    }
  },

  trackOnConnectionStatus: (client: Client, status: string) => {
    window.analytics.track("Connection Status", {
      client_jid: getClientJid(client),
      provided_email: window.providedEmail,
      status: status,
    })
  },

  trackOnReconnecting: (client: Client) => {
    window.analytics.track("XMPPReconnecting", {
      client_jid: getClientJid(client),
    })
  },

  trackOnReconnected: (client: Client) => {
    window.analytics.track("XMPPReconnected", {
      client_jid: getClientJid(client),
    })
  },

  trackOnChatDisconnected: ({
    userJid,
    chatJid,
    statuses,
  }: {
    userJid: string
    chatJid: string
    statuses: number[]
  }) => {
    window.analytics.track("Chat Disconnected", {
      user: userJid,
      chat: chatJid,
      statuses,
    })
  },

  trackOnError: (client: Client, error: XMPPError) => {
    window.analytics.track("Error", {
      client_jid: getClientJid(client),
      name: error.name,
      message: error.message,
      error_string: error.toString(),
      text: error.text,
      condition: error.condition,
      application: error.application ? formatToString(error.application) : "",
      element: error.element ? formatToString(error.element) : "",
    })
  },
}

export default XmppAnalytics
