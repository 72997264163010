import React from "react"
import { Message } from "../reducers/chatsSlice"
import { MessageAuthor, MessageBody } from "./message/MessageBubleBody"
import classNames from "classnames"
import { Icon } from "@blueprintjs/core"

type ChatReplyBarProps = {
  replyMessage: Message
  onClearReply: () => void
}

export const ChatReplyBar = ({
  replyMessage,
  onClearReply,
}: ChatReplyBarProps) => {
  return (
    <div className="relative flex justify-between items-center gap-2 px-3 py-2 rounded-md bg-gray-50 before:bg-active-chat before:w-[3px] before:rounded-l-md before:absolute before:top-0 before:left-0 before:h-full">
      <div className="overflow-hidden max-h-16">
        <MessageAuthor message={replyMessage} />
        <MessageBody
          message={replyMessage}
          downloadFileName={"."}
          className={classNames("pointer-events-none")}
          compact
        />
      </div>

      <Icon
        icon="cross"
        className="p-2 rounded-full cursor-pointer hover:bg-gray-200"
        onClick={onClearReply}
      />
    </div>
  )
}
