import React from "react"
import useDebounce from "../../hooks/useDebounce"

export default function SearchPopoverBar({
  onSearch,
  searchQuery,
  setSearchQuery,
}: {
  onSearch: (query: string) => void
  searchQuery: string
  setSearchQuery: (query: string) => void
}) {
  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== searchQuery) {
      setSearchQuery(e.target.value)
    }
  }

  useDebounce(
    () => {
      onSearch(searchQuery)
    },
    [onSearch, searchQuery],
    300,
  )

  return (
    <form
      className="max-w-md mx-auto"
      onSubmit={(e) => {
        const target = e.target as typeof e.target & {
          query: { value: string }
        }

        e.preventDefault()
        onSearch(target.query.value)
      }}
    >
      <label
        htmlFor="query"
        className="mb-2 text-sm font-medium text-gray-900 sr-only "
      >
        Search
      </label>
      <div className="relative">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
          <svg
            className="w-4 h-4 text-gray-500 "
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
            />
          </svg>
        </div>
        <input
          type="search"
          id="query"
          name="query"
          value={searchQuery}
          onChange={handleQueryChange}
          className="block w-full p-4 ps-10 text-base text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
          placeholder="Search messages..."
        />
        <button
          type="submit"
          className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 "
        >
          Search
        </button>
        {searchQuery !== "" && (
          <button
            className="text-white absolute end-24 bottom-2.5 bg-gray-400 hover:bg-gray-500 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-full text-xs px-2 py-1 my-1"
            type="reset"
            onClick={() => setSearchQuery("")}
          >
            X
          </button>
        )}
      </div>
    </form>
  )
}
