import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Participant } from "./chatsSlice"
import { PURGE } from "redux-persist"

export type Profile = Participant

export type ProfilesState = Profile[]

const initialState: ProfilesState = []

type UpsertProfileAction = {
  profile: Profile
}

export const profilesSlice = createSlice({
  name: "profiles",
  initialState,
  reducers: {
    upsertProfile: (state, action: PayloadAction<UpsertProfileAction>) => {
      const profileIndex = state.findIndex(
        (p) => p.jid === action.payload.profile.jid,
      )

      if (profileIndex === -1) {
        state.push(action.payload.profile)
      } else {
        state[profileIndex] = action.payload.profile
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState)
  },
})

export const { upsertProfile } = profilesSlice.actions

export default profilesSlice.reducer
