import { Element } from "@xmpp/xml"
import { Participant } from "../reducers/chatsSlice"

class VCardParser {
  static parse(stanza: Element): Participant | undefined {
    if (stanza.getChild("vCard", "vcard-temp")?.children.length === 0) {
      return undefined
    }

    return {
      name: VCardParser.userName(stanza),
      jid: stanza.attrs.from,
      phone: VCardParser.phone(stanza),
      role: VCardParser.role(stanza),
      plates: VCardParser.carPlates(stanza),
      photo: VCardParser.photo(stanza),
    }
  }

  static role(stanza: Element): string {
    return stanza.getChild("vCard", "vcard-temp")?.getChildText("ROLE") || ""
  }

  static phone(stanza: Element): string {
    return (
      stanza
        .getChild("vCard", "vcard-temp")
        ?.getChild("TEL")
        ?.getChildText("NUMBER") || ""
    )
  }

  static userName(stanza: Element): string {
    const nameChild = stanza.getChild("vCard", "vcard-temp")?.getChild("N")

    if (!nameChild) return ""

    return `${nameChild.getChildText("GIVEN") || ""} ${
      nameChild.getChildText("FAMILY") || ""
    }`.trim()
  }

  static carPlates(stanza: Element): string {
    const desc = stanza.getChild("vCard", "vcard-temp")?.getChildText("DESC")

    return desc?.split(":").slice(-1)[0] || ""
  }

  static photo(stanza: Element): Participant["photo"] | undefined {
    const photoChild = stanza.getChild("vCard", "vcard-temp")?.getChild("PHOTO")

    if (!photoChild) return undefined

    const binVal = photoChild.getChildText("BINVAL")
    const type = photoChild.getChildText("TYPE")

    if (!binVal || !type) return

    return {
      data: binVal,
      type: type,
    }
  }
}

export default VCardParser
