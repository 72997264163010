import { Element } from "@xmpp/xml"
import { Activity } from "../reducers/activitySlice"

const firstChild = (xml: Element): Element | undefined => {
  const children = xml.children.filter((e) => e instanceof Element) as Element[]
  return children[0]
}

class ActivityParser {
  static parse(stanza: Element): Activity | undefined {
    const eventNode =
      stanza.getChild("event", "http://jabber.org/protocol/pubsub#event") ||
      stanza.getChild("pubsub", "http://jabber.org/protocol/pubsub")
    if (!eventNode) return

    const itemsNode = eventNode.getChildByAttr(
      "node",
      "http://jabber.org/protocol/activity",
    )
    if (!itemsNode) return

    const itemNode = itemsNode.getChildByAttr("id", "current")
    if (!itemNode) return

    const activityNode = itemNode.getChild(
      "activity",
      "http://jabber.org/protocol/activity",
    )
    if (!activityNode) return

    // XEP-0108 says that the general element MUST be present.
    // That's why we cast to Element because we can be sure that it exists.
    // The secondary specifc element is optional.
    // https://xmpp.org/extensions/xep-0108.html#proto-format
    const generalNode = firstChild(activityNode) as Element
    const specificNode = firstChild(generalNode)

    return {
      jid: stanza.attrs.from,
      general: generalNode.name,
      specific: specificNode?.name,
    }
  }
}

export default ActivityParser
