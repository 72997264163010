import { Element } from "@xmpp/xml"

type MucInfo = {
  name: string
}

class MucDiscoInfoParser {
  static parse(stanza: Element): MucInfo | undefined {
    const identity = stanza.getChild("query")?.getChild("identity")

    if (!identity) return undefined

    return { name: identity.attrs.name }
  }
}

export default MucDiscoInfoParser
