import React, { memo } from "react"
import { Icon } from "@blueprintjs/core"
import classNames from "classnames"
import css from "./ScrollBottomButton.module.css"
import utilsCss from "./utilsCss.module.css"

type ScrollBottomButtonProp = {
  unreadCount: number
  onClick: () => void
}

const ScrollBottomButton = ({
  onClick,
  unreadCount,
}: ScrollBottomButtonProp) => {
  return (
    <div className={css.container}>
      <div className={css.scrollBottom} onClick={onClick}>
        {unreadCount > 0 && (
          <div className={classNames(utilsCss.unreadTag, css.unreadBadge)}>
            {unreadCount}
          </div>
        )}
        <Icon icon="chevron-down" size={24} />
      </div>
    </div>
  )
}

export default memo(ScrollBottomButton)
