import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { PURGE } from "redux-persist"

export type ConnectionStatus = "online" | "disconnected" | "offline"

export type ConnectionStatusState = {
  value: ConnectionStatus
}

const initialState = { value: "offline" } as ConnectionStatusState

type UpdateConnectionStatusAction = {
  status: ConnectionStatus
}

export const connectionStatusSlice = createSlice({
  name: "connectionStatus",
  initialState,
  reducers: {
    updateConnectionStatus: (
      state,
      action: PayloadAction<UpdateConnectionStatusAction>,
    ) => {
      state.value = action.payload.status
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState)
  },
})

export const { updateConnectionStatus } = connectionStatusSlice.actions
export default connectionStatusSlice.reducer
