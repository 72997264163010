import { useState, useEffect, useCallback } from "react"
import AdHocCommands from "../api/adHocCommands"
import { Client } from "@xmpp/client"
import { Element } from "@xmpp/xml"

export interface GpsDevice {
  label: string
  value: string | null
}

export interface GpsDevicesState {
  gpsDevices: GpsDevice[]
  currentGpsDevice: string
  sessionId: string
  refreshGpsDevices: () => void
}

const useGetGpsDevices = (
  client: Client | null,
  driverJID: string | null,
): GpsDevicesState => {
  const [gpsDevices, setGpsDevices] = useState<GpsDevice[]>([])
  const [currentGpsDevice, setCurrentGpsDevice] = useState("")
  const [sessionId, setSessionId] = useState("")
  const [refreshing, setRefreshing] = useState(false)

  const refreshGpsDevices = () => {
    setRefreshing(true)
  }

  const extractGpsDevicevalue = (label: string) => {
    const match = label.match(/\((.*?)\)/)
    return match ? match[1] : ""
  }

  const extractDevicesFromOptions = (optionElements: Element[]) => {
    return optionElements.map((option) => ({
      label: option.attrs.label,
      value: option.getChildText("value") || "",
    }))
  }

  const fetchData = useCallback(async (client: Client, driverJID: string) => {
    try {
      const result = await AdHocCommands.getGPSDeviceList(client, driverJID)

      const commandElement = result.getChild("command")
      if (commandElement) {
        setSessionId(commandElement.attrs.sessionid)

        const fieldElement = commandElement.getChild("x")?.getChild("field")
        if (fieldElement) {
          setCurrentGpsDevice(extractGpsDevicevalue(fieldElement.attrs.label))

          const optionElements = fieldElement.getChildren("option")
          setGpsDevices(extractDevicesFromOptions(optionElements))
        }
      }
    } catch (error) {
      window.appToaster.show({
        message: "Sorry, but we could not load GPS devices",
        intent: "danger",
        timeout: 20,
      })
    } finally {
      setRefreshing(false)
    }
  }, [])

  useEffect(() => {
    if (client && driverJID) {
      fetchData(client, driverJID)
    }
  }, [client, driverJID, refreshing, fetchData])

  useEffect(() => {
    if (client && driverJID) {
      refreshGpsDevices()
    }
  }, [client, driverJID])

  return { gpsDevices, currentGpsDevice, sessionId, refreshGpsDevices }
}

export default useGetGpsDevices
