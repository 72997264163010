import React from "react"
import { useAppSelector } from "../reducers/hooks"
import { selectUpdateAvailable } from "../reducers/appVersionSlice"
import cssUtils from "./utilsCss.module.css"

const NotificationNewAppVersion = () => {
  const updateAvailable = useAppSelector(selectUpdateAvailable)

  const fetchNewAppVersion = () => {
    window.location.reload()
  }

  if (!updateAvailable) return <></>

  if (updateAvailable) {
    window.appToaster.show(
      {
        message: "New version of SlickShift is available",
        icon: "info-sign",
        timeout: 0,
        intent: "primary",
        isCloseButtonShown: false,
        action: {
          text: "Get new version",
          onClick: fetchNewAppVersion,
          intent: "primary",
          className: cssUtils.underlineText,
        },
      },
      "newVersion",
    )
  }

  return <></>
}

export default NotificationNewAppVersion
