import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { PURGE } from "redux-persist"
import { RootState } from "./store"

type ChatMeta = {
  jid: string
  lastSyncedAt: string
}

const initialState: ChatMeta[] = []

type UpdateLastSyncedAtAction = {
  jid: string
}

const chatMeta = createSlice({
  name: "chatMeta",
  initialState,
  reducers: {
    updateLastSyncedAt: (
      state,
      action: PayloadAction<UpdateLastSyncedAtAction>,
    ) => {
      const date = new Date().toISOString()
      const chat = state.find((c) => c.jid === action.payload.jid)
      if (chat) {
        chat.lastSyncedAt = date
      } else {
        state.push({ jid: action.payload.jid, lastSyncedAt: date })
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState)
  },
})

export const selectLastSyncedAt =
  (jid: string) =>
  (state: RootState): string | undefined => {
    const meta = state.chatMeta.find((m) => m.jid === jid)
    if (!meta) return undefined

    return meta.lastSyncedAt
  }

export const { updateLastSyncedAt } = chatMeta.actions

export default chatMeta.reducer
