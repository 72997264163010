import { useContext, useEffect, useState } from "react"
import { ChatHistoryContext } from "../contexts/ChatHistoryContext"
import { Message } from "../reducers/chatsSlice"

const useMessageHighlight = (message: Message) => {
  const [isHighlighted, setIsHighlighted] = useState(false)
  const { messageHighlight } = useContext(ChatHistoryContext)

  useEffect(() => {
    setIsHighlighted(false)
    if (messageHighlight?.messageId === message.id) {
      setTimeout(() => {
        setIsHighlighted(true)
      }, 25)
    }
  }, [messageHighlight, message.id])

  return isHighlighted
}

export default useMessageHighlight
