import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { PURGE } from "redux-persist"
import { RosterItem } from "../api/xmppApi"

export type Roster = {
  items: RosterItem[]
}

const initialState: Roster = {
  items: [],
}

const roster = createSlice({
  name: "roster",
  initialState,
  reducers: {
    setRoster: (state: Roster, action: PayloadAction<Roster>) => {
      state.items = action.payload.items
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState)
  },
})

export const { setRoster } = roster.actions

export default roster.reducer
