import { Client, xml } from "@xmpp/client"
import { Element } from "@xmpp/xml"
import { getAllItems, publish, retract } from "./PubSub"

export type Bookmark = {
  jid: string
  autojoin: boolean
  name?: string
}

const NODE = "urn:xmpp:bookmarks:1"

const privatePublishOptions = [
  { var: "pubsub#persist_items", value: "true" },
  //{ var: "pubsub#max_items", value: "max" },
  //{ var: "pubsub#send_last_published_item", value: "never" },
  { var: "pubsub#access_model", value: "whitelist" },
]

export const retrieveAllBookmarks = async (
  xmpp: Client,
): Promise<Bookmark[]> => {
  const publishToJid = xmpp.jid?.bare().toString() as string

  const bookmarks = await getAllItems({
    xmpp,
    jid: publishToJid,
    node: NODE,
    timeout: 4000,
  })

  const items = bookmarks
    .getChild("pubsub")
    ?.getChild("items")
    ?.getChildren("item") as [Element]
  return items.map((item) => {
    const conference = item.getChild("conference") as Element
    return {
      jid: item.attr("id"),
      autojoin: conference.attr("autojoin") === "true",
      name: conference.attr("name"),
    }
  })
}

export const addBookmark = async (
  xmpp: Client,
  id: string,
  name: string | undefined,
): Promise<void> => {
  const xmlConference = xml("conference", {
    xmlns: NODE,
    autojoin: "true",
    name: name,
  })

  const publishToJid = xmpp.jid?.bare().toString() as string

  await publish({
    xmpp,
    jid: publishToJid,
    node: NODE,
    id,
    payload: xmlConference,
    options: privatePublishOptions,
    timeout: 4000,
  })
}

export const removeBookmark = async (
  xmpp: Client,
  id: string,
): Promise<void> => {
  const jid = xmpp.jid?.bare().toString() as string

  await retract({ xmpp, jid, node: NODE, id })
}
