import * as Sentry from "@sentry/react"

type WebViewInterface = {
  postMessage: (message: string) => void
}

const nativeStorage = (webview: WebViewInterface) => {
  const currentValues = new Map<string, string>()

  return {
    getItem: (key: string): Promise<string> => {
      return Sentry.startSpan({ name: key, op: "storage.get" }, async () => {
        return currentValues.get(key) || ""
      })
    },
    setItem: (key: string, item: string): Promise<void> => {
      return Sentry.startSpan({ name: key, op: "storage.set" }, async () => {
        const message = JSON.stringify({ type: "PushStore", data: item })
        currentValues.set(key, item)
        webview.postMessage(message)
      })
    },
    removeItem: (key: string): Promise<void> => {
      return Sentry.startSpan({ name: key, op: "storage.remove" }, async () => {
        const message = JSON.stringify({ type: "PushStore", data: null })
        currentValues.delete(key)
        webview.postMessage(message)
      })
    },
  }
}

export default nativeStorage
