import { H1, Icon, Overlay, Tag } from "@blueprintjs/core"
import React, { useCallback, useEffect, useState } from "react"
import classNames from "classnames"

import cssUtils from "./utilsCss.module.css"
import css from "./NotificationsPermissionsRequest.module.css"

const WebNotificationsPermissionRequest = () => {
  const [overlayOpen, setOverlayOpen] = useState(false)

  const closeOverlay = useCallback(() => {
    setOverlayOpen(false)
  }, [])

  useEffect(() => {
    if (!window.Notification) return

    if (window.Notification.permission === "default") {
      const onAllowNotification = () => {
        setOverlayOpen(true)
        window.Notification.requestPermission().then(() => {
          closeOverlay()
        })
      }
      window.appToaster.show(
        {
          message: "Allow Slickshift to notify you about new messages",
          intent: "primary",
          timeout: 0,
          icon: "notifications",
          action: {
            text: "Enable",
            onClick: onAllowNotification,
            intent: "primary",
            className: cssUtils.underlineText,
          },
        },
        "notification",
      )
    }
  }, [closeOverlay])

  if (!overlayOpen) return <></>
  return (
    <Overlay
      isOpen={overlayOpen}
      enforceFocus={true}
      onClose={closeOverlay}
      canOutsideClickClose={false}
    >
      <div className={classNames(css.container, cssUtils.center)}>
        <div>
          <Icon icon="arrow-top-left" size={48} />
        </div>
        <div className={css.info}>
          <H1>Allow notifications</H1>
          <p>
            To get notifications for new messages, click &#39;Allow&#39; above
          </p>
          <Tag onClick={closeOverlay} interactive large round>
            OK, got it
          </Tag>
        </div>
      </div>
    </Overlay>
  )
}

export default WebNotificationsPermissionRequest
