import { parse as jidParse } from "../lib/cachedJid"
import MessageHelpers, { MessageStatus } from "./messageHelpers"
import {
  Chat,
  Message,
  Participant,
  GenericMessage,
  MessageGroup,
  MessageDisplayType,
  ChatEvent,
} from "../reducers/chatsSlice"
import { AppStore } from "../reducers/store"
import { jid } from "@xmpp/client"
import {
  ChatWithMessages,
  selectChatMessagesWithAppliedRetractions,
} from "../reducers/chatsSliceSelectors"

const COMPULSORY_ROOMS_NAMES = ["cmr", "office", "feedback", "feedback.room"]

class ChatHelpers {
  static canUserLeaveChat(chat: Chat): boolean {
    return !COMPULSORY_ROOMS_NAMES.includes(chat.jid.split("@")?.[0])
  }

  static isChatInStore(store: AppStore, jid: string): boolean {
    const chats: Chat[] = store.getState()?.chats ?? []
    return chats.some((c) => c.jid === jid)
  }

  static findChatIndex(chatJid: string, chats: Chat[]): number {
    return chats.findIndex((c) => c.jid === chatJid)
  }

  static findDriverParticipant(chat: Chat): Participant | undefined {
    return chat.participants.find(
      (participant) => participant.role === "driver",
    )
  }

  static unreadMessagesCount(chat: ChatWithMessages, myJid: string): number {
    const theJid = jidParse(myJid)

    // No unread count if I chat with myself
    if (chat.jid === theJid.bare().toString()) return 0

    const lastDisplayIndex = ChatHelpers.findLastIndex(
      chat.messages,
      (msg: Message) =>
        MessageHelpers.isDisplayed(msg, myJid) ||
        !MessageHelpers.isIncoming(msg, theJid),
    )

    if (lastDisplayIndex === -1) return chat.messages.length

    return chat.messages.length - (lastDisplayIndex + 1)
  }

  static messageStatus(
    chat: Chat,
    messageOrMessageGroup: Message | MessageGroup,
    myJid: string,
    ackStatus: "scheduled" | "not_sent" | "sent",
  ): MessageStatus | undefined {
    const message =
      messageOrMessageGroup.displayType === MessageDisplayType.MessageGroup
        ? messageOrMessageGroup.messages[0]
        : (messageOrMessageGroup as Message)

    // In DM chat message status only on outgoing messages
    if (!chat.room && MessageHelpers.isIncoming(message, jidParse(myJid)))
      return undefined

    if (chat.participants.length === 0) {
      return undefined
    }

    let forJid = ""
    let driverParicipant: Participant | undefined
    if (chat.room) {
      driverParicipant = ChatHelpers.findDriverParticipant(chat)
      if (!driverParicipant) return undefined

      forJid = driverParicipant.jid
    } else {
      forJid = chat.participants[0].jid
    }

    // In Room no status on messages coming from the driver
    if (
      driverParicipant &&
      chat.room &&
      MessageHelpers.hasComeFromJid(message, driverParicipant.jid)
    ) {
      return undefined
    }

    if (ackStatus === "scheduled" || ackStatus === "not_sent") {
      // We must early return here with these known "invalid" statuses
      // so we don't mark such message as displayed/received because other
      // following messages are displayed.
      return ackStatus
    }

    const lastDisplayIndex = ChatHelpers.findLastIndex(
      chat.messages,
      (msg: Message) => MessageHelpers.isDisplayed(msg, forJid),
    )
    const messageIndex = chat.messages.findIndex((msg) => msg.id === message.id)

    if (lastDisplayIndex >= messageIndex) {
      return "displayed"
    }

    const lastReceivedIndex = ChatHelpers.findLastIndex(
      chat.messages,
      (msg: Message) => MessageHelpers.isReceived(msg, forJid),
    )

    if (lastReceivedIndex >= messageIndex) {
      return "received"
    }

    return ackStatus
  }

  static findMessageChat = (
    message: GenericMessage,
    chats: Chat[],
    myJid: string,
  ) => {
    if (!message.from || !message.to) return undefined
    const index = ChatHelpers.findMessageChatIndex(message, chats, myJid)
    return index === -1 ? undefined : chats[index]
  }

  static findMessageChatIndex = (
    message: GenericMessage,
    chats: Chat[],
    myJid: string,
  ) => {
    const theJid = jidParse(myJid)

    const to = jidParse(message.to)?.bare().toString()
    const from = jidParse(message.from)?.bare().toString()

    return chats.findIndex((chat) => {
      if (MessageHelpers.isSendingOrError(message)) {
        return to === chat.jid
      }

      if (from === theJid.bare().toString() && to === chat.jid) {
        return true
      }

      if (
        MessageHelpers.isIncoming(message, theJid) ||
        message.type === "groupchat"
      ) {
        return from === chat.jid
      }

      return to === chat.jid
    })
  }

  private static findLastIndex(
    messages: Message[],
    predicate: (msg: Message) => boolean,
  ): number {
    for (let index = messages.length - 1; index >= 0; index--) {
      if (predicate(messages[index])) return index
    }

    return -1
  }

  static getLastChatEvent(chat: Chat): ChatEvent | undefined {
    const lastMessage = selectChatMessagesWithAppliedRetractions(chat)().at(-1)

    if (chat.lastNonMessageEvent) {
      if (!lastMessage) return chat.lastNonMessageEvent

      return chat.lastNonMessageEvent.timestamp > lastMessage.timestamp
        ? chat.lastNonMessageEvent
        : lastMessage
    }

    return lastMessage
  }

  static getChatEventBody(event: ChatEvent): string {
    if (event.displayType === MessageDisplayType.Reaction) {
      return `${jid.parse(event.from).resource} reacted with ${event.emojis}`
    } else {
      return event.body || "file attachment"
    }
  }

  static getChatColor(chat: { groups: string[] }) {
    if (!chat.groups) return undefined

    const group = chat.groups.find((g) => g.startsWith("color:"))
    if (!group) return undefined

    const color = group.match(/(#[a-f0-9]{6})$/)
    if (!color) return undefined

    return color[1]
  }
}

export default ChatHelpers
