import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { PURGE } from "redux-persist"
import { Message } from "./chatsSlice"

export type ChatRepliesState = Record<string, Message>

const initialState: ChatRepliesState = {}

export const chatRepliesSlice = createSlice({
  name: "chatReplies",
  initialState,
  reducers: {
    setChatReplyMessage: (
      state,
      action: PayloadAction<{ chatJid: string; message: Message }>,
    ) => {
      state[action.payload.chatJid] = action.payload.message
    },
    clearReplyMessage: (state, action: PayloadAction<{ chatJid: string }>) => {
      delete state[action.payload.chatJid]
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState)
  },
})

export const { setChatReplyMessage, clearReplyMessage } =
  chatRepliesSlice.actions

export default chatRepliesSlice.reducer
