import MessageHelpers from "../lib/messageHelpers"
import { GenericMessage, Message } from "./chatsSlice"
import { RootState } from "./store"
import { jid } from "@xmpp/client"
import { JID } from "@xmpp/jid"
import { userJid } from "../jidUtils"

export const driversProfiles = (state: RootState) => {
  return state.profiles.filter((p) => p.role === "driver")
}

export const findAuthorProfile = (message: GenericMessage, domain: string) => {
  return (state: RootState) => {
    const candidates = [MessageHelpers.fromBareJid(message, domain)]
    if (message.type === "groupchat") {
      candidates.push(userJid(message.from))
    }
    return state.profiles.find((p) => candidates.includes(p.jid))
  }
}

export const findProfile = (userJid: string) => {
  const bareJid = jid.parse(userJid).bare().toString()
  return (state: RootState) => {
    return state.profiles.find((p) => p.jid === bareJid)
  }
}

export const messageAuthorName = (message: GenericMessage, myJid: JID) => {
  return (state: RootState) => {
    const authorProfile = findAuthorProfile(message, myJid.domain)(state)

    if (!authorProfile || authorProfile?.name.length === 0) {
      return MessageHelpers.userName(message)
    }

    return authorProfile.name
  }
}

export const maybeMessageAuthorName = (
  message: Message | undefined,
  myJid: JID,
) => {
  return (state: RootState) => {
    if (!message) {
      return undefined
    }

    const authorProfile = findAuthorProfile(message, myJid.domain)(state)

    if (!authorProfile || authorProfile?.name.length === 0) {
      return MessageHelpers.userName(message)
    }

    return authorProfile.name
  }
}
