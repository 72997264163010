import { Client, xml } from "@xmpp/client"
import { v4 as uuid } from "uuid"
import { Element } from "@xmpp/xml"

type ChatMarkerName = "received" | "displayed"

const ChatMarkersApi = {
  sendMarker: (
    name: ChatMarkerName,
    id: string,
    to: string,
    type: "chat" | "groupchat",
    xmpp: Client,
  ): Promise<Element> => {
    return new Promise((resolve) => {
      if (!id || id.length === 0) {
        throw "ChatMarkerApi: Can not send chat marker to an empty id"
      }

      const marker = xml(name, {
        xmlns: "urn:xmpp:chat-markers:0",
        id: id,
      })

      const stanza = xml(
        "message",
        {
          to: to,
          id: uuid(),
          from: xmpp.jid,
          type: type,
        },
        marker,
        xml("store", "urn:xmpp:hints"),
      )

      xmpp.send(stanza).catch((e) => {
        throw e
      })

      resolve(stanza)
    })
  },
}

export default ChatMarkersApi
